import * as constants from "../constants";

export const fetchChats = (onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/chat-list",
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const fetchChatHistory = (chatId, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/chat-details/" + chatId,
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const chatSend = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/chat-send",
    method: "POST",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    data: data,
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const fetchLastChatHistory = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/chat-details/" + data.chatId,
    method: "GET",
    params: {
      seconds: data?.seconds,
    },
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const ChatDeletion = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/chat-delete",
    method: "DELETE",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const fetchChatCount = (onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/chat-new-count",
    method: "GET",
    success: (response) => {
      return {
        type: constants.SET_CHAT_COUNT,
        payload: response?.result,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
