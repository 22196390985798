import PropTypes from "prop-types";
import React, { Component } from "react";
import ChatWindow from "./ChatWindow";
import launcherIcon from "./../assets/logo-no-bg.svg";
import incomingMessageSound from "./../assets/sounds/notification.mp3";
import { connect } from "react-redux";
import {
  ChatDeletion,
  chatSend,
  fetchChatHistory,
  fetchLastChatHistory,
} from "../../Store/Actions/conversationActionCreator";
import { POLL_CHAT_HISTORY_API_MS } from "../../Utils/constants";
import moment from "moment";
import { showMessage } from "../../Utils/helper";

class Launcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      launcherIcon,
      isOpen: false,
      messageList: props?.messageList ?? [],
    };
    this.interval = null;
    this.sendMessage.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.fetchLastChatHistoryData(this.props.agentProfile?.id),
      POLL_CHAT_HISTORY_API_MS
    );
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.messageList?.length !== this.props?.messageList?.length) {
      this.setState({
        messageList: this.props?.messageList,
      });
    }
  }

  addMessages(messages, updateExistingMessage) {
    let allMessage = [
      ...messages,
      ...this.state.messageList?.filter((msg) =>
        updateExistingMessage
          ? !messages?.find((m) => m.chatMessage === msg.chatMessage)
          : true
      ),
    ];
    const jsonObject = allMessage.map((m) => m.id);
    const uniqueSet = new Set(jsonObject);
    allMessage = Array.from(uniqueSet).map((id) =>
      allMessage?.find((m) => m.id === id)
    );
    this.setState({ messageList: allMessage });
  }

  sendMessage(messageType, message) {
    this.addMessages(
      [
        {
          loggedInUser: true,
          id: 0,
          chatMessage: message,
          createdTimestamp: moment().valueOf(),
        },
      ],
      false
    );
    this.props.dispatchChatSend(
      {
        toUserId: this.props.agentProfile?.id,
        chatMessage: message,
        messageType: messageType,
      },
      (resp) => {
        if (resp?.status) {
          this.addMessages(resp?.result, true);
        } else {
          showMessage(resp?.result, "error");
        }
      },
      (err) => {}
    );
  }

  fetchLastChatHistoryData(chatId) {
    this.props.dispatchFetchLastChatHistory(
      {
        chatId: chatId,
        seconds: 10,
      },
      (resp) => {
        if (resp?.status & (resp?.result != null)) {
          this.addMessages(resp?.result);
        }
      },
      (err) => {
        // do nothing here
      }
    );
  }

  playIncomingMessageSound() {
    var audio = new Audio(incomingMessageSound);
    audio.play();
  }

  handleClick() {
    if (this.props.handleClick !== undefined) {
      this.props.handleClick();
    } else {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  handleChatDelete(deleteIdList, cb) {
    const that = this;
    this.props.dispatchChatDeletion(
      {
        deleteIdList,
      },
      (resp) => {
        that.props.dispatchFetchChatHistory(
          this.props.agentProfile?.id,
          (resp) => {
            if (resp?.status & (resp?.result != null)) {
              this.setState(
                {
                  messageList: resp?.result ?? [],
                },
                () => {
                  !!cb && cb();
                }
              );
            }
          }
        );
      },
      (err) => {}
    );
  }

  render() {
    const isOpen = this.props.hasOwnProperty("isOpen")
      ? this.props.isOpen
      : this.state.isOpen;

    const messageList = this.state.messageList.sort(
      (a, b) => b?.createdTimestamp - a?.createdTimestamp
    );
    return (
      <div id="sc-launcher">
        {/*<div className={classList.join(' ')} onClick={this.handleClick.bind(this)} hidden={!isOpen}>
          <MessageCount count={this.props.newMessagesCount} isOpen={isOpen} />
          <img className={'sc-open-icon'} src={launcherIconActive} />
          <img className={'sc-closed-icon'} src={launcherIcon} />
        </div>*/}
        <ChatWindow
          messageList={messageList}
          onUserInputSubmit={(messageType, message) => {
            this.sendMessage(messageType, message);
          }}
          onChatDelete={(chatIds, cb) => {
            this.handleChatDelete(chatIds, cb);
          }}
          onFilesSelected={this.props.onFilesSelected}
          agentProfile={this.props.agentProfile}
          isOpen={isOpen}
          onClose={this.props.handleClose}
          showEmoji={this.props.showEmoji}
        />
      </div>
    );
  }
}

Launcher.propTypes = {
  isOpen: PropTypes.bool,
  messageList: PropTypes.arrayOf(PropTypes.object),
  mute: PropTypes.bool,
  showEmoji: PropTypes.bool,
};

Launcher.defaultProps = {
  showEmoji: true,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatchChatSend: (data, onSuccess, onError) =>
    dispatch(chatSend(data, onSuccess, onError)),
  dispatchFetchLastChatHistory: (data, onSuccess, onError) =>
    dispatch(fetchLastChatHistory(data, onSuccess, onError)),
  dispatchFetchChatHistory: (data, onSuccess, onError) =>
    dispatch(fetchChatHistory(data, onSuccess, onError)),
  dispatchChatDeletion: (data, onSuccess, onError) =>
    dispatch(ChatDeletion(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Launcher);
