import * as constants from "./../constants";

const defaultState = {};

export default function UserReducer(state = defaultState, action) {
  switch (action.type) {
    case constants.SET_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case constants.SET_USER_IMAGES:
      return {
        ...state,
        imageUrl: !!action.payload ? action.payload : state?.user?.imageUrl,
      };
      case constants.SET_PRIME_USER:
        return {
          ...state,
          prime: !!action.payload ? action.payload.isPrime : false,
          primeExpiryDate: !!action.payload ? action.payload.primeExpiryDate : null
        };
    case constants.RESET_USER_INFO:
      return {};
    default:
      return state;
  }
}
