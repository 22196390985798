import { combineReducers } from "redux";
import * as constants from "../constants";
import conversation from "./conversationReducer";
import notifications from "./notificationReducer";
import user from "./userReducer";
import SystemReducer from "./systemReducer";

const rootReducer = combineReducers({
  conversation,
  user,
  notifications,
  systemInfo: SystemReducer,
});

// export default rootReducer;
// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  if (action.type === constants.RESET_USER_INFO) {
    state = undefined;
  }

  return rootReducer(state, action);
};
