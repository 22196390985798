import { Box, Divider, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { login, SendAccountOtp } from "../../../Store/Actions/userActionCreator";
import { CustomTextField, Button } from "../../UI";
import Modal from "../Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { USER_ROLE_ENUM } from "../../../Utils/constants";
import { withRouter } from "react-router-dom";
import { showMessage } from "../../../Utils/helper";
import EmailOtpVerificationModal from "./EmailOtpVerificationModal";

const validationSchema = yup.object({
  userId: yup.string().when("isEmail", {
    is: "1",
    then: yup
      .string()
      .email("Please enter valid email")
      .required("Email / Phone Number cannot be empty"),
    otherwise: yup
      .string()
      .required("Email / Phone Number cannot be empty")
      .min(10, "Phone Number must be 10 char")
      .max(10, "Phone Number must be 10 char"),
  }),
});

function OtpLoginModal({
  user,
  open,
  onClose,
  toggleLoginModal,
  dispatchSendAccountOtp,
  ...props
}) {
  const [isOpen, toggleModal] = useState(open);
  const [loading, toggleLoading] = useState(false);
  const [openEmailOtpModal, setOpenEmailOtpModal] = useState(false);
  const { palette } = useTheme();

  const handleClose = () => {
    toggleModal(!isOpen);
    onClose && onClose();
  };

  const SendEmailOtp = (values) => {
    toggleLoading(true);
    dispatchSendAccountOtp(
      { userId: values?.userId },
      (resp) => {
        if (!!resp?.status) {
          setOpenEmailOtpModal(true);
          showMessage(resp?.result, "success");
        } else {
          showMessage(resp?.result, "error");
        }
        toggleLoading(false);
      },
      (err) => {
        toggleLoading(false);
        handleClose();
        // showMessage(err, "error");
      }
    );
  };

  const formik = useFormik({
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      isEmail: "0",
      userId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      SendEmailOtp(values);
    },
  });

  const actions = [];
  return (
    <>
      <Modal
        onClose={handleClose}
        open={isOpen}
        title={""}
        actions={actions}
        loading={loading}
        isForm={true}
        onSubmit={formik.handleSubmit}
      >
        <Box
          style={{
            padding: "0 15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              marginTop: "8px",
              marginBottom: "24px",
              // fontFamily: "Montserrat",
              fontWeight: "bold",
              color: palette.primary.main,
            }}
          >
            Welcome!
          </Typography>
          <CustomTextField
            fullWidth={true}
            label="Enter Email ID / Mobile No."
            id="userId"
            name="userId"
            value={formik.values.userId}
            onChange={(event) => {
              formik.handleChange("userId")(event);
              if (Number(event.target.value)) {
                formik.handleChange("isEmail")("0");
              } else {
                formik.handleChange("isEmail")("1");
              }
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.userId ? Boolean(formik.errors.userId) : false
            }
            helperText={formik.touched.userId && formik.errors.userId}
            style={{ marginBottom: "16px" }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{
              fontWeight: "bold",
            }}
            type="submit"
          >
            Send OTP
          </Button>
          <Typography style={{
            textAlign: "center",
            marginTop: "8px",
            color: "black",
            fontSize:"0.8em"
          }} >You can login with Mobile OTP for only 3 times a day</Typography>
          <Divider
            style={{
              margin: "20px 0 8px 0",
              borderWidth: 2,
              borderColor: "#000",
              borderRadius: 100,
              width: "100%",
            }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              color: "#5671FF",
              width: "100%",
            }}
          >
            <Button
              sx={{ textTransform: "none", "&:hover": { color: "none" } }}
              onClick={async () => {
                toggleLoginModal();
              }}
            >
              <Typography color={"#5671FF"}>Login via Password</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
      {!!openEmailOtpModal && (
        <EmailOtpVerificationModal
          open={openEmailOtpModal}
          data={{ emailId: formik?.values?.userId, validateViaOTP: true }}
          onClose={() => {
            setOpenEmailOtpModal(false);
          }}
          blockClose={false}
          next={(resp) => {
            if (!!resp?.status) {
              toggleLoading(false);
              handleClose();
              if (!resp?.result?.emailVerified) {
                props.history.push("/register", {
                  ...resp?.result,
                });
              } else if (!resp?.result?.mobileVerified) {
                props.history.push("/register", {
                  ...resp?.result,
                });
              } else if (
                !!resp?.result &&
                resp?.result?.roles[resp?.result?.roles?.length - 1]
                  ?.authority === USER_ROLE_ENUM.ROLE_NORMAL_USER
              ) {
                props.history.push("/register/basic");
              } else if (
                !!resp?.result &&
                resp?.result?.roles[resp?.result?.roles?.length - 1]
                  ?.authority === USER_ROLE_ENUM.ROLE_REGISTERED_USER &&
                !Object.keys(resp?.result?.imageUrl ?? {})?.length
              ) {
                props.history.push("/register/profile");
              } else {
                handleClose();
                props.history.push("/my-matches");
              }
            } else {
              showMessage(resp?.result, "error");
              toggleLoading(false);
            }
            setOpenEmailOtpModal(false);
            // props.history.push("/my-matches");
            // handleClose();
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (payload, onSuccess, onError) =>
    dispatch(login(payload, onSuccess, onError)),
  dispatchSendAccountOtp: (payload, onSuccess, onError) =>
    dispatch(SendAccountOtp(payload, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OtpLoginModal));
