// Common
export const API = "API";
export const DO_NOTHING = "DO_NOTHING";
export const TOGGLE_LOADER = "TOGGLE_LOADER";

//Notifiers
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

// User Reducer Actions
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_IMAGES = "SET_USER_IMAGES";
export const RESET_USER_INFO = "RESET_USER_INFO";
export const SET_PRIME_USER = "SET_PRIME_USER";

// Chat Reducer Action
export const SET_CHAT_COUNT = "SET_CHAT_COUNT";
// System Reducer Action
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
