import PropTypes from "prop-types";
import React, { Component } from "react";
import UserInput from "./UserInput";
import Header from "./Header";
import MessageGroup from "./MessageGroup";
import { Box } from "@mui/material";
import { Spinner } from "../../Components/UI";
import ReportProfileModal from "../../Components/Modals/ModalContexts/ReportProfileModal";
import moment from "moment";

class ChatWindow extends Component {
  messagesEndRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showDeleteButton: false,
      selectedDeleteRow: [],
      openReportProfileModal: false,
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevPops) {
    if (prevPops.messageList?.length != this.props.messageList?.length) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  onUserInputSubmit(messageType, message) {
    this.props.onUserInputSubmit(messageType, message);
  }

  onFilesSelected(filesList) {
    this.props.onFilesSelected(filesList);
  }

  createGroups(messageList) {
    // this gives an object with dates as keys
    const groups = messageList.reduce((groups, message) => {
      const date = moment(message.createdTimestamp).format("YYYY-MM-DD");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups)
      .reverse()
      .map((date) => {
        return {
          date,
          messages: groups[date],
        };
      });

    return groupArrays;
  }

  handleOption(selectOption) {
    switch (selectOption) {
      case "DELETE":
        this.setState({
          showDeleteButton: true,
        });
        break;
      case "REPORT_PROFILE":
        this.setState({
          openReportProfileModal: true,
        });
        break;

      default:
        break;
    }
  }

  onDeleteRowSelected(selectedDeleteRow) {
    this.setState({
      selectedDeleteRow,
    });
  }

  handleMessageDeletion() {
    const { selectedDeleteRow } = this.state;
    const that = this;
    this.setState(
      {
        loading: true,
      },
      () => {
        that.props.onChatDelete(selectedDeleteRow, () => {
          that.hanldeCancelDeletion();
        });
      }
    );
  }

  hanldeCancelDeletion() {
    this.setState({
      loading: false,
      showDeleteButton: false,
      selectedDeleteRow: [],
    });
  }

  render() {
    const {
      loading,
      showDeleteButton,
      selectedDeleteRow,
      openReportProfileModal,
    } = this.state;
    let messageList = this.props.messageList || [];
    let classList = ["sc-chat-window", this.props.isOpen ? "opened" : "closed"];
    const groupedMessage = this.createGroups(messageList);
    return (
      <>
        <div className={classList.join(" ")}>
          <Spinner loading={loading}>
            <div className={"sc-chat-window"}>
              <Header
                teamName={this.props.agentProfile.teamName}
                imageUrl={this.props.agentProfile.imageUrl}
                onClose={this.props.onClose}
                handleOptionChange={(selectedOption) =>
                  this.handleOption(selectedOption)
                }
              />
              <div className="sc-message-list">
                <div className="sc-notfication-message">
                  Conversations from last 6 months are shown in this secured
                  chat. Report the profile if you get any indencent messages.
                  For feedback / query write to jspn.vvs@umapps.in
                </div>
                {groupedMessage?.length > 0 ? (
                  groupedMessage.map((group, i) => {
                    return (
                      <MessageGroup
                        date={group.date}
                        messages={group.messages}
                        imageUrl={this.props.agentProfile.imageUrl}
                        showDelete={showDeleteButton}
                        selectedDeleteRow={selectedDeleteRow}
                        onDeleteRowSelected={(selectedList) =>
                          this.onDeleteRowSelected(selectedList)
                        }
                      />
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "90%",
                    }}
                  >
                    Start a conversation
                  </Box>
                )}
                <div ref={this.messagesEndRef} />
              </div>
              <UserInput
                onSubmit={this.onUserInputSubmit.bind(this)}
                onFilesSelected={this.onFilesSelected.bind(this)}
                showEmoji={this.props.showEmoji}
                showDelete={showDeleteButton}
                selectedDeleteRow={selectedDeleteRow}
                onDelete={() => {
                  this.handleMessageDeletion();
                }}
                onDeletionCancled={() => this.hanldeCancelDeletion()}
              />
            </div>
          </Spinner>
        </div>
        {!!openReportProfileModal && (
          <ReportProfileModal
            open={openReportProfileModal}
            data={this.props.agentProfile?.id}
            onClose={() => {
              this.setState({
                openReportProfileModal: false,
              });
            }}
            next={() => {
              this.setState({
                openReportProfileModal: false,
              });
              // handleClose();
            }}
          />
        )}
      </>
    );
  }
}

ChatWindow.propTypes = {
  agentProfile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func,
  onUserInputSubmit: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool,
};

export default ChatWindow;
