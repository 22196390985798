import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Close } from "@mui/icons-material";
import { Spinner } from "../UI/Spinner/Spinner";
import {
  Box,
  Divider,
  IconButton,
  // Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiPaper-root": {
      borderRadius: 16,
    },
    "& .modal-head": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    "& .modal-title": {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#14112d",
    },
    "& .modal-subtitle": {
      fontSize: "14px",
      fontWeight: "normal",
      color: "#14112d",
    },

    "& .cancelButton": {
      color: "var(--primary-color)",
    },
    "& .saveButton": {},
  },
  iconButton: {
    padding: "0px",
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

function Modal({
  open,
  headStyle,
  title,
  isCustomTitle = false,
  titleStyle,
  subtitle,
  children,
  actions,
  onClose,
  maxWidth,
  loading,
  className,
  isForm,
  blockClose = false,
  onSubmit,
  subtitleStyle,
  haveStickyHead = false,
  onBackdropClick,
  ...props
}) {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(open);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = (ev) => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Dialog
      open={isOpen}
      {...(!blockClose ? { onClose: handleClose } : {})}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.modal} ${className}`}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth ?? "xs"}
      disableEscapeKeyDown={true}
      style={
        haveStickyHead
          ? {
              justifyContent: "center",
              top: "15%",
              bottom: "17.5%",
              height: "75%",
            }
          : {
              borderRadius: 16,
            }
      }
      {...props}
    >
      <Spinner loading={loading}>
        <DialogTitle
          id="alert-dialog-slide-title"
          style={
            haveStickyHead
              ? {
                  top: 0,
                  overflow: "hidden",
                  position: "sticky",
                  background: "white",
                  zIndex: 999,
                  ...headStyle,
                }
              : {
                  padding: "16px 24px 8px 24px",
                  ...headStyle,
                }
          }
        >
          {isCustomTitle ? (
            <Box className={`modal-head`}>{title}</Box>
          ) : (
            <Box className={`modal-head`}>
              <div>
                {!!title && (
                  <Typography
                    className={`modal-title`}
                    variant="h6"
                    style={titleStyle ?? {}}
                  >
                    {title}
                  </Typography>
                )}
                {!!subtitle && (
                  <Typography
                    className={`modal-subtitle`}
                    variant="body2"
                    style={subtitleStyle ?? {}}
                  >
                    {subtitle}
                  </Typography>
                )}
              </div>
              <div
                style={{
                  alignSelf: "center",
                  justifySelf: "flex-end",
                }}
              >
                <IconButton
                  aria-label="delete"
                  className={classes.iconButton}
                  onClick={handleClose}
                >
                  <Close />
                </IconButton>
              </div>
            </Box>
          )}
        </DialogTitle>
        <DialogContent
          style={
            haveStickyHead
              ? {
                  overflowY: "unset",
                  padding: "0px 24px 8px",
                  minHeight: "70vh",
                }
              : {}
          }
        >
          {isForm ? (
            <form onSubmit={onSubmit}>
              {children}
              {!!actions.length && <Divider style={{ marginTop: "10px" }} />}
              {/* {!!actions.length && (
                <DialogActions>
                  {actions.map((action, index) => (
                    <Box key={index}>{action}</Box>
                  ))}
                </DialogActions>
              )} */}
            </form>
          ) : (
            <>{children}</>
          )}
        </DialogContent>
        {!!actions.length && <Divider style={{ marginTop: "10px" }} />}
        {!!actions?.length && (
          <DialogActions style={{justifyContent:"center",marginBottom:"6vh"}}>
            {actions?.map((action, index) => (
              <Box key={index}>{action}</Box>
            ))}
          </DialogActions>
        )}
      </Spinner>
    </Dialog>
  );
}
export default Modal;
