import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomTextField,
  StyledMenuItem,
  Button,
  Spinner,
} from "../../Components/UI";
import "./Registration.scss";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import { useFormik } from "formik";
import * as yup from "yup";
import { HeadTitle, HorizontalLine } from ".";
import { connect } from "react-redux";
import {
  GetBasicDetails,
  PostBasicDetails,
} from "../../Store/Actions/userActionCreator";
import { showMessage } from "../../Utils/helper";
import {
  HOUSE_ENUM,
  MATHA_ENUM,
  MOTHER_TONGUE_ENUM,
} from "../../Utils/constants";

var categories = {
  Madhwa: "Madhwa",
  Smartha: "Smartha",
};
const validationSchema = yup.object({
  matha: yup
    .string("Invalid matha")
    .trim()
    .nullable(true)
    .required("matha cannot be empty"),
  motherTongue: yup
    .string("Invalid mother tongue")
    .trim()
    .nullable(true)
    .required("mother tongue cannot be empty"),
  category: yup
    .string("Invalid Category")
    .trim()
    .nullable(true)
    .required("Category cannot be empty"),
  fatherName: yup
    .string("Invalid father name")
    .trim()
    .nullable(true)
    .required("father name cannot be empty")
    .min(3)
    .max(50),
  fatherOccupation: yup
    .string("Invalid father occupation")
    .trim()
    .nullable(true)
    .required("father occupation cannot be empty")
    .max(50),
  motherName: yup
    .string("Invalid mother name")
    .trim()
    .nullable(true)
    .required("mother name cannot be empty")
    .min(3)
    .max(50),
  motherOccupation: yup
    .string("Invalid mother occupation")
    .trim()
    .nullable(true)
    .max(50),
  brotherCount: yup
    .number("Invalid brother count")
    .typeError("Invalid brother count")
    .min(0)
    .default(0)
    .transform((value) => (isNaN(value) ? 0 : value))
    .required("brother count cannot be empty"),
  sisterCount: yup
    .number("Invalid sister count")
    .typeError("Invalid sister count")
    .default(0)
    .transform((value) => (isNaN(value) ? 0 : value))
    .min(0)
    .required("sister count cannot be empty"),
  alternateContact: yup
    .string("Invalid alternate contact no.")
    .matches(/^[0-9+]+$/, "Invalid mobile number")
    .trim()
    .nullable(true),
  house: yup.string("Invalid house").nullable(true),
  address: yup
    .string("Invalid address")
    .trim()
    .nullable(true)
    .required("address cannot be empty"),
  contactPreference: yup
    .string("Type in the Preferred Contact Time")
    .max(150, "Preferred Contact Time text should be less than 150 chars")
    .trim()
    .nullable(true),
});

function BasicForm({
  dispatchGetBasicDetails,
  dispatchPostBasicDetails,
  ...props
}) {
  const [loading, toggleLoading] = useState(false);

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      matha: "",
      motherTongue: "",
      mobileNumber: "",
      fatherName: "",
      fatherOccupation: "",
      motherName: "",
      motherOccupation: "",
      brotherCount: 0,
      sisterCount: 0,
      alternateContact: "",
      address: "",
      contactPreference: "",
      house: "",
      category: "",
      gender: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      toggleLoading(true);
      dispatchPostBasicDetails(
        {
          brotherCount: 0,
          sisterCount: 0,
          ...values,
        },
        (resp) => {
          if (!!resp?.status) {
            props.history.push("/register/advance");
            toggleLoading(false);
          } else {
            toggleLoading(false);
            showMessage(resp?.result, "error");
          }
        },
        (err) => {
          toggleLoading(false);
          showMessage(err, "error");
        }
      );
    },
  });

  useEffect(() => {
    toggleLoading(true);
    dispatchGetBasicDetails(
      (resp) => {
        if (!!resp?.status) {
          formik.setValues({
            ...resp?.result,
          });
          const gender = resp.result.gender;
          if (gender === "MALE") {
            categories = {
              Madhwa: "Madhwa",
            };
            formik.setFieldValue("category", "Madhwa");
          }
        } else {
          showMessage(resp?.result || "", "error");
        }
        toggleLoading(false);
      },
      (err) => {
        toggleLoading(false);
        showMessage(err, "error");
      }
    );
  }, []);

  useEffect(() => {}, [formik.errors, formik]);

  return (
    <Box className="registration-baisc-form">
      <a
        href="https://wa.me/message/BN2R4ZFIMY4JM1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <Typography variant="h4" fontWeight={"bold"}>
        You are verified!
      </Typography>
      <Typography variant="h6" color={"#434343"} marginBottom={5}>
        Now lets just get to know you better
      </Typography>
      <HeadTitle title={"BASIC DETAILS"} />
      <Spinner loading={loading}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Matha"
                select
                fullWidth={true}
                id="matha"
                name="matha"
                value={formik.values.matha ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.matha ? Boolean(formik.errors.matha) : false
                }
                helperText={formik.touched.matha && formik.errors.matha}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(MATHA_ENUM).map((matha) => {
                  return (
                    <StyledMenuItem key={matha} value={matha}>
                      {matha}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Mother tongue"
                select
                fullWidth={true}
                id="motherTongue"
                name="motherTongue"
                value={formik.values.motherTongue ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.motherTongue
                    ? Boolean(formik.errors.motherTongue)
                    : false
                }
                helperText={
                  formik.touched.motherTongue && formik.errors.motherTongue
                }
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(MOTHER_TONGUE_ENUM).map((lang) => {
                  return (
                    <StyledMenuItem key={lang} value={lang}>
                      {lang}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Father's / Guardian Name"
                id="fatherName"
                name="fatherName"
                value={formik.values.fatherName ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fatherName
                    ? Boolean(formik.errors.fatherName)
                    : false
                }
                helperText={
                  formik.touched.fatherName && formik.errors.fatherName
                }
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Father's Occupation"
                id="fatherOccupation"
                name="fatherOccupation"
                value={formik.values.fatherOccupation ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fatherOccupation
                    ? Boolean(formik.errors.fatherOccupation)
                    : false
                }
                helperText={
                  formik.touched.fatherOccupation &&
                  formik.errors.fatherOccupation
                }
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Mother's Name"
                id="motherName"
                name="motherName"
                value={formik.values.motherName ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.motherName
                    ? Boolean(formik.errors.motherName)
                    : false
                }
                helperText={
                  formik.touched.motherName && formik.errors.motherName
                }
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Mother's Occupation"
                id="motherOccupation"
                name="motherOccupation"
                value={formik.values.motherOccupation ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.motherOccupation
                    ? Boolean(formik.errors.motherOccupation)
                    : false
                }
                helperText={
                  formik.touched.motherOccupation &&
                  formik.errors.motherOccupation
                }
                // isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="No. of Brother (If any)"
                id="brotherCount"
                name="brotherCount"
                value={formik.values.brotherCount ?? 0}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.brotherCount
                    ? Boolean(formik.errors.brotherCount)
                    : false
                }
                helperText={
                  formik.touched.brotherCount && formik.errors.brotherCount
                }
                InputProps={{
                  type: "number",
                }}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="No. of Sister (If any)"
                id="sisterCount"
                name="sisterCount"
                value={formik.values.sisterCount ?? 0}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.sisterCount
                    ? Boolean(formik.errors.sisterCount)
                    : false
                }
                helperText={
                  formik.touched.sisterCount && formik.errors.sisterCount
                }
                InputProps={{
                  type: "number",
                }}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Alternate Contact Number"
                id="alternateContact"
                name="alternateContact"
                value={formik.values.alternateContact ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.alternateContact
                    ? Boolean(formik.errors.alternateContact)
                    : false
                }
                helperText={
                  formik.touched.alternateContact &&
                  formik.errors.alternateContact
                }
                style={{ marginBottom: "22px", marginTop: "20px" }}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Category"
                select
                fullWidth={true}
                id="category"
                name="category"
                value={formik.values.category ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.category
                    ? Boolean(formik.errors.category)
                    : false
                }
                helperText={formik.touched.category && formik.errors.category}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(categories).map((cat) => {
                  return (
                    <StyledMenuItem key={cat} value={cat}>
                      {cat}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={12} margin={"auto 0"}>
              <Typography color={"#434343"} marginRight={5}>
                House
              </Typography>
              <RadioGroup
                row={true}
                id="house"
                name="house"
                value={formik.values.house ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {Object.keys(HOUSE_ENUM)?.map((build) => {
                  return (
                    <FormControlLabel
                      value={build}
                      control={<Radio />}
                      label={build}
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
            <FormHelperText
              error={
                formik.touched.house ? Boolean(formik.errors.house) : false
              }
            >
              {!!formik.touched.house && formik.errors.house}
            </FormHelperText>
            <Grid item xs={12} md={12} margin={"auto 0"}>
              <Typography variant="body1" color={"#434343"}>
                Address<span style={{ color: "red" }}>*</span>
              </Typography>
              <CustomTextField
                fullWidth={true}
                multiline={true}
                label=""
                rows={1}
                id="address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.address
                    ? Boolean(formik.errors.address)
                    : false
                }
                helperText={formik.touched.address && formik.errors.address}
                style={{ marginBottom: "16px" }}
              />
            </Grid>
            <Grid item xs={12} md={12} margin={"auto 0"}>
              <Typography variant="body1" color={"#434343"}>
                Preferred Contact Time
              </Typography>
              <CustomTextField
                placeholder="Contact me on WhatsApp only / Call after 8 PM / Weekends Only"
                fullWidth={true}
                multiline={true}
                label=""
                rows={1}
                id="contactPreference"
                name="contactPreference"
                value={formik.values.contactPreference}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.contactPreference
                    ? Boolean(formik.errors.contactPreference)
                    : false
                }
                helperText={
                  formik.touched.contactPreference &&
                  formik.errors.contactPreference
                }
                style={{ marginBottom: "16px" }}
              />
            </Grid>
            <Grid item xs={12} md={12} margin={"auto 0"}>
              <HorizontalLine />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  xs={12}
                  md
                  variant="contained"
                  size="large"
                  type="submit"
                  alignItems="center"
                  endIcon={
                    <ArrowForwardOutlinedIcon
                      htmlColor="#663399"
                      fontSize="small"
                    />
                  }
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    color: "#663399",
                    borderRadius: "0.7rem",
                  }}
                  disabled={!formik.isValid}
                >
                  Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Spinner>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetBasicDetails: (onSuccess, onError) =>
    dispatch(GetBasicDetails(onSuccess, onError)),
  dispatchPostBasicDetails: (payload, onSuccess, onError) =>
    dispatch(PostBasicDetails(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicForm);
