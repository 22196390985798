import React from "react";
import Linkify from "react-linkify";
import moment from "moment";
import { DoubleTickIcon } from "../../../Assets/svg";

const TextMessage = (props) => {
  return (
    <div className="sc-message--text">
      <div>
        <Linkify properties={{ target: "_blank" }}>{props.chatMessage}</Linkify>
      </div>
      <div className="sc-message--time">
        {moment(props.createdTimestamp).format("hh:mm A")}
        {!!props?.loggedInUser && (
          <>
            {"   "}
            <DoubleTickIcon style={!!props?.isSeen ? { fill: "#FFB955" } : {}} />
          </>
        )}
      </div>
    </div>
  );
};

export default TextMessage;
