import React from "react";
import { withRouter } from "react-router-dom";
import RouteWithTitle from "../../Components/Navigation/AppRoute/RouteWithTitle";
import Navbar from "../../Components/Navbar";
import { Notifier } from "../../Components/UI";
import "./styles.scss";
import { getLoginUser, logout } from "../../Utils/helper";
import ScrollToTop from "../../Utils/ScrollToTop";

function MainLayout({ path, component, name, ...props }) {
  const user = getLoginUser();

  if (!user?.token) {
    logout();
    return false;
  }
  return (
    <div className="main-layout">
      <Navbar />
      <div className="container">
        {path && component && (
          <RouteWithTitle
            key={name}
            exact={true}
            path={path}
            component={component}
            name={name}
          />
        )}
      </div>
      <Notifier />
      <ScrollToTop />
    </div>
  );
}

export default withRouter(MainLayout);
