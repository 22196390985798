import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import React from "react";

export default function LoadingProfileCard() {
  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: 250,
        borderColor: "rgba(102,51,153, 0.5)",
        borderRadius: "15px",
        cursor: "pointer",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          position: "relative",
        }}
      >
        <Skeleton
          variant="circular"
          sx={{ width: 150, height: 150, objectFit: "cover" }}
        />
        <Skeleton variant="text" sx={{ mt: 1.5, width: 150 }} />
        <Skeleton variant="text" sx={{ width: 75 }} />
      </CardContent>
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Skeleton variant="text" sx={{ width: "33%" }} />
          <Typography variant="caption" color="#000000">
            {" - "}
          </Typography>
          <Skeleton variant="text" sx={{ width: "33%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Skeleton variant="text" sx={{ width: "33%" }} />
          <Typography variant="caption" color="#000000">
            {" - "}
          </Typography>
          <Skeleton variant="text" sx={{ width: "33%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Skeleton variant="text" sx={{ width: "33%" }} />
          <Typography variant="caption" color="#000000">
            {" - "}
          </Typography>
          <Skeleton variant="text" sx={{ width: "33%" }} />
        </Box>
      </CardContent>
    </Card>
  );
}
