import { CircularProgress } from "@mui/material";
import React from "react";
import "./styles.scss";

export const Spinner = ({ children, loading }) => {
  return (
    <div className="jspin-nested-loading">
      {loading ? (
        <div className="jspin jspin-spinning">
          <div className="jspin-dot jspin-dot-spin">
            <CircularProgress color="primary" />
          </div>
        </div>
      ) : null}
      <div className={`jspin-container ${loading ? "jspin-blur" : ""}`}>
        {children}
      </div>
    </div>
  );
};
