import { createStore, applyMiddleware, compose } from 'redux';
// Imports: Redux
import rootReducer from './reducers';
import { apiMiddleware } from './middlewares';

const saveToLocalStorage = (state) => {
    try {
        localStorage.setItem(
            'state',
            window.btoa(unescape(encodeURIComponent(JSON.stringify(state)))),
        );
    } catch (e) {
        console.error(e);
    }
};

const loadFromLocalStorage = () => {
    try {
        if (localStorage.hasOwnProperty('state')) {
            const stateStr = window.atob(localStorage.getItem('state'));
            return stateStr ? JSON.parse(stateStr) : undefined;
        }
    } catch (e) {
        console.error(e);
        return undefined;
    }
};

const persistedStore = loadFromLocalStorage();

const middlewares = [apiMiddleware];

// if (__DEV__) {
//     // middlewares.push(createLogger());
// }

// Redux: Store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    persistedStore,
    composeEnhancers(applyMiddleware(...middlewares)),
);

store.subscribe(() => {
    saveToLocalStorage(store.getState());
});

// Exports
export default store;
