/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import "./DetailProfile.scss";
import {
  Box,
  Container,
  Grid,
  Button,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  IconButton,
} from "@mui/material";

import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { connect } from "react-redux";
import ReportProfileModal from "../../Components/Modals/ModalContexts/ReportProfileModal";
import {
  FetchContacts,
  FetchPrimeContacts,
  fetchMyMatchesDetail,
  SendMatchInvite,
} from "../../Store/Actions/matchesActionCreator";
import KeyValueInfo from "./components/KeyValueInfo";
import { Spinner } from "../../Components/UI";
import { showMessage } from "../../Utils/helper";
import { EMPTY_VALUE } from "../../Utils/constants";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./DetailProfile.scss";
import { ChatWindowLauncher } from "../../Chat";
import InviteSharedInfoModal from "../../Components/Modals/ModalContexts/InviteSharedInfoModal";

function DetailProfile({
  user,
  dispatchfetchMyMatchesDetail,
  dispatchSendMatchInvite,
  dispatchFetchContact,
  dispatchFetchPrimeContact,
  ...props
}) {
  const [profileInfo, setprofileInfo] = useState({
    ...(props?.location?.state?.data ?? {}),
  });
  const [imageUrls, setImageUrls] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [isLightBoxOpen, toggleLightBox] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [openReportProfileModal, setOpenReportProfileModal] =
    React.useState(false);
    const [isInviteSharedModalOpen, toggleInviteSharedModal] = useState(false);
    const [contactResp, setContactResp] = useState(null);
  const fetchProfileInfo = () => {
    if (!!profileInfo?.id) {
      toggleLoading(true);
      dispatchfetchMyMatchesDetail(
        profileInfo?.id,
        (resp) => {
          if (resp?.status) {
            setprofileInfo({
              ...profileInfo,
              ...(resp?.result?.responseDTO ?? {}),
            });
            setImageUrls(Object.values(resp?.result?.imageUrl));
            toggleLoading(false);
          } else {
            showMessage(resp?.result ?? "Failed to fetch profile", "error");
            props.history.push("/my-matches");
          }
        },
        (err) => {
          props.history.push("/my-matches");
          toggleLoading(false);
        }
      );
    }
  };
  const SendInvitation = () => {
    if (!!profileInfo?.id) {
      toggleLoading(true);
      dispatchSendMatchInvite(
        profileInfo?.id,
        (resp) => {
          if (resp?.status) {
            fetchProfileInfo();
            showMessage(
              resp?.result ?? "Invitation sent successfully",
              "success"
            );
          } else {
            showMessage(resp?.result ?? "Failed to Express Interest", "error");
          }
          toggleLoading(false);
        },
        (err) => {
          props.history.push("/my-matches");
          toggleLoading(false);
        }
      );
    }
  };

  const viewPrimeContact = () => {
    if (!!profileInfo?.id) {
      toggleLoading(true);
      dispatchFetchPrimeContact(
        profileInfo?.id,
        (resp) => {
          if (resp?.status) {
            setContactResp(resp?.result);
            toggleInviteSharedModal(true);
            // showMessage("Fetch Prime successfully", "success");
          } else {
            showMessage(
              resp?.result ?? "Failed to Fetch Prime successfully",
              "error"
            );
          }
          toggleLoading(false);
        },
        (err) => {
          toggleLoading(false);
        }
      );
    }
  };

  const viewContact = () => {
    if (!!profileInfo?.id) {
      toggleLoading(true);
      dispatchFetchContact(
        profileInfo?.id,
        (resp) => {
          if (resp?.status) {
            setContactResp(resp?.result);
            toggleInviteSharedModal(true);
            // showMessage("Fetch view contact successfully", "success");
          } else {
            showMessage(
              resp?.result ?? "Failed to Fetch Prime successfully",
              "error"
            );
          }
          toggleLoading(false);
        },
        (err) => {
          toggleLoading(false);
        }
      );
    }
  };

  let parseDays = (value) => {
    var year, months, week, days;

    year = value >= 365 ? Math.floor(value / 365) : 0;
    value = year ? value - year * 365 : value;

    months = value >= 30 ? Math.floor((value % 365) / 30) : 0;
    value = months ? value - months * 30 : value;

    week = value >= 7 ? Math.floor((value % 365) / 7) : 0;
    value = week ? value - week * 7 : value;

    days = value < 7 ? Math.floor((value % 365) % 7) : 0;

    return (
      (year ? year + (year > 1 ? " years " : " year ") : "") +
      (months ? months + (months > 1 ? " months " : " month ") : "")
    );
  };

  let parseHeight = (value) => {
    var inches = (value * 0.393700787).toFixed(0);
    var feet = Math.floor(inches / 12);
    inches %= 12;

    let returnValue = feet + " feet " + inches + " inch";

    return returnValue;
  };

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
    if (!user?.accessToken) {
      props.history.push("/");
    }
    if (!profileInfo?.id) {
      props.history.push("/my-matches");
    }
    fetchProfileInfo();
  }, []);

  const chatButton = (
    <ChatWindowLauncher
      userProfile={{
        id: profileInfo?.id,
        teamName: `${profileInfo?.firstName ?? ""} ${
          profileInfo?.lastName ?? ""
        }`,
        imageUrl: imageUrls[0],
      }}
      disabled={!user?.prime}
    >
      <Button
        sx={{
          mt: 6,
          width: "100%",
          textTransform: "none",
          borderColor: "#663399",
          color: "#663399",
          "&:hover": {
            borderColor: "#663399",
            backgroundColor: "#663399",
            color: "#fff",
          },
        }}
        variant="outlined"
        disabled={!user?.prime}
      >
        Chat
      </Button>
    </ChatWindowLauncher>
  );

  const isMobileView = window.innerWidth < 600;
  return (
    <Spinner loading={loading}>
      <div id="DetailProfile" className="detailprofile">
        <Container
          className="base-container"
          maxWidth="unset"
          style={{ paddingBottom: "0px !important" }}
        >
                {!!isInviteSharedModalOpen && (
        <InviteSharedInfoModal
          open={isInviteSharedModalOpen}
          data={contactResp}
          onClose={() => {
            toggleInviteSharedModal(false);
          }}
          next={() => {
            toggleInviteSharedModal(false);
          }}
        />
      )}
          <Grid container={true} justifyContent={"space-between"}>
            <Grid xs={12} md={12} className="mobile_margin">
              <Grid container>
                <Grid item xs={6} md={12} className="mobile_margin">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      const route = props?.location?.state?.pathname?.includes(
                        "/my-inbox"
                      )
                        ? "/my-inbox"
                        : "/my-matches";
                      props.history.push(route, {
                        filterInfo: {
                          ...(props?.location?.state?.filterInfo ?? {}),
                        },
                      });
                      // props?.history?.goBack();
                    }}
                  >
                    <Tooltip title="GO BACK">
                      <IconButton aria-label="delete" sx={{ mr: "16px" }}>
                        <ChevronLeftIcon />
                      </IconButton>
                    </Tooltip>

                    <Typography
                      variant={"span"}
                      color={"#000000"}
                      fontWeight="bold"
                      textAlign={"left"}
                    >
                      Go Back
                    </Typography>
                  </Box>
                </Grid>
                {isMobileView ? (
                  <Grid item xs={6} md={12} className="mobile_margin">
                    <Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          style={{
                            fontWeight: 300,
                            color: "#F86969",
                            backgroundColor: "#fff",
                            borderColor: "#F86969",
                            marginTop: "0.5em",
                          }}
                          variant="outlined"
                          disableElevation
                          size="small"
                          onClick={() => {
                            setOpenReportProfileModal(true);
                          }}
                        >
                          Report Profile
                        </Button>
                      </div>
                      {!!openReportProfileModal && (
                        <ReportProfileModal
                          open={openReportProfileModal}
                          data={profileInfo?.id}
                          onClose={() => {
                            setOpenReportProfileModal(false);
                          }}
                          next={() => {
                            setOpenReportProfileModal(false);
                            // handleClose();
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid xs={6} md={2} className="desktopBorder mobile_margin ">
              <center>
                {!!imageUrls[0] ? (
                  <Avatar
                    alt={profileInfo?.firstName ?? ""}
                    sx={{
                      width: 150,
                      height: 150,
                      objectFit: "cover",
                      alignItems: "center",
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                    }}
                    src={imageUrls[0]}
                  />
                ) : (
                  <Avatar
                    alt={profileInfo?.firstName ?? ""}
                    sx={{
                      width: 150,
                      height: 150,
                      objectFit: "cover",
                      alignItems: "center",
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                    }}
                    src={""}
                  />
                )}
                <Button
                  sx={{
                    "&:hover": {
                      color: "white",
                      background: "#663399",
                    },
                    mx: 1,
                    mt: 2,
                    fontWeight: 900,
                    backgroundColor: "#ffb955",
                  }}
                  variant="contained"
                  disableElevation
                  size="large"
                  paddingTop="1em"
                  paddingBottom="1em"
                  onClick={(e) => {
                    if (!!imageUrls?.length) {
                      toggleLoading(true);
                      toggleLightBox(true);
                      toggleLoading(false);
                    } else {
                      showMessage("No Image Found", "error");
                    }
                  }}
                  disableRipple
                  className="view_all_image_button"
                >
                  View All Images
                </Button>
              </center>
            </Grid>
            <Grid
              container={true}
              xs={6}
              md={10}
              justifyContent={"space-between"}
              className="desktopBorder"
              style={{
                padding: "1em",
                position: "relative",
                borderLeft: "unset",
              }}
            >
              <Grid item md={8}>
                <Typography
                  variant="h4"
                  fontFamily="Roboto"
                  fontWeight={900}
                  className="responsive_text"
                >
                  {`${profileInfo?.firstName ?? EMPTY_VALUE} ${
                    profileInfo?.lastName ?? EMPTY_VALUE
                  }`}
                </Typography>
                <Typography
                  variant="h5"
                  fontFamily="Roboto"
                  fontWeight={600}
                  color="black"
                  className="responsive_text_sub"
                  sx={{
                    borderBottom: "1px solid black",
                    display: "table",
                  }}
                >
                  Profile ID:{profileInfo?.id ?? EMPTY_VALUE}
                </Typography>
                <Typography
                  variant="h5"
                  fontFamily="Roboto"
                  fontWeight={400}
                  color="black"
                  className="responsive_text_sub"
                  sx={{ paddingTop: "3vh" }}
                >
                  Employer: {profileInfo?.employer ?? EMPTY_VALUE}
                </Typography>
                <Typography
                  variant="h5"
                  fontFamily="Roboto"
                  fontWeight={400}
                  color="black"
                  className="responsive_text_sub"
                >
                  Age - {profileInfo?.age ?? EMPTY_VALUE}
                </Typography>
                <Button
                  style={{
                    fontWeight: 900,
                    backgroundColor: "#FFB8B8",
                    marginTop: "16px",
                  }}
                  variant="contained"
                  disableElevation
                  size="medium"
                  className="hideMobile"
                  onClick={() => {
                    setOpenReportProfileModal(true);
                  }}
                >
                  Report Profile
                </Button>
              </Grid>
              <Grid item md={4} className="hideMobile">
                {/* <Button
                  style={{ width: "100%", fontWeight: 900, marginTop: "20%" }}
                  variant="contained"
                  disableElevation
                  size="large"
                  onClick={() => SendInvitation()}
                  >
                    Express Interest
                  </Button> */}
                {profileInfo?.inviteSent && profileInfo.inviteAccepted ? (
                  <Button
                    style={{ width: "100%", fontWeight: 900, marginTop: "20%" }}
                    variant="contained"
                    disableElevation
                    size="large"
                    sx={{
                      "&:hover": {
                        color: "white",
                        background: "#663399",
                      },
                    }}
                    onClick={() => viewContact()}
                  >
                    View Contact
                  </Button>
                ) : profileInfo?.inviteSent ? (
                  <Button
                    style={{
                      width: "100%",
                      fontWeight: 900,
                      marginTop: "20%",
                      backgroundColor: !user?.prime ? "gray" : "",
                      color: !user?.prime ? "gray" : "",
                    }}
                    sx={{
                      "&:hover": {
                        color: "white",
                        background: "#663399",
                      },
                    }}
                    variant="contained"
                    disableElevation
                    size="large"
                    onClick={() => {
                      user?.prime
                        ? viewPrimeContact()
                        : showMessage(
                            "You need to be a VVS PRIME member to view contact details OR wait till the invite is accepted.",
                            "error"
                          );
                    }}
                  >
                    View Contact
                  </Button>
                ) : (
                  <Button
                    style={{ width: "100%", fontWeight: 900, marginTop: "20%" }}
                    variant="contained"
                    disableElevation
                    size="large"
                    sx={{
                      "&:hover": {
                        color: "white",
                        background: "#663399",
                      },
                    }}
                    onClick={() => SendInvitation()}
                  >
                    Express Interest
                  </Button>
                )}
                {chatButton}
                {/* <Button
                  style={{
                    width: "100%",
                    fontWeight: 900,
                    marginTop: "1em",
                    color: "#F86969",
                    backgroundColor: "#fff",
                    borderColor: "#F86969",
                  }}
                  variant="outlined"
                  disableElevation
                  size="large"
                >
                  Add To SHORTLIST
                </Button> */}
              </Grid>

              {!!openReportProfileModal && (
                <ReportProfileModal
                  open={openReportProfileModal}
                  data={profileInfo?.id}
                  onClose={() => {
                    setOpenReportProfileModal(false);
                  }}
                  next={() => {
                    setOpenReportProfileModal(false);
                    // handleClose();
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ px: "24px", pt: 3 }}>
          {!!isMobileView && chatButton}
          <Typography
            style={{
              marginTop: "1em",
              marginBottom: "1em",
              fontWeight: "bold",
              fontSize: 16,
              color: "green",
            }}
          >
            {`Matches are generated based on Gotra, Gunas >=18, Grihamaitri >=2.5
            and Age gap greater than 6 months.`}
          </Typography>
          {/* {parseEkanadiRemarks(profileInfo) && (
            <Accordion className="bordercoloraccordion bottommargin">
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="reverseflex bordercoloraccordion"
                style={{ backgroundColor: "#fab8b9" }}
              >
                <Typography
                  style={{ marginLeft: "1em", fontWeight: "bold", fontSize: 18 }}
                >
                  Ekanadi Profile: Consult Astrologer for more details.
                </Typography>
              </AccordionSummary>
            </Accordion>)} */}
          <Accordion className="bordercoloraccordion bottommargin">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    border: "1px solid #663399",
                    borderRadius: "1em",
                    color: "#663399",
                  }}
                  color="#663399"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="reverseflex bordercoloraccordion"
            >
              <Typography
                style={{ marginLeft: "1em", fontWeight: "bold", fontSize: 18 }}
              >
                Personal Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KeyValueInfo
                label={"Age"}
                value={profileInfo?.age ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Current Region of stay"}
                value={profileInfo?.region ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Current Place of stay"}
                value={profileInfo?.location ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Height"}
                value={
                  profileInfo
                    ? profileInfo.height
                      ? parseHeight(profileInfo.height)
                      : EMPTY_VALUE
                    : EMPTY_VALUE
                }
              />
              <KeyValueInfo
                label={"Address"}
                value={profileInfo?.address ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"About Vadhu/Vara"}
                value={profileInfo?.about ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Partner Preferences"}
                value={profileInfo?.partnerPreferences ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Build"}
                value={profileInfo?.build ?? EMPTY_VALUE}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion className="bordercoloraccordion bottommargin">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    border: "1px solid #663399",
                    borderRadius: "1em",
                    color: "#663399",
                  }}
                  color="#663399"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="reverseflex bordercoloraccordion"
            >
              <Typography
                style={{ marginLeft: "1em", fontWeight: "bold", fontSize: 18 }}
              >
                Education / Career
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KeyValueInfo
                label={"Higher Qualification"}
                value={profileInfo?.higherQualification ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Post Graduation"}
                value={profileInfo?.postGraduation ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Post Graduation Year"}
                value={profileInfo?.postGraduationYear ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Graduation"}
                value={profileInfo?.graduation ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Graduation Year"}
                value={profileInfo?.graduationYear ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Work Experience"}
                value={profileInfo?.workExperience ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Working Since"}
                value={profileInfo?.workingSince ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Employer"}
                value={profileInfo?.employer ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Previous Employment"}
                value={profileInfo?.previousEmployment ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Monthly Income (Rs)"}
                value={profileInfo?.income ?? EMPTY_VALUE}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion className="bordercoloraccordion bottommargin">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    border: "1px solid #663399",
                    borderRadius: "1em",
                    color: "#663399",
                  }}
                  color="#663399"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="reverseflex bordercoloraccordion"
            >
              <Typography
                style={{ marginLeft: "1em", fontWeight: "bold", fontSize: 18 }}
              >
                Family Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KeyValueInfo
                label={"Father Name"}
                value={profileInfo?.fatherName ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Father Occupation"}
                value={profileInfo?.fatherOccupation ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Mother Name"}
                value={profileInfo?.motherName ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Mother Occupation"}
                value={profileInfo?.motherOccupation ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"No. of Brothers"}
                value={profileInfo?.brotherCount ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"No. of Sisters"}
                value={profileInfo?.sisterCount ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Family Values"}
                value={profileInfo?.familyValues ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Family Type"}
                value={profileInfo?.familyType ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Family Status"}
                value={profileInfo?.familyStatus ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Views on Family Value & Traditions"}
                value={profileInfo?.familyViews ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Other information of my family"}
                value={profileInfo?.familyOtherInfo ?? EMPTY_VALUE}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion className="bordercoloraccordion bottommargin">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    border: "1px solid #663399",
                    borderRadius: "1em",
                    color: "#663399",
                  }}
                  color="#663399"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="reverseflex bordercoloraccordion"
            >
              <Typography
                style={{ marginLeft: "1em", fontWeight: "bold", fontSize: 18 }}
              >
                General Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KeyValueInfo
                label={"Mother Tongue"}
                value={profileInfo?.motherTongue ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Category"}
                value={profileInfo?.category ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Matha"}
                value={profileInfo?.matha ?? EMPTY_VALUE}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion className="bordercoloraccordion bottommargin">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    border: "1px solid #663399",
                    borderRadius: "1em",
                    color: "#663399",
                  }}
                  color="#663399"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="reverseflex bordercoloraccordion"
            >
              <Typography
                style={{ marginLeft: "1em", fontWeight: "bold", fontSize: 18 }}
              >
                Horoscope Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KeyValueInfo
                label={"Gotra"}
                value={profileInfo?.gotra ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Rashi"}
                value={profileInfo?.rashi ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Nakshatra"}
                value={profileInfo?.nakshatra ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Charana/Pada"}
                value={profileInfo?.charana ?? EMPTY_VALUE}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion className="bordercoloraccordion bottommargin">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    border: "1px solid #663399",
                    borderRadius: "1em",
                    color: "#663399",
                  }}
                  color="#663399"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="reverseflex bordercoloraccordion"
            >
              <Typography
                style={{ marginLeft: "1em", fontWeight: "bold", fontSize: 18 }}
              >
                Matching Details in Profile
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KeyValueInfo
                label={"Guna"}
                value={profileInfo?.guna ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Grihamaitri"}
                value={profileInfo?.grihamaitri ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Age Gap"}
                value={
                  profileInfo
                    ? profileInfo.agegapindays
                      ? parseDays(profileInfo.agegapindays)
                      : EMPTY_VALUE
                    : EMPTY_VALUE
                }
              />
              /*{" "}
              <KeyValueInfo
                label={"Dosha 1"}
                value={profileInfo?.dosha1 ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Dosha 2"}
                value={profileInfo?.dosha2 ?? EMPTY_VALUE}
              />
              <KeyValueInfo
                label={"Dosha 3"}
                value={profileInfo?.dosha3 ?? EMPTY_VALUE}
              />{" "}
              */
              <KeyValueInfo
                label={"Remarks"}
                value={parseRemarks(profileInfo)}
              />
              <Typography
                style={{
                  marginTop: "1em",
                  fontWeight: "bold",
                  fontSize: 12,
                  color: "green",
                }}
              >
                Note: Matches are generated programmatically. Consult Astrologer
                for more details.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Typography
            style={{
              color: "#F86969",
              borderBottom: "1px solid #F86969",
              display: "inline-block",
              marginTop: 30,
            }}
          >
            NOTE:
          </Typography>
          <ul>
            <li>
              {" "}
              <Typography>
                You can view the contact details from 'Inbox - Sent' tab once
                you express interest. If you are a VVS PRIME member, you can
                view contact immediately. Or you have to wait till your
                invitation is Accepted.
              </Typography>
            </li>
          </ul>
        </Container>
        <Box item xs={12} className="onlyMobile floatingbuttons">
          {profileInfo?.inviteSent && profileInfo.inviteAccepted ? (
            <Button
              style={{ width: "100%", fontWeight: 900 }}
              className="sendinvitation"
              variant="contained"
              disableElevation
              size="large"
              onClick={() => viewContact()}
            >
              View Contact
            </Button>
          ) : profileInfo?.inviteSent ? (
            <Button
              style={{
                width: "100%",
                fontWeight: 900,
                backgroundColor: !user?.prime ? "gray" : "",
                color: !user?.prime ? "gray" : "",
              }}
              className="sendinvitation"
              variant="contained"
              disableElevation
              size="large"
              onClick={() => {
                user?.prime
                  ? viewPrimeContact()
                  : showMessage(
                      "You need to be a VVS PRIME member to view contact details OR wait till the invite is accepted.",
                      "error"
                    );
              }}
            >
              View Contact
            </Button>
          ) : (
            <Button
              style={{ width: "100%", fontWeight: 900 }}
              className="sendinvitation"
              variant="contained"
              disableElevation
              size="large"
              onClick={() => SendInvitation()}
            >
              Express Interest
            </Button>
          )}
        </Box>
        {isLightBoxOpen && (
          <Lightbox
            mainSrc={imageUrls[imageIndex]}
            nextSrc={imageUrls[(imageIndex + 1) % imageUrls.length]}
            prevSrc={
              imageUrls[(imageIndex + imageUrls.length - 1) % imageUrls.length]
            }
            onCloseRequest={() => toggleLightBox(false)}
            onMovePrevRequest={() =>
              setImageIndex(
                (imageIndex + imageUrls.length - 1) % imageUrls.length
              )
            }
            onMoveNextRequest={() =>
              setImageIndex((imageIndex + 1) % imageUrls.length)
            }
          />
        )}
      </div>
    </Spinner>
  );
}

let parseEkanadiRemarks = (profileInfo) => {
  let doshaString = "";
  let dosha1 = profileInfo?.dosha1;
  let dosha2 = profileInfo?.dosha2;
  let dosha3 = profileInfo?.dosha3;
  if (
    dosha1?.match(/^Eknadi$/) ||
    dosha2?.match(/^Eknadi$/) ||
    dosha3?.match(/^Eknadi$/)
  ) {
    return true;
  } else return false;
};

let parseRemarks = (profileInfo) => {
  let remarksString = EMPTY_VALUE;
  let doshaString = "";
  let dosha1 = profileInfo?.dosha1;
  let dosha2 = profileInfo?.dosha2;
  let dosha3 = profileInfo?.dosha3;
  if (
    dosha1?.match(/^Eknadi$/) ||
    dosha2?.match(/^Eknadi$/) ||
    dosha3?.match(/^Eknadi$/)
  ) {
    doshaString = "Ekanadi (In some cases this is not a concern)";
  }
  if (profileInfo?.remarks == null) return doshaString;
  else remarksString = doshaString + " - " + profileInfo?.remarks;
  // return remarksString;
  return doshaString; // Removing remarks as asked not to show mother in law and other texts
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchfetchMyMatchesDetail: (id, onSuccess, onError) =>
    dispatch(fetchMyMatchesDetail(id, onSuccess, onError)),
  dispatchSendMatchInvite: (id, onSuccess, onError) =>
    dispatch(SendMatchInvite(id, onSuccess, onError)),
  dispatchFetchPrimeContact: (id, onSuccess, onError) =>
    dispatch(FetchPrimeContacts(id, onSuccess, onError)),
  dispatchFetchContact: (id, onSuccess, onError) =>
    dispatch(FetchContacts(id, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailProfile);
