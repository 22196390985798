import "./App.scss";
import theme from "./Utils/theme";
import AppRoute from "./Components/Navigation/AppRoute";
import { Provider } from "react-redux";
import store from "./Store/configureStore";
import { SnackbarProvider } from "notistack";
import { CssBaseline, Slide, ThemeProvider } from "@mui/material";
import { ConfirmProvider } from "./Components/ConfirmationModal";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import ReactGA from "react-ga";
import ChatWindow, { ChatWindowRef } from "./Chat";
const MAX_SNACKBAR = 3;
//Set the auto hide time, the default value is 5 seconds, which is 5000 milliseconds
const AUTO_HIDE_DURATION = 3000;
//Set the position of the message bar, the default value is the bottom left
const POSITION = {
  vertical: "bottom",
  horizontal: "center",
};
// const TRACKING_ID = "G-0SLR9DFFQM";
// ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname);
function App() {
  return (
    <Provider store={store}>
      <div className="App">
        {/* <TawkMessengerReact
                propertyId="5f44a5b61e7ade5df443a843"
                widgetId="default"/> */}
        {/* <a
          href="https://wa.me/message/BN2R4ZFIMY4JM1"
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a> */}
      </div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={MAX_SNACKBAR}
          autoHideDuration={AUTO_HIDE_DURATION}
          anchorOrigin={POSITION}
          TransitionComponent={Slide}
        >
          <ConfirmProvider>
            <AppRoute />
            <ChatWindow ref={ChatWindowRef} />
          </ConfirmProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
