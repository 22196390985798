/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  GetOrderDetails,
  setUserInfo,
  VerifyOrderDetails,
} from "../../../Store/Actions/userActionCreator";
import { CustomTextField, Button, Spinner } from "../../UI";
import Modal from "../Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { withRouter } from "react-router-dom";
import { showMessage } from "../../../Utils/helper";
import { makeStyles } from "@mui/styles";
import { DonateImage } from "../../../Assets/svg";
import Backdrop from '@mui/material/Backdrop';
import "./style.scss";
const useStyles = makeStyles((theme) => ({
  topBar: {
    display: "flex",
    flex: "1 1 15%",
    // border: '1px solid black',
  },

  otpInputStyle: {
    width: "48px !important",
    height: "48px !important",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "#663399",
    color: "#663399",
    fontSize: "24px",
    fontWeight: 600,
  },
  error: {
    border: "1px solid red !important",
  },
}));

const validationSchema = yup.object({
  amount: yup.number("Invalid Donation Amount").integer()
    .transform((value) => (value === '' ? 0 : value))
    .transform((value) => (isNaN(value) ? 0 : value))
    .typeError("Invalid Donation Amount")
    .min(500, "Minimum Donation amount should be 500 Rs")
    .max(100000, "Donation amount is more than the maximum limit")
    .required("Amount cannot be empty"),
});


function DonateModal({
  user,
  data,
  open,
  onClose,
  next,
  dispatchGetOrderDetails,
  dispatchVerifyOrderDetails,
  ...props
}) {
  const { palette } = useTheme();
  const classes = useStyles();
  const [isOpen, toggleModal] = useState(open);
  // const [loading, toggleLoading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    toggleModal(!isOpen);
    onClose && onClose();
  };

  const formik = useFormik({
    initialValues: {
    },
    validationSchema: validationSchema,
  });

  const loadRazorpay = () => {
    if (formik.isValid) {
      setLoading(true);
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onerror = () => {
        alert('Razorpay SDK failed to load. Are you online?');
      };
      script.onload = async () => {

        try {

          dispatchGetOrderDetails({
            amount: formik.values.amount,
          },
            (resp) => {
              if (resp?.status) {
                const options = {
                  // key: resp.result.razorpayKey,
                  name: 'JSPN VVS PRIME',
                  description: 'JSPN VVS PRIME',
                  order_id: resp.result.orderId,
                  handler: async function (response) {
                    dispatchVerifyOrderDetails({
                      "orderId": response.razorpay_order_id,
                      "paymentId": response.razorpay_payment_id, "signature": response.razorpay_signature
                    },
                      (resp) => {
                        if (resp?.status) {
                          setLoading(false);
                          handleClose();
                          showMessage(resp?.result?.message, "success");
                        } else {
                          setLoading(true);
                          showMessage(resp?.result, "error");
                        }
                      }
                    )
                  },
                  prefill: {
                    name: 'JSPN',
                    email: resp.result.email,
                    contact: resp.result.contact,
                  },
                  notes: {
                    // address: 'example address',
                  },
                  theme: {
                    color: '#80c0f0',
                  },
                };

                setLoading(false);
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
              } else {
                setLoading(false);
                showMessage(resp?.result, "error");
              }
            },
            (err) => {
              setLoading(false);
              showMessage("Something went wrong!", "error");
            }
          );
        } catch (err) {
          setLoading(false);
          showMessage(err, "error");
        }
      };
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
  }, [formik.values]);


  const actions = [];
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 9999 }}
      open={loading}
    >
      {/* <CircularProgress color="inherit" /> */}

      {/* // <Spinner loading={loading}> */}
      <Modal
        onClose={handleClose}
        open={isOpen}
        title={""}
        isCustomTitle={true}
        actions={actions}
        loading={loading}
        isForm={true}
        onSubmit={formik.handleSubmit}
        maxWidth="sm"
      >
        <Box className="mainbox">
          <DonateImage className="forgetimg" />
          <Box className="modelforum">
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                marginTop: "8px",
                marginBottom: "24px",
                // fontFamily: "Montserrat",
                fontWeight: "bold",
              }}
            >
              Donate
            </Typography>
            <Typography
              //   variant="h6"
              style={{
                marginTop: "8px",
                marginBottom: "24px",
                // fontFamily: "Montserrat",
                color: "#000000",
              }}
            >
              All donations to Jaya Satya Pramoda Nidhi of amount INR 500 and above will be provided with complimentary feature of immediate access to contacts for a duration of 6 months.
              To view contacts, send an invite by clicking on 'Express Interest' and navigate to SENT tab in 'My Inbox'
            </Typography>
            <CustomTextField
              fullWidth={true}
              label="Enter the amount in INR"
              id="amount"
              name="amount"
              value={formik.values.amount > 0 ? Math.floor(formik.values.amount) : 0}
              onChange={
                formik.handleChange("amount")
              }
              error={
                formik.touched.amount ? Boolean(formik.errors.amount) : false
              }
              helperText={formik.touched.amount && formik.errors.amount}
              style={{ marginBottom: "16px" }}

            />
           <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{
                fontWeight: "bold",
              }}
              type="submit"
              onClick={loadRazorpay}
            // disabled={!formik.isValid}
            >
              DONATE NOW
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              sx={{
                backgroundColor: "#fff",
                color: palette.secondary.main,
                width: "100%",
                borderColor: palette.secondary.main,
                "&:hover": {
                  backgroundColor: palette.secondary.main,
                  borderColor: palette.secondary.main,
                  color: "white",
                },
                marginTop: "2em"
              }}
              disableElevation={true}
              onClick={(e) => {
                handleClose()
              }}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* </Spinner> */}
    </Backdrop>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetOrderDetails: (amount, onSuccess, onError) =>
    dispatch(GetOrderDetails(amount, onSuccess, onError)),
  dispatchVerifyOrderDetails: (data, onSuccess, onError) =>
    dispatch(VerifyOrderDetails(data, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DonateModal));
