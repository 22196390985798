import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomTextField,
  StyledMenuItem,
  Button,
  Spinner,
} from "../../Components/UI";
import "./Registration.scss";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import { useFormik } from "formik";
import * as yup from "yup";
import { HeadTitle, HorizontalLine } from ".";
import {
  BUILD_ENUM,
  CHARANA_ENUM,
  FAMILY_STATUS_ENUM,
  FAMILY_TYPE_ENUM,
  FAMILY_VALUE_ENUM,
  GOTRA_ENUM,
  GRADUATION_ENUM,
  HEIGHT_ENUM,
  HIGHEST_EDUCATION_ENUM,
  LOCATION_REGION,
  NAKSHATHRA_ENUM,
  POST_GRADUATION,
  RASHI_ENUM,
  YEARS_LIST,
} from "../../Utils/constants";
import { connect } from "react-redux";
import {
  GetAdvanceDetails,
  PostAdvanceDetails,
} from "../../Store/Actions/userActionCreator";
import { showMessage } from "../../Utils/helper";

const validationSchema = yup.object({
  gotra: yup
    .string("Invalid gotra")
    .trim()
    .nullable()
    .required("gotra cannot be empty"),
  rashi: yup
    .string("Invalid rashi")
    .trim()
    .nullable()
    .required("rashi cannot be empty"),
  nakshatra: yup
    .string("Invalid nakshatra")
    .trim()
    .nullable()
    .required("nakshatra cannot be empty"),
  height: yup
    .string("Invalid height")
    .trim()
    .nullable()
    .required("height cannot be empty"),
  location: yup
    .string("Invalid location")
    .trim()
    .nullable()
    .required("location cannot be empty")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  region: yup
    .string("Invalid region")
    .trim()
    .nullable()
    .required("region cannot be empty"),
  charana: yup.string("Invalid charana").trim().nullable(),
  build: yup.string("Invalid build").trim().nullable(),
  about: yup.string("Invalid about").trim().nullable(),
  partnerPreferences: yup
    .string("Invalid partner preferences")
    .trim()
    .nullable(),
  higherQualification: yup
    .string("Invalid higher qualification")
    .trim()
    .nullable()
    .required("higher qualification cannot be empty"),
  postGraduation: yup.string("Invalid post graduation").trim().nullable(),
  // .required("post graduation cannot be empty"),
  postGraduationYear: yup
    .string("Invalid post graduation year")
    .trim()
    .nullable(),
  // .required("post graduation year cannot be empty"),
  graduation: yup.string("Invalid graduation").trim().nullable(),
  // .required("graduation cannot be empty"),
  graduationYear: yup.string("Invalid graduation year").trim().nullable(),
  // .required("graduation year cannot be empty"),

  employer: yup.string("Invalid employer").trim().nullable(),
  previousEmployment: yup
    .string("Invalid previous employment")
    .trim()
    .nullable(),
  workingSince: yup.string("Invalid working since").trim().nullable(),
  workExperience: yup.string("Invalid work experience").trim().nullable(),
  income: yup.string("Invalid work income").trim().nullable(),
  familyValues: yup
    .string("Invalid family values")
    .trim()
    .nullable()
    .required("family values cannot be empty"),
  familyType: yup
    .string("Invalid family type")
    .trim()
    .nullable()
    .required("family type cannot be empty"),
  familyStatus: yup
    .string("Invalid family status")
    .trim()
    .nullable()
    .required("family status cannot be empty"),
  familyOtherInfo: yup.string("Invalid family other info").trim().nullable(),
  familyViews: yup.string("Invalid family views").trim().nullable(),
});

function AdvanceForm({
  dispatchGetAdvanceDetails,
  dispatchPostAdvanceDetails,
  ...props
}) {
  const [loading, toggleLoading] = useState(false);
  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      gotra: "",
      rashi: "",
      nakshatra: "",
      height: "",
      location: "",
      region: "",
      charana: "",
      build: "",
      about: "",
      partnerPreferences: "",
      higherQualification: "",
      postGraduation: "",
      postGraduationYear: "",
      graduation: "",
      graduationYear: "",
      employer: "",
      previousEmployment: "",
      workingSince: "",
      workExperience: "",
      income: "",
      familyValues: "",
      familyType: "",
      familyStatus: "",
      familyOtherInfo: "",
      familyViews: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      dispatchPostAdvanceDetails(
        {
          ...values,
        },
        (resp) => {
          if (!!resp?.status) {
            props.history.push("/register/profile");
          } else {
            showMessage(resp?.result, "error");
          }
        },
        (err) => {
          showMessage(err, "error");
        }
      );
    },
  });

  useEffect(() => {
    toggleLoading(true);
    dispatchGetAdvanceDetails(
      (resp) => {
        if (!!resp?.status) {
          formik.setValues({
            ...resp?.result,
          });
        } else {
          showMessage(resp?.result || "", "error");
        }
        toggleLoading(false);
      },
      (err) => {
        toggleLoading(false);
        showMessage(err, "error");
      }
    );
  }, []);

  return (
    <Box className="registration-advance-form">
      <a
        href="https://wa.me/message/BN2R4ZFIMY4JM1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <Spinner loading={loading}>
        <form onSubmit={formik.handleSubmit}>
          <HeadTitle title="HOROSCOPE DETAILS" />
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Gotra"
                select
                fullWidth={true}
                id="gotra"
                name="gotra"
                value={formik.values.gotra}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.gotra ? Boolean(formik.errors.gotra) : false
                }
                helperText={formik.touched.gotra && formik.errors.gotra}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(GOTRA_ENUM).map((gotra) => {
                  return (
                    <StyledMenuItem key={gotra} value={GOTRA_ENUM[gotra]}>
                      {gotra}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Rashi"
                select
                fullWidth={true}
                id="rashi"
                name="rashi"
                value={formik.values.rashi}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.rashi ? Boolean(formik.errors.rashi) : false
                }
                helperText={formik.touched.rashi && formik.errors.rashi}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(RASHI_ENUM).map((rashi) => {
                  return (
                    <StyledMenuItem key={rashi} value={RASHI_ENUM[rashi]}>
                      {rashi}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Nakshatra"
                select
                fullWidth={true}
                id="nakshatra"
                name="nakshatra"
                value={formik.values.nakshatra}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                error={
                  formik.touched.nakshatra
                    ? Boolean(formik.errors.nakshatra)
                    : false
                }
                helperText={formik.touched.nakshatra && formik.errors.nakshatra}
                isRequired={true}
                variant={"standard"}
              >
                {NAKSHATHRA_ENUM[formik?.values?.rashi ?? ""]?.length ? (
                  NAKSHATHRA_ENUM[formik?.values?.rashi ?? ""].map((rashi) => {
                    return (
                      <StyledMenuItem key={rashi} value={rashi}>
                        {rashi}
                      </StyledMenuItem>
                    );
                  })
                ) : (
                  <StyledMenuItem value={""}>No Data Found</StyledMenuItem>
                )}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Charana/Pada"
                select
                fullWidth={true}
                id="charana"
                name="charana"
                value={formik.values.charana}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.charana
                    ? Boolean(formik.errors.charana)
                    : false
                }
                helperText={formik.touched.charana && formik.errors.charana}
                isRequired={false}
                variant={"standard"}
              >
                {Object.keys(CHARANA_ENUM).map((charana) => {
                  return (
                    <StyledMenuItem key={charana} value={CHARANA_ENUM[charana]}>
                      {charana}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
          </Grid>
          <HorizontalLine />
          <HeadTitle title={"PERSONAL DETAILS"} />
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Where do Vadhu/Vara Stay currently (Region)?"
                select
                fullWidth={true}
                id="region"
                name="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.region ? Boolean(formik.errors.region) : false
                }
                helperText={formik.touched.region && formik.errors.region}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(LOCATION_REGION).map((region) => {
                  return (
                    <StyledMenuItem
                      key={region}
                      value={LOCATION_REGION[region]}
                    >
                      {region}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Where do Vadhu/Vara Stay currently (Place)?"
                id="location"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.location
                    ? Boolean(formik.errors.location)
                    : false
                }
                helperText={formik.touched.location && formik.errors.location}
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Height in feet"
                select
                fullWidth={true}
                id="height"
                name="height"
                value={formik.values.height}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.height ? Boolean(formik.errors.height) : false
                }
                helperText={formik.touched.height && formik.errors.height}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(HEIGHT_ENUM).map((height) => {
                  return (
                    <StyledMenuItem key={height} value={HEIGHT_ENUM[height]}>
                      {height}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              margin={"auto 0"}
              padding={"16px 0 16px 0"}
              paddingBottom="1.5em"
            >
              <Grid item xs={12} md={2} margin={"auto 0"}>
                <Typography
                  color={"#434343"}
                  marginRight={10}
                  marginLeft={"16px"}
                >
                  Build
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} margin={"auto 0"} marginLeft={"16px"}>
                <RadioGroup
                  row={true}
                  id="build"
                  name="build"
                  value={formik.values.build}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(BUILD_ENUM)?.map((build) => {
                    return (
                      <Grid item xs={6} md={3} margin={"auto 0"}>
                        <FormControlLabel
                          value={BUILD_ENUM[build]}
                          control={<Radio />}
                          label={build}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
            </Grid>
            <FormHelperText
              error={
                formik.touched.build ? Boolean(formik.errors.build) : false
              }
            >
              {!!formik.touched.build && formik.errors.build}
            </FormHelperText>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <Box style={{ marginTop: 15 }}>
                <Typography variant="body1" color={"#434343"}>
                  About themselves
                </Typography>
                <CustomTextField
                  fullWidth={true}
                  multiline={true}
                  label=""
                  rows={2}
                  id="about"
                  name="about"
                  value={formik.values.about}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.about ? Boolean(formik.errors.about) : false
                  }
                  helperText={formik.touched.about && formik.errors.about}
                  style={{ marginBottom: "16px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <Box style={{ marginTop: 15 }}>
                <Typography variant="body1" color={"#434343"}>
                  Partner Preferences
                </Typography>
                <CustomTextField
                  fullWidth={true}
                  multiline={true}
                  label=""
                  rows={2}
                  id="partnerPreferences"
                  name="partnerPreferences"
                  value={formik.values.partnerPreferences}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.partnerPreferences
                      ? Boolean(formik.errors.partnerPreferences)
                      : false
                  }
                  helperText={
                    formik.touched.partnerPreferences &&
                    formik.errors.partnerPreferences
                  }
                  style={{ marginBottom: "16px" }}
                />
              </Box>
            </Grid>
          </Grid>
          <HorizontalLine />
          <HeadTitle
            title={"EDUCATION QUALIFICATIONS & PROFESSIONAL DETAILS"}
          />
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Highest Qualification"
                select
                fullWidth={true}
                id="higherQualification"
                name="higherQualification"
                value={formik.values.higherQualification}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.higherQualification
                    ? Boolean(formik.errors.higherQualification)
                    : false
                }
                helperText={
                  formik.touched.higherQualification &&
                  formik.errors.higherQualification
                }
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(HIGHEST_EDUCATION_ENUM).map((h_edu) => {
                  return (
                    <StyledMenuItem
                      key={h_edu}
                      value={HIGHEST_EDUCATION_ENUM[h_edu]}
                    >
                      {h_edu}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Post-Graduation"
                select
                fullWidth={true}
                id="postGraduation"
                name="postGraduation"
                value={formik.values.postGraduation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postGraduation
                    ? Boolean(formik.errors.postGraduation)
                    : false
                }
                helperText={
                  formik.touched.postGraduation && formik.errors.postGraduation
                }
                isRequired={false}
                variant={"standard"}
              >
                {Object.keys(POST_GRADUATION).map((pg) => {
                  return (
                    <StyledMenuItem key={pg} value={POST_GRADUATION[pg]}>
                      {pg}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Completion Year (PG)"
                select
                fullWidth={true}
                id="postGraduationYear"
                name="postGraduationYear"
                value={formik.values.postGraduationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postGraduationYear
                    ? Boolean(formik.errors.postGraduationYear)
                    : false
                }
                helperText={
                  formik.touched.postGraduationYear &&
                  formik.errors.postGraduationYear
                }
                isRequired={false}
                variant={"standard"}
              >
                {YEARS_LIST?.map((year) => {
                  return (
                    <StyledMenuItem key={year} value={year}>
                      {year}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Graduation"
                select
                fullWidth={true}
                id="graduation"
                name="graduation"
                value={formik.values.graduation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.graduation
                    ? Boolean(formik.errors.graduation)
                    : false
                }
                helperText={
                  formik.touched.graduation && formik.errors.graduation
                }
                isRequired={false}
                variant={"standard"}
              >
                {Object.keys(GRADUATION_ENUM).map((grad) => {
                  return (
                    <StyledMenuItem key={grad} value={GRADUATION_ENUM[grad]}>
                      {grad}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Completion Year (UG)"
                select
                fullWidth={true}
                id="graduationYear"
                name="graduationYear"
                value={formik.values.graduationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.graduationYear
                    ? Boolean(formik.errors.graduationYear)
                    : false
                }
                helperText={
                  formik.touched.graduationYear && formik.errors.graduationYear
                }
                isRequired={false}
                variant={"standard"}
              >
                {YEARS_LIST?.map((year) => {
                  return (
                    <StyledMenuItem key={year} value={year}>
                      {year}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Current Employer"
                id="employer"
                name="employer"
                value={formik.values.employer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.employer
                    ? Boolean(formik.errors.employer)
                    : false
                }
                helperText={formik.touched.employer && formik.errors.employer}
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Previous Employement"
                id="previousEmployment"
                name="previousEmployment"
                value={formik.values.previousEmployment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.previousEmployment
                    ? Boolean(formik.errors.previousEmployment)
                    : false
                }
                helperText={
                  formik.touched.previousEmployment &&
                  formik.errors.previousEmployment
                }
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Working Since"
                id="workingSince"
                name="workingSince"
                value={formik.values.workingSince}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.workingSince
                    ? Boolean(formik.errors.workingSince)
                    : false
                }
                helperText={
                  formik.touched.workingSince && formik.errors.workingSince
                }
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Total Working years"
                id="workExperience"
                name="workExperience"
                value={formik.values.workExperience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.workExperience
                    ? Boolean(formik.errors.workExperience)
                    : false
                }
                helperText={
                  formik.touched.workExperience && formik.errors.workExperience
                }
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Monthly Income (Rs)"
                id="income"
                name="income"
                value={formik.values.income}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.income ? Boolean(formik.errors.income) : false
                }
                helperText={formik.touched.income && formik.errors.income}
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
          </Grid>
          <HorizontalLine />
          <HeadTitle title={"FAMILY & TRADITIONAL DETAILS"} />
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid
              container
              xs={12}
              md={12}
              margin={"auto 0"}
              padding={"16px 0 16px 0"}
            >
              <Grid item xs={12} md={2.5} margin={"auto 0"}>
                <Typography
                  color={"#434343"}
                  marginRight={5}
                  marginLeft={"16px"}
                >
                  Family Values<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid item xs={12} md={9} margin={"auto 0"} marginLeft={"16px"}>
                <RadioGroup
                  row={true}
                  id="familyValues"
                  name="familyValues"
                  value={formik.values.familyValues}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(FAMILY_VALUE_ENUM)?.map((build) => {
                    return (
                      <Grid item xs={6} md={3} margin={"auto 0"}>
                        <FormControlLabel
                          value={FAMILY_VALUE_ENUM[build]}
                          control={<Radio />}
                          label={build}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
              <FormHelperText
                error={
                  formik.touched.familyValues
                    ? Boolean(formik.errors.familyValues)
                    : false
                }
              >
                {!!formik.touched.familyValues && formik.errors.familyValues}
              </FormHelperText>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              margin={"auto 0"}
              padding={"16px 0 16px 0"}
            >
              <Grid item xs={12} md={2.5} margin={"auto 0"}>
                <Typography
                  color={"#434343"}
                  marginRight={6.5}
                  marginLeft={"16px"}
                >
                  {"Family Type"}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} margin={"auto 0"} marginLeft={"16px"}>
                <RadioGroup
                  row={true}
                  id="familyType"
                  name="familyType"
                  value={formik.values.familyType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(FAMILY_TYPE_ENUM)?.map((build) => {
                    return (
                      <Grid item xs={6} md={3} margin={"auto 0"}>
                        <FormControlLabel
                          value={FAMILY_TYPE_ENUM[build]}
                          control={<Radio />}
                          label={build}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
              <FormHelperText
                error={
                  formik.touched.familyType
                    ? Boolean(formik.errors.familyType)
                    : false
                }
              >
                {!!formik.touched.familyType && formik.errors.familyType}
              </FormHelperText>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              margin={"auto 0"}
              padding={"16px 0 16px 0"}
            >
              <Grid item xs={12} md={2.5} margin={"auto 0"}>
                <Typography
                  color={"#434343"}
                  marginRight={5}
                  marginLeft={"16px"}
                >
                  Family Status<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} margin={"auto 0"} marginLeft={"16px"}>
                <RadioGroup
                  row={true}
                  id="familyStatus"
                  name="familyStatus"
                  value={formik.values.familyStatus}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(FAMILY_STATUS_ENUM)?.map((build) => {
                    return (
                      <Grid item xs={6} md={3} margin={"auto 0"}>
                        <FormControlLabel
                          value={FAMILY_STATUS_ENUM[build]}
                          control={<Radio />}
                          label={build}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
              <FormHelperText
                error={
                  formik.touched.familyStatus
                    ? Boolean(formik.errors.familyStatus)
                    : false
                }
              >
                {!!formik.touched.familyStatus && formik.errors.familyStatus}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <Typography variant="body1" color={"#434343"}>
                Any other information of your family
              </Typography>
              <CustomTextField
                fullWidth={true}
                multiline={true}
                label=""
                rows={2}
                id="familyOtherInfo"
                name="familyOtherInfo"
                value={formik.values.familyOtherInfo}
                onChange={formik.handleChange}
                error={
                  formik.touched.familyOtherInfo
                    ? Boolean(formik.errors.familyOtherInfo)
                    : false
                }
                helperText={
                  formik.touched.familyOtherInfo &&
                  formik.errors.familyOtherInfo
                }
                style={{ marginBottom: "16px" }}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <Typography variant="body1" color={"#434343"}>
                Your expectations on family value & traditions
              </Typography>
              <CustomTextField
                fullWidth={true}
                multiline={true}
                label=""
                rows={2}
                id="familyViews"
                name="familyViews"
                value={formik.values.familyViews}
                onChange={formik.handleChange}
                error={
                  formik.touched.familyViews
                    ? Boolean(formik.errors.familyViews)
                    : false
                }
                helperText={
                  formik.touched.familyViews && formik.errors.familyViews
                }
                style={{ marginBottom: "16px" }}
              />
            </Grid>
          </Grid>
          <Box>
            <HorizontalLine />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                size="large"
                type="submit"
                endIcon={
                  <ArrowForwardOutlinedIcon
                    htmlColor="#663399"
                    fontSize="small"
                  />
                }
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  color: "#663399",
                  borderRadius: "0.7rem",
                }}
                disabled={!formik.isValid}
              >
                Next
              </Button>
            </Box>
          </Box>
        </form>
      </Spinner>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetAdvanceDetails: (onSuccess, onError) =>
    dispatch(GetAdvanceDetails(onSuccess, onError)),
  dispatchPostAdvanceDetails: (payload, onSuccess, onError) =>
    dispatch(PostAdvanceDetails(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceForm);
