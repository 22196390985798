import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import moment from "moment";
import {
  GetUserNotifications,
  MarkUserNotificationSeen,
  NotificationCount,
} from "../../Store/Actions/systemActionCreator";
import { showMessage } from "../../Utils/helper";
import { useDispatch } from "react-redux";
import { EmptyImage } from "../../Assets/svg";
import routes from "../../Components/Navigation/routes";
import { Button, Spinner } from "../../Components/UI";

function Notification({ dispatchGetUserNotifications, ...props }) {
  const isMobileView = window.innerWidth < 600;

  const dispatch = useDispatch();

  const [loading, toggleLoading] = useState(false);
  const [notifyResp, setNotificationResp] = useState({
    notifications: [],
  });
  const [filterPayload, setfilterPayload] = useState({
    page: 1,
  });

  const handleNotification = (notification) => {
    try {
      const { data } = notification ?? {};
      if (
        (!notification?.hasOwnProperty("isSeen") ||
          notification?.isSeen === false) &&
        !!notification?.notificationId
      ) {
        markNotificationSeen(notification?.notificationId);
      }
      if (!data?.action) {
        return false;
      }
      const foundedRouteData = routes.find((route) =>
        data?.action === "profile"
          ? route?.path.includes("my-matches")
          : !!route?.path && !!route?.path.includes(data?.action)
      );
      if (!!foundedRouteData) {
        props.history.push(
          data?.action === "profile"
            ? "/my-matches/" + data?.id
            : foundedRouteData.path,
          foundedRouteData.name.includes("Profile")
            ? {
                data: { id: data?.id },
              }
            : {}
        );
      }
    } catch (error) {}
  };

  const markNotificationSeen = (notificationid, needToResetFilters = false) => {
    dispatch(
      MarkUserNotificationSeen(
        { notificationid },
        (resp) => {
          if (resp?.status) {
            dispatch(NotificationCount());
            if (needToResetFilters) {
              setfilterPayload({
                ...filterPayload,
                page: 1,
              });
            }
          }
        },
        (err) => {}
      )
    );
  };

  const fetchNotifications = (payload = {}) => {
    toggleLoading(true);
    dispatch(
      GetUserNotifications(
        payload,
        (resp) => {
          if (resp?.status) {
            setNotificationResp({
              ...resp?.result,
              notifications: [...resp?.result?.notifications],
            });
          } else {
            showMessage(resp?.result, "error");
          }
          toggleLoading(false);
        },
        (err) => {
          setNotificationResp({ notifications: [] });
          toggleLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchNotifications(filterPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPayload]);

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };

  return (
    <div id="MyNotification" className="mynotification" onScroll={onScroll}>
      <Spinner loading={loading}>
        <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
          <div className="account-top">
          <div>
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight="bold"  
              paddingBottom={"0.1em"}           
              textAlign={isMobileView ? "center" : "left"}
            >
              My Notifications
            </Typography>
            <Typography
              variant="h7"
              color={"#000000"}              
              textAlign={isMobileView ? "center" : "left"}
            >
              Notification(s) from last 30 days
              </Typography>
            </div>
            <Button
              variant="contained"
              sx={{ textTransform: "none", height: 32,marginTop: isMobileView ? "1em" : "initial"}}
              onClick={() => {
                markNotificationSeen("all", true);
              }}
            >
              Mark All as Seen
            </Button>
          </div>
          <Divider
            sx={{
              borderColor: "rgba(102, 51, 153, 0.3)",
              my: "16px",
              mb: "20px",
            }}
          />
          {!!notifyResp?.notifications?.length ? (
            notifyResp?.notifications.map((data) => {
              return (
                <ListItem
                  sx={{
                    width: "100%",
                    bgcolor: !data?.isSeen ? "#66339950" : "background.paper",
                    mb: 2,
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNotification(data)}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: "white" }}>
                      {data?.data?.action === "profile" && (
                        <FavoriteIcon color="secondary" />
                      )}
                      {data?.data?.action === "my-inbox" && (
                        <EmailIcon color="secondary" />
                      )}
                      {data?.data?.action === "my-account" && (
                        <PersonIcon color="secondary" />
                      )}
                      {!["my-account", "my-inbox", "profile"].includes(
                        data?.data?.action
                      ) && <NotificationsActiveIcon color="secondary" />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={data?.title}
                    secondary={moment(data?.createdTimestamp).fromNow()}
                  />
                </ListItem>
              );
            })
          ) : (
            <Box
              className="inboxlist"
              padding={0}
              paddingBottom="1rem"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <EmptyImage width={400} height={400} />
            </Box>
          )}
          <Stack
            spacing={2}
            width="22em"
            margin={"2em auto 1em auto"}
            style={{ textAlign: "center" }}
          >
            <Pagination
              count={Math.ceil(
                Number(notifyResp?.totalCount) / Number(notifyResp?.size) >= 1
                  ? Number(notifyResp?.totalCount) / Number(notifyResp?.size)
                  : 1
              )}
              page={filterPayload.page}
              shape="rounded"
              onChange={(e, page) => {
                setfilterPayload({
                  ...filterPayload,
                  page,
                });
              }}
            />
          </Stack>
        </Box>
      </Spinner>
    </div>
  );
}

export default Notification;
