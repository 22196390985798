import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function TermsAndConditions(props) {
  const isMobileView = window.innerWidth < 600;
  return (
    <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Tooltip title="GO BACK">
          <IconButton
            aria-label="delete"
            sx={{ mr: "16px" }}
            onClick={(e) => {
              props?.history?.goBack();
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>

        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color={"#000000"}
          fontWeight="bold"
          textAlign={"left"}
        >
          Terms &amp; Conditions: Updated on 22-March-2023
        </Typography>
      </Box>
      <Divider
        sx={{
          borderColor: "rgba(102, 51, 153, 0.3)",
          my: "16px",
          mb: "30px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: isMobileView ? "100%" : "80%",
          flexDirection: isMobileView ? "column" : "row",
          color: "#000",
        }}
      >
        <div>
          <ol
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <li>
              For the purpose of this document, 'VVS' refers to
              www.srijspnvvs.umapps.in and/or UM VVS Madhwa Matrimony app.
            </li>
            <br />
            <li>
              To register your profile at VVS and become a 'member', you must be
              of legal marriageable age as per the Indian laws (currently, 18
              years or over for females(bride) and 21 years or over for
              males(groom)).
            </li>
            <br />
            <li>
              Participants must register their profile at VVS by providing the
              required information. VVS does not entertain and accept any other
              mode of registration (e-mail, other matrimonial websites and so
              on)
            </li>
            <br />
            <li>
              Non-Commercial Use by Members.
              <br />
              VVS is for the personal use of individual members to advertise and
              promote their profiles for the purpose of generating Accepts from
              relevant matches and cannot be used in connection with any
              commercial endeavours. This includes providing links to other web
              sites, whether deemed competitive to VVS or otherwise.
              Organizations, companies, and/or businesses cannot become Members
              of VVS and should not use the VVS Service or Site for any purpose.
              Illegal and/or unauthorized uses of the VVS, including
              unauthorized framing of or linking to the Site or App will be
              investigated, and appropriate legal action will be taken,
              including without limitation, civil, criminal, and injunctive
              redress.
            </li>
            <br />
            <li>
              You hereby confirm that as on date of this registration, you do
              not have any objection to receiving emails, messages,
              notifications and calls from VVS and members of VVS as long as you
              are a registered member of VVS. This consent shall supersede any
              preferences set by you with or registration done with the Do Not
              Disturb (DND Register)/ National Customer Preference Register
              (NCPR). This consent extends to emails, messages, notifications or
              calls relating but not limited to phone number verification, the
              provision of match making advertising service, matchmaking
              enquiries and promotions.
            </li>
            <br />
            <li>
              Multiple profiles of the same person are not allowed on VVS. VVS
              reserves the right to deactivate all multiple profiles or any
              single profile.
            </li>
            <br />
            <li>
              In order to protect VVS and/or our Members from any abuse /
              misuse, VVS reserves the right to take appropriate steps as it
              deemed appropriate in its sole discretion in order to protect
              interest of other Members.
            </li>
            <br />
            <li>
              Proprietary Rights in the Content on srijspnvvs.umapps.in
              <br />
              Proprietary Rights in the Content on VVS. VVS owns and retains all
              proprietary rights in the VVS platforms. The Site contains the
              copyrighted material, trademarks, and other proprietary
              information of VVS, and its licensors. Except for that information
              which is in the public domain such as member profile or for which
              permission has been obtained from the user, you cannot copy,
              modify, publish, transmit, distribute, perform, display, or sell
              any such proprietary information. Any such act or an attempted act
              on your part shall constitute a violation of this Agreement and
              your membership is liable to be terminated forthwith by VVS
              without prior intimation or information. We also reserve our right
              to take legal action (civil and/or criminal) wherever applicable
              for any violations
            </li>
            <br />
            <li>
              Content Posted on the Site.
              <br />
              <ul>
                <ul>
                  <li>
                    You understand and agree that VVS may delete any listing,
                    content, communication/s, photos or profiles (collectively,
                    "Content") that in the sole judgment of VVS violate this
                    Agreement or which might be offensive, illegal, or that
                    might violate the rights, harm, or threaten the safety of
                    either VVS and/or its members.
                  </li>
                  <li>
                    With respect to Content you submit or make available for
                    inclusion on publicly accessible areas of the Site including
                    but not limited to your contact details, you hereby
                    unconditionally and irrevocably grant to VVS the license to
                    use, distribute, reproduce, modify, adapt, publicly perform
                    and publicly display such Content on the Site and to
                    Centres, events, members from time to time.
                  </li>
                </ul>
              </ul>
            </li>
            <br />
            <li>
              Member Disputes.
              <br />
              You are solely responsible for your advertisement / communications
              with other VVS Members. VVS reserves the right, but has no
              obligation, to monitor disputes between you and other Members. VVS
              also reserves the right to take appropriate action against errant
              members. However, VVS is not obliged to share such action with
              other members, including complainants. VVS expressly disclaims any
              responsibility or liability for any transactions or interactions
              between the members inter-se.
            </li>
            <br />
            <li>
              VVS only enables the 'first' instance of marriage for its members.
              Candidates seeking re-marriage are discouraged from using the
              services of this platform. VVS reserves the right to reject the
              registration of any such members and prohibit them from
              participating in any event conducted under the banner of VVS and
              will not refund the donation made towards the same.
            </li>
            <br />
            <li>
              VVS has been established with the objective of betterment of the
              Madhwa society and reserves the right to not process the
              registration of candidates, at its discretion, if deemed
              detrimental to this stated objective.
            </li>
            <br />
            <li>
              VVS will maintain confidentiality of all personal information
              (other than that meant for posting or transmission) furnished by
              the members. By agreeing to register at VVS, you allow the VVS
              platform to use your email address and/or telephone number/ Mobile
              number for reaching out to you as well as providing your profile
              information to other members only for the purpose of matrimonial
              alliances. VVS will not use the profile information of the members
              for any other activity.
            </li>
            <br />
            <li>
              VVS is not responsible for the veracity &amp; factual correctness
              of the content and information (including profile and photograph)
              provided by members on the website. We advise members to further
              independently verify the details of prospective matches.
            </li>
            <br />
            <li>
              VVS reserves the right to cancel, without prior notice and at its
              discretion, the registration of members who are found to have
              wilfully provided inaccurate and/or misleading information during
              the registration process.
            </li>
            <br />
            <li>
              If you are using VVS, you are responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your computer/ mobile phones or devices, and you agree
              to accept responsibility for all activities that occur under your
              account or password
            </li>
            <br />
            <li>
              The donations collected from VVS PRIME are used exclusively for
              social service activities of JSPN. Through the VVS PRIME
              membership, you can contribute to the society while finding your
              perfect match. This can be enabled Only for registered users. VVS
              PRIME membership cannot be cancelled or the donation refunded
              during the membership period.
            </li>
            <br />
            <li>
              VVS has no franchises or branches. All communication related to
              VVS must be addressed to "Jaya Satya Pramoda Nidhi, C/o. Uttaradi
              Math, Jayateertha Vidyapeetha, Uttaradi Math road, Basavanagudi,
              Bangalore - 560 004". Alternately you can also send an e-mail to
              jspn.vvs@umapps.in. No other mode of correspondence will be
              entertained
            </li>
            <br />
          </ol>
          <Divider
            sx={{
              borderColor: "rgba(102, 51, 153, 0.3)",
            }}
          />
          <ul
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight="bold"
              textAlign={isMobileView ? "center" : "left"}
            >
              SMS Terms And Conditions
            </Typography>
            <Divider
              sx={{
                borderColor: "rgba(102, 51, 153, 0.3)",
                my: "16px",
                mb: "30px",
              }}
            />
            <li>
              By entering mobile number on VVS while registering for an account
              or receiving alerts, contacting an advertiser seller / buyer, user
              gives consent to JSPN and its partners / vendors and sub-partners
              / sub-vendors/subscribers to send alerts, contact details, SMS,
              notifications, emails and calls related to user s action in our
              website to the mobile number entered by the user, irrespective of
              whether the same is on the DND list or any other such data base.
              In case user does not want to receive these messages on his / her
              mobile number, he / she shall not submit his / her mobile number
              with VVS.
            </li>
            <br />
            <li>
              The user/customer by signing in to VVS hereby unconditionally
              consents to receive calls/SMS's /notifications from JSPN or its
              users in accordance with the 'Do not disturb' guidelines of
              Telecom Regulatory Authority of India (TRAI) or such other
              authority in India and abroad. JSPN further reserves the right to
              send emails and SMS's /notifications related to any communication
              by the user/customer in JSPN website and the user/customer agrees
              that the same will not tantamount to spam.
            </li>
            <br />
            <li>
              Short Messaging Service (SMS SERVICE) is offered by JSPN as a add
              on service to users.
            </li>
            <br />
            <li>
              SMS will be sent only to the mobile number you have
              registered/given during the registration.
            </li>
            <br />
            <li>
              SMS will be sent from the time ticket request or acceptance or
              rejection are recorded from the application.
            </li>
            <br />
            <li>
              You may not receive SMS in case there are issues/problems with
              your mobile phone, mobile network reach and technical issues with
              your mobile network provider. In order to receive SMS, you must
              ensure that your mobile phone is switched on, is in working
              condition and connected to the mobile network of your mobile
              network provider at all times. If your mobile phone is switched
              off or is out of coverage, your mobile network provider may delete
              any SMS messages to be received by you.
            </li>
            <br />
            <li>
              The time to deliver the SMS is dependent upon the traffic on the
              mobile network and whether your mobile phone is within reach and
              switched on and cannot therefore be guaranteed by JSPN.
            </li>
            <br />
            <li>
              JSPN is not a mobile network operator and does not in any way
              guarantee the delivery of SMS text messages.
            </li>
            <br />
            <li>
              By registering/booking to the reservation, you confirm that you
              have provided the accurate mobile number to be reached via SMS and
              that you are the owner or its legitimate user of the mobile phone
              being used at the time of registration. You acknowledge that using
              another person's mobile phone or providing inaccurate mobile phone
              number or unauthorized use of mobile phone number for receiving
              the SMS may entail disclosure of your confidential information
              which disclosure shall be at your sole risk.
            </li>
            <br />
            <li>
              SMS SERVICE is provided with no warranties, express or implied.
              JSPN is not responsible or liable or otherwise have any obligation
              to you for any loss, damage or expenses incurred directly or
              indirectly due to non receipt of the SMS for any reasons
              whatsoever. In no event shall JSPN be liable for any indirect or
              consequential damages.
            </li>
            <br />
            <li>
              These Terms and Conditions are governed by the law of India SMS
              SERVICE submits to the exclusive jurisdiction of the courts in
              Bengaluru.
            </li>
            <br />
            <li>
              By accepting the Terms and Conditions, you specifically
              acknowledge and agree to the aforesaid terms and conditions.
            </li>
            <br />
            <li>
              Delivery of SMS (alerts, service alerts etc.) depends on mobile
              service provider of the user and JSPN is not responsible for its
              delivery.
            </li>
            <br />
          </ul>
          <hr />
          <ol
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight="bold"
              textAlign={isMobileView ? "center" : "left"}
            >
              Disclaimer:
            </Typography>
            <Divider
              sx={{
                borderColor: "rgba(102, 51, 153, 0.3)",
                my: "16px",
                mb: "30px",
              }}
            />
            <li>
              VVS is an online &amp; app based Matrimonial Service. The terms
              and conditions set forth hereinafter are applicable to all
              visitors / Members of the platform.
            </li>
            <br />
            <li>
              VVS is designed, updated and maintained by JSPN and the name,
              design and the model are exclusive property of JSPN and any copy
              and reproduction in any form and/or unauthorized access to any
              data forming part of VVS will amount to infringement of copyright
              laws and international treaty provisions.
            </li>
            <br />
            <li>
              All information/content of an individual User and/or Member of the
              VVS, is considered privileged and confidential and shall not be
              shared with any other members/User, except as per the Terms of
              Use.
            </li>
            <br />
            <li>
              While utmost care is taken to provide correct and updated
              information, the platform does not warrant the accuracy,
              completeness and timely availability of the information provided
              on the website and accepts no responsibility or liability for any
              error or omission in any information on VVS.
            </li>
            <br />
            <li>
              The user of VVS is responsible for removing his/her profile from
              the website or intimating VVS in writing to remove the profile
              from the website once their match is finalized. VVS will not be
              responsible in any way for the lapse on the user's part to take
              this action and no liability for this in action on the part of the
              user rests with VVS
            </li>
            <br />
            <li>
              VVS will in no event be liable for any direct, incidental,
              consequential or indirect damages including damages arising out of
              the use of or inability to use and/or for any delay in the Service
              provided by VVS.
            </li>
            <br />
            <li>
              VVS reserves the right to modify, add, delete and/or change the
              contents and classification and presentation of the information at
              any time as it may in its absolute discretion find to be expedient
              and without giving any notice. It is the user's responsibility to
              refer to the terms and/or any change or addition to the same while
              accessing The Site or App.
            </li>
            <br />
            <li>
              VVS offers no guarantee that the contents of The Site are free
              from viruses or any other infection, which has contaminating or
              destructive properties.
            </li>
            <br />
            <li>
              VVS provides links to other web sites at various places hosted on
              servers maintained by third parties over which VVS has no control.
              VVS accepts no responsibility as to the contents of such websites
              and shall in no way be liable for any damage/loss arising to users
              seeking access or using the said web sites.
            </li>
            <br />
            <li>
              Provision is made to enlist members to The Site and all such
              members are bound by the general terms &amp; conditions mentioned
              herein before and by the Terms of Use.
            </li>
            <br />
            <li>
              VVS accepts advertising material sent by third parties for display
              on the website. Advertisers are responsible for ensuring that the
              material submitted by them complies with national and
              international law. The Site or App at its absolute discretion
              reserves the right to change, suspend or omit any advertisement
            </li>
            <br />
          </ol>
        </div>
      </Box>
    </Box>
  );
}
