import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button } from "../../Components/UI";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PlaystoreBadge, AppstoreBadge } from "../../Components/UI";

const HomeLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.white,
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    height: "100vh",
    minHeight: 500,
    maxHeight: 1300,
  },
}));

const Background = styled(Box)({
  // position: "absolute",
  // left: 0,
  // right: 0,
  // top: 0,
  // height: "100vh",
  // bottom: 0,
  // backgroundSize: "cover",
  // backgroundRepeat: "no-repeat",
  // zIndex: -2,
  position: "fixed",
  zIndex: "-2",
  width: "100vw",
  height: "100vh",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center"
});

function HomeLayout(props) {
  const { sxBackground, children, user } = props;

  return (
    <HomeLayoutRoot >

      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          marginBottom: 0

        }}
      >
        <div class="hideMobile">
          <CardContent className="welcome-msg">
          {/* <Typography
              fontWeight={900}
              variant="h7"
              color={"orange"}
              align="left">
              We are facing outage with our SMS provider and we are working on the fix. Registration OTP/ Login OTP and other SMS will not be delivered until then. 
              Please reach out on WhatsApp to connect with our volunteers on +91 6363921008 for any queries. 
            </Typography> */}
            <Typography
              fontWeight={900}
              fontStyle="italic"
              variant="h2"
              color={"#FFF"}
              paddingTop="18vh"
              align="center">
              Vadhu Vara Samyojana by JSPN
            </Typography>
            <Typography
              fontWeight={900}
              variant="h5"
              color={"#FFF"}
              align="center">
              The Social Wing of Sri Uttaradi Math
            </Typography>
          </CardContent>
        </div>
        {!(
          !!Object.keys(user?.imageUrl ?? {}).length && !!user?.accessToken
        ) && (
            <>
              <Card className="register_free_gradient">
                <CardContent>
                  <Typography
                    fontWeight={900}
                    variant="h4"
                    color={"#FF27B7"}
                    align="center"
                  >
                    Welcome to VVS
                  </Typography>
                  <Typography sx={{ margin: "2em 0" }} align="justify">
                    Register yourself and Search through a list of verified Madhwa Community Profiles to find your soul
                    partner.{" "}
                  </Typography>
                  <Button
                    style={{ width: "100%", fontWeight: 900 }}
                    onClick={(e) => {
                      props.history.push("/register");
                    }}
                    variant="contained"
                    size="large"
                  >
                    REGISTER FOR FREE
                  </Button>
                </CardContent>
              </Card>
            </>
          )}

        <Background sx={sxBackground} />
        <div class="onlyMobile">
        {/* <Typography
              fontWeight={900}
              variant="h7"
              color={"orange"}
              align="left">
We are facing outage with our SMS provider and we are working on the fix. Registration OTP/ Login OTP and other SMS will not be delivered until then. 
              Please reach out on WhatsApp to connect with our volunteers on +91 6363921008 for any queries.
            </Typography> */}
          <CardContent className="welcome-msg" style={{ marginTop: "30vh" }}  >
            <Typography
              fontWeight={900}
              fontStyle="italic"
              variant="h4"
              color={"#FFF"}
              align="center">
              Vadhu Vara Samyojana by JSPN
            </Typography>
            <Typography
              fontWeight={900}
              variant="h6"
              color={"#FFF"}
              align="center">
              The Social Wing of Sri Uttaradi Math
            </Typography>
          </CardContent>
        </div>

        {!(
          !!Object.keys(user?.imageUrl ?? {}).length && !!user?.accessToken
        ) && (
            <Button
              style={{ width: "100%", fontWeight: 900, bottom: "10vh", top: "2vh" }}
              onClick={(e) => {
                props.history.push("/register");
              }}
              variant="contained"
              size="large"
              className="onlyMobile"
            >
              REGISTER FOR FREE
            </Button>
          )}
      </Container>
      {children}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", position: "fixed", bottom: "0px", background: "#a35050", width: "100%", padding: "8px" }}>
        <PlaystoreBadge />
        <AppstoreBadge />
      </div>
    </HomeLayoutRoot>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  sxBackground: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeLayout));
