import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function PrivacyPolicy(props) {
  const isMobileView = window.innerWidth < 600;
  return (
    <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Tooltip title="GO BACK">
          <IconButton aria-label="delete" sx={{ mr: "16px" }} onClick={e => {
            props?.history?.goBack()
          }}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>

        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color={"#000000"}
          fontWeight="bold"
          textAlign={"left"}
        >
          Privacy Policy: Updated on 25-Oct-2022
        </Typography>
      </Box>
      <Divider
        sx={{
          borderColor: "rgba(102, 51, 153, 0.3)",
          my: "16px",
          mb: "30px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: isMobileView ? "100%" : "80%",
          flexDirection: isMobileView ? "column" : "row",
          color: "#000",
        }}
      >
        <div>
          <p> This privacy notice discloses the privacy practices for srijspnvvs.umapps.in
            This privacy notice applies solely to information collected by this website. It will notify you of the following:</p>
          <ol
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <li>
              What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.
            </li>
            <br />
            <li>
              What choices are available to you regarding the use of your data.
            </li>
            <br />
            <li>
              The security procedures in place to protect the misuse of your information.
              <br />
            </li>
            <br />
            <li>
              How you can correct any inaccuracies in the information.
            </li>
            <br />

            <br />
          </ol>
          <Divider
            sx={{
              borderColor: "rgba(102, 51, 153, 0.3)",
            }}
          />
          <ul
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight="bold"
              textAlign={isMobileView ? "center" : "left"}
            >
              Information Collection, Use, and Sharing
            </Typography>
            <Divider
              sx={{
                borderColor: "rgba(102, 51, 153, 0.3)",
                my: "16px",
                mb: "30px",
              }}
            />
            <li>
              We are the sole owners of the information collected on this site. We only have access to collect
              information that you voluntarily give us via email or other direct contact from you.
              We will not sell or rent this information to anyone.
            </li>
            <br />
            <li>
              We will use your information to respond to you, regarding the reason you contacted us.
              We will not share your information with any third party outside of our organization,
              other than as necessary to fulfil your request.
            </li>
            <br />
            <li>
              Unless you ask us not to, we may contact you via email & SMS in the future to tell you about events,
              new services, or changes to this privacy policy.
            </li>
            <br />

            <br />
          </ul>
          <hr />
          <ol
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight="bold"
              textAlign={isMobileView ? "center" : "left"}
            >
              Your Access to and Control Over Information:
            </Typography>
            <Divider
              sx={{
                borderColor: "rgba(102, 51, 153, 0.3)",
                my: "16px",
                mb: "30px",
              }}
            />
            <ol
              style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
            >
              <li>
                See what data we have about you, if any.
              </li>
              <br />
              <li>
                Change/correct any data we have about you.
              </li>
              <br />
              <li>
                Have us delete any data we have about you.
                <br />
              </li>
              <br />
              <li>
                Express any concern you have about our use of your data.
              </li>
              <br />

              <br />
            </ol>
            <br />
          </ol>
          <Divider
            sx={{
              borderColor: "rgba(102, 51, 153, 0.3)",
            }}
          />
          <ul
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight="bold"
              textAlign={isMobileView ? "center" : "left"}
            >
              Security
            </Typography>
            <Divider
              sx={{
                borderColor: "rgba(102, 51, 153, 0.3)",
                my: "16px",
                mb: "30px",
              }}
            />
            <li>
              We take precautions to protect your information.
              When you submit sensitive information via the website, your information is protected both online and offline.
            </li>
            <br />
            <li>
              While we use encryption to protect sensitive information transmitted online,
              we also protect your information offline. Only Volunteers who need the information to perform a specific job
              are granted access to personally identifiable information.
              The computer servers in which we store personally identifiable information are kept in a highly secure environment.
            </li>
            <br />
            <li>
              If you feel that we are not abiding by this privacy policy, you should contact our business head immediately via
              email at  jspn.vvs@umapps.in.
            </li>
            <br />

            <br />
          </ul>
          <hr />
        </div>
      </Box>
    </Box>
  );
}
