import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  CustomTextField,
  FileUploader,
  Spinner,
  StyledMenuItem,
} from "../../Components/UI";
import * as yup from "yup";
import {
  Box,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  BUILD_ENUM,
  CHARANA_ENUM,
  FAMILY_STATUS_ENUM,
  FAMILY_TYPE_ENUM,
  FAMILY_VALUE_ENUM,
  GOTRA_ENUM,
  GRADUATION_ENUM,
  HEIGHT_ENUM,
  HIGHEST_EDUCATION_ENUM,
  HOUSE_ENUM,
  LOCATION_REGION,
  MATHA_ENUM,
  MOTHER_TONGUE_ENUM,
  NAKSHATHRA_ENUM,
  POST_GRADUATION,
  RASHI_ENUM,
  YEARS_LIST,
} from "../../Utils/constants";
import { HeadTitle, HorizontalLine } from "../Registration";
import "../Registration/Registration.scss";
import { connect } from "react-redux";
import {
  DeleteUserImage,
  FetchProfileDetails,
  GetImageUploadUrl,
  GetImageUrl,
  UpdateProfileDetails,
} from "../../Store/Actions/userActionCreator";
import { showMessage } from "../../Utils/helper";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import "./UserProfile.scss";
var categories = {
  Madhwa: "Madhwa",
  Smartha: "Smartha",
};
const validationSchema = yup.object({
  matha: yup
    .string("Invalid matha")
    .trim()
    .nullable(true)
    .required("matha cannot be empty"),
  motherTongue: yup
    .string("Invalid mother tongue")
    .trim()
    .nullable(true)
    .required("mother tongue cannot be empty"),
  fatherName: yup
    .string("Invalid father name")
    .trim()
    .nullable(true)
    .required("father name cannot be empty")
    .min(3)
    .max(50),
  fatherOccupation: yup
    .string("Invalid father occupation")
    .trim()
    .nullable(true)
    .max(50)
    .required("father occupation cannot be empty"),
  motherName: yup
    .string("Invalid mother name")
    .trim()
    .nullable(true)
    .required("mother name cannot be empty")
    .min(3)
    .max(50),
  motherOccupation: yup
    .string("Invalid mother occupation")
    .trim()
    .nullable(true)
    .max(50),
  brotherCount: yup
    .number("Invalid brother count")
    .typeError("Invalid brother count")
    .min(0)
    .default(0)
    .transform((value) => (isNaN(value) ? 0 : value))
    .required("brother count cannot be empty"),
  sisterCount: yup
    .number("Invalid sister count")
    .typeError("Invalid sister count")
    .default(0)
    .transform((value) => (isNaN(value) ? 0 : value))
    .min(0)
    .required("sister count cannot be empty"),
  alternateContact: yup
    .string("Invalid alternate contact no.")
    .matches(/^[0-9+]+$/, 'Invalid mobile number')
    .trim()
    .nullable(true),
  house: yup.string("Invalid house").nullable(true),
  address: yup
    .string("Invalid address")
    .trim()
    .nullable(true)
    .required("address cannot be empty"),
  contactPreference: yup
    .string("Type in the Preferred Contact Time")
    .max(150, "Preferred Contact Time text should be less than 150 chars")
    .trim()
    .nullable(true),
  gotra: yup
    .string("Invalid gotra")
    .trim()
    .nullable()
    .required("gotra cannot be empty"),
  rashi: yup
    .string("Invalid rashi")
    .trim()
    .nullable()
    .required("rashi cannot be empty"),
  nakshatra: yup
    .string("Invalid nakshatra")
    .trim()
    .nullable()
    .required("nakshatra cannot be empty"),
  height: yup
    .string("Invalid height")
    .trim()
    .nullable()
    .required("height cannot be empty"),
  location: yup
    .string("Invalid location")
    .trim()
    .nullable()
    .required("location cannot be empty"),
  region: yup
    .string("Invalid region")
    .trim()
    .nullable()
    .required("region cannot be empty"),
  charana: yup.string("Invalid charana").trim().nullable(),
  build: yup.string("Invalid build").trim().nullable(),
  about: yup.string("Invalid about").trim().nullable(),
  partnerPreferences: yup
    .string("Invalid partner preferences")
    .trim()
    .nullable(),
  higherQualification: yup
    .string("Invalid highest qualification")
    .trim()
    .nullable()
    .required("highest qualification cannot be empty"),
  postGraduation: yup.string("Invalid post graduation").trim().nullable(),
  // .required("post graduation cannot be empty"),
  postGraduationYear: yup
    .string("Invalid post graduation year")
    .trim()
    .nullable(),
  // .required("post graduation year cannot be empty"),
  graduation: yup.string("Invalid graduation").trim().nullable(),
  // .required("graduation cannot be empty"),
  graduationYear: yup.string("Invalid graduation year").trim().nullable(),
  // .required("graduation year cannot be empty"),

  employer: yup.string("Invalid employer").trim().nullable(),
  previousEmployment: yup
    .string("Invalid previous employment")
    .trim()
    .nullable(),
  workingSince: yup.string("Invalid working since").trim().nullable(),
  workExperience: yup.string("Invalid work experience").trim().nullable(),
  income: yup.string("Invalid work income").trim().nullable(),
  familyValues: yup
    .string("Invalid family values")
    .trim()
    .nullable()
    .required("family values cannot be empty"),
  familyType: yup
    .string("Invalid family type")
    .trim()
    .nullable()
    .required("family type cannot be empty"),
  familyStatus: yup
    .string("Invalid family status")
    .trim()
    .nullable()
    .required("family status cannot be empty"),
  familyOtherInfo: yup.string("Invalid family other info").trim().nullable(),
  familyViews: yup.string("Invalid family views").trim().nullable(),
});

function ProfileEdit({
  dispatchFetchProfileDetails,
  dispatchUpdateProfileDetails,
  dispatchGetImageUrl,
  dispatchGetImageUploadUrl,
  dispatchDeleteUserImage,
  ...props
}) {
  const [loading, toggleLoading] = useState(false);
  const [loadImages, toggleImageLoader] = useState(false);
  const [uploadUrlLinks, setUploadUrlLinks] = useState({});
  const [uploadedLinks, setUploadedLinks] = useState({});
  const [profileImageLinks, setProfileImagellist] = useState(null);
  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      matha: "",
      motherTongue: "",
      fatherName: "",
      fatherOccupation: "",
      motherName: "",
      motherOccupation: "",
      brotherCount: "",
      sisterCount: "",
      alternateContact: "",
      address: "",
      contactPreference: "",
      house: "",
      gotra: "",
      rashi: "",
      nakshatra: "",
      height: "",
      location: "",
      region: "",
      charana: "",
      build: "",
      about: "",
      partnerPreferences: "",
      higherQualification: "",
      postGraduation: "",
      postGraduationYear: "",
      graduation: "",
      graduationYear: "",
      employer: "",
      previousEmployment: "",
      workingSince: "",
      workExperience: "",
      income: "",
      familyValues: "",
      familyType: "",
      familyStatus: "",
      familyOtherInfo: "",
      familyViews: "",
      category: "",
      imageUrl: {},
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      toggleLoading(true);
      dispatchUpdateProfileDetails(
        {
          ...values,
        },
        (resp) => {
          if (!!resp?.status) {
            window.scrollTo({
              x: 0,
              y: 0,
            });
            props.history.push("/my-profile");
            toggleLoading(false);
          } else {
            toggleLoading(false);
            showMessage(resp?.result, "error");
          }
        },
        (err) => {
          toggleLoading(false);
          showMessage(err, "error");
        }
      );
    },
  });

  const fetchUploadedProfileImages = () => {
    toggleImageLoader(true);
    dispatchGetImageUrl(
      (resp) => {
        if (!!resp?.status) {
          setUploadedLinks(resp?.result ?? {});
        } else {
          showMessage(resp?.result || "", "error");
        }
        toggleImageLoader(false);
      },
      (err) => {
        toggleImageLoader(false);
        showMessage(err, "error");
      }
    );
  };

  const fetchProfileDetails = () => {
    toggleLoading(true);
    dispatchFetchProfileDetails(
      {},
      (resp) => {
        if (resp?.status) {
          const gender = resp.result.gender;
          if (gender === "MALE") {
            categories = {
              Madhwa: "Madhwa",
            };
          }
          // formik.setFieldValue('category', 'Madhwa');
          formik.setValues(
            {
              ...resp?.result,
            },
            true
          );
        }
        toggleLoading(false);
      },
      (err) => {
        toggleLoading(false);
      }
    );
  };
  let links = uploadedLinks;

  useEffect(() => {
    toggleLoading(true);
    fetchProfileDetails();
    dispatchGetImageUploadUrl(
      (resp) => {
        if (!!resp?.status) {
          setUploadUrlLinks(resp?.result ?? []);
          fetchUploadedProfileImages();
        } else {
          showMessage(resp?.result || "", "error");
        }
        toggleLoading(false);
      },
      (err) => {
        showMessage(err, "error");
        toggleLoading(false);
      }
    );
  }, []);

  return (
    <Spinner loading={loading}>
      <a
        href="https://wa.me/message/BN2R4ZFIMY4JM1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <Container
        className="base-container hideMobile"
        maxWidth="unset"
        style={{ backgroundColor: "#ffb95545" }}
      >
        <Box className="multiSelectToggle" style={{ paddingTop: "2em" }}>
          <Grid container={true} gap={2}>
            <Typography
              variant="h4"
              fontFamily="Roboto"
              fontWeight={600}
              className="matchCount"
            >
              Edit Profile
            </Typography>
          </Grid>
        </Box>
      </Container>
      <Container className="base-container hideMobile" maxWidth="unset">
        <form onSubmit={formik.handleSubmit}>
          <Box
            className="registration-baisc-form"
            sx={{ marginBottom: "16px" }}
          >
            <HeadTitle
              title={"UPDATE IMAGE"}
              subTitle="Note: Profile photo (1st Photo) is mandatory. Upload any 2 other photos to get better matches"
              subTitleColor="red"
            />
            <Spinner loading={loadImages}>
              <Grid
                container
                justifyContent={"space-between"}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {[...Object.keys(uploadUrlLinks)]?.map((key, idx) => (
                  <Grid item xs={12} md={4} margin={"auto 0"} key={idx}>
                    <FileUploader
                      // label={'Upload Support Document'}
                      placeholder={`Upload ${key?.split("/")?.[1] ?? ""}`}
                      defaultValue={uploadedLinks[key]}
                      uploadUrl={uploadUrlLinks[key]}
                      onFileUploaded={(url) => {
                        fetchUploadedProfileImages();
                      }}
                      onRemoved={() => {
                        if (idx !== 0) {
                          toggleImageLoader(true);
                          dispatchDeleteUserImage(
                            key.split("/")[1],
                            (resp) => {
                              if (!!resp?.status) {
                                const newList = { ...uploadedLinks };
                                // setProfileImagellist(newList[key]);
                                delete newList[key];
                                setUploadedLinks(newList);
                              }
                              toggleImageLoader(false);
                            },
                            (err) => {
                              toggleImageLoader(true);
                            }
                          );
                        } else {
                          const newList = { ...uploadedLinks };
                          delete newList[key];
                          setUploadedLinks(newList);
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Spinner>
          </Box>
          <Box className="registration-baisc-form">
            <HeadTitle title={"BASIC DETAILS"} />
            <Grid
              container
              justifyContent={"space-between"}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            >
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="Matha"
                  select
                  fullWidth={true}
                  id="matha"
                  name="matha"
                  value={formik.values.matha ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.matha ? Boolean(formik.errors.matha) : false
                  }
                  helperText={formik.touched.matha && formik.errors.matha}
                  isRequired={true}
                  variant={"standard"}
                >
                  {Object.keys(MATHA_ENUM).map((matha) => {
                    return (
                      <StyledMenuItem key={matha} value={matha}>
                        {matha}
                      </StyledMenuItem>
                    );
                  })}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="Mother tongue"
                  select
                  fullWidth={true}
                  id="motherTongue"
                  name="motherTongue"
                  value={formik.values.motherTongue ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.motherTongue
                      ? Boolean(formik.errors.motherTongue)
                      : false
                  }
                  helperText={
                    formik.touched.motherTongue && formik.errors.motherTongue
                  }
                  isRequired={true}
                  variant={"standard"}
                >
                  {Object.keys(MOTHER_TONGUE_ENUM).map((lang) => {
                    return (
                      <StyledMenuItem key={lang} value={lang}>
                        {lang}
                      </StyledMenuItem>
                    );
                  })}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="Father's / Guardian Name"
                  id="fatherName"
                  name="fatherName"
                  value={formik.values.fatherName ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.fatherName
                      ? Boolean(formik.errors.fatherName)
                      : false
                  }
                  helperText={
                    formik.touched.fatherName && formik.errors.fatherName
                  }
                  isRequired={true}
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="Father's Occupation"
                  id="fatherOccupation"
                  name="fatherOccupation"
                  value={formik.values.fatherOccupation ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.fatherOccupation
                      ? Boolean(formik.errors.fatherOccupation)
                      : false
                  }
                  helperText={
                    formik.touched.fatherOccupation &&
                    formik.errors.fatherOccupation
                  }
                  isRequired={true}
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="Mother's Name"
                  id="motherName"
                  name="motherName"
                  value={formik.values.motherName ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.motherName
                      ? Boolean(formik.errors.motherName)
                      : false
                  }
                  helperText={
                    formik.touched.motherName && formik.errors.motherName
                  }
                  isRequired={true}
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="Mother's Occupation"
                  id="motherOccupation"
                  name="motherOccupation"
                  value={formik.values.motherOccupation ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.motherOccupation
                      ? Boolean(formik.errors.motherOccupation)
                      : false
                  }
                  helperText={
                    formik.touched.motherOccupation &&
                    formik.errors.motherOccupation
                  }
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="No. of Brother (If any)"
                  id="brotherCount"
                  name="brotherCount"
                  value={formik.values.brotherCount ?? 0}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.brotherCount
                      ? Boolean(formik.errors.brotherCount)
                      : false
                  }
                  helperText={
                    formik.touched.brotherCount && formik.errors.brotherCount
                  }
                  InputProps={{
                    type: "number",
                  }}
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="No. of Sister (If any)"
                  id="sisterCount"
                  name="sisterCount"
                  value={formik.values.sisterCount ?? 0}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.sisterCount
                      ? Boolean(formik.errors.sisterCount)
                      : false
                  }
                  helperText={
                    formik.touched.sisterCount && formik.errors.sisterCount
                  }
                  InputProps={{
                    type: "number",
                  }}
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="Alternate Contact Number"
                  id="alternateContact"
                  name="alternateContact"
                  value={formik.values.alternateContact ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.alternateContact
                      ? Boolean(formik.errors.alternateContact)
                      : false
                  }
                  helperText={
                    formik.touched.alternateContact &&
                    formik.errors.alternateContact
                  }
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
                <CustomTextField
                  label="Category"
                  select
                  fullWidth={true}
                  id="category"
                  name="category"
                  value={formik.values.category ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.category
                      ? Boolean(formik.errors.category)
                      : false
                  }
                  helperText={formik.touched.category && formik.errors.category}
                  isRequired={true}
                  variant={"standard"}
                >
                  {Object.keys(categories).map((cat) => {
                    return (
                      <StyledMenuItem key={cat} value={cat}>
                        {cat}
                      </StyledMenuItem>
                    );
                  })}
                </CustomTextField>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                margin={"auto 0"}
                paddingBottom="1.5em"
              >
                <Typography color={"#434343"} marginRight={5}>
                  House
                </Typography>
                <RadioGroup
                  row={true}
                  id="house"
                  name="house"
                  value={formik.values.house ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(HOUSE_ENUM)?.map((build) => {
                    return (
                      <FormControlLabel
                        value={build}
                        control={<Radio />}
                        label={build}
                      />
                    );
                  })}
                </RadioGroup>
              </Grid>
              <FormHelperText
                error={
                  formik.touched.house ? Boolean(formik.errors.house) : false
                }
              >
                {!!formik.touched.house && formik.errors.house}
              </FormHelperText>
              <Grid item xs={12} md={12} margin={"auto 0"}>
                <Typography variant="body1" color={"#434343"}>
                  Address<span style={{ color: "red" }}>*</span>
                </Typography>
                <CustomTextField
                  fullWidth={true}
                  multiline={true}
                  label=""
                  rows={2}
                  id="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address
                      ? Boolean(formik.errors.address)
                      : false
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  style={{ marginBottom: "16px" }}
                />
              </Grid>
              <Grid item xs={12} md={12} margin={"auto 0"}>
                <Typography variant="body1" color={"#434343"}>
                  Preferred Contact Time
                </Typography>
                <CustomTextField
                  placeholder="Contact me on WhatsApp only / Call after 8 PM / Weekends Only "
                  fullWidth={true}
                  multiline={true}
                  label=""
                  rows={2}
                  id="contactPreference"
                  name="contactPreference"
                  value={formik.values.contactPreference}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactPreference
                      ? Boolean(formik.errors.contactPreference)
                      : false
                  }
                  helperText={
                    formik.touched.contactPreference &&
                    formik.errors.contactPreference
                  }
                  style={{ marginBottom: "16px" }}
                />
              </Grid>
            </Grid>
          </Box>
          <HeadTitle title="HOROSCOPE DETAILS" />
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Gotra"
                select
                fullWidth={true}
                id="gotra"
                name="gotra"
                value={formik.values.gotra}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.gotra ? Boolean(formik.errors.gotra) : false
                }
                helperText={formik.touched.gotra && formik.errors.gotra}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(GOTRA_ENUM).map((gotra) => {
                  return (
                    <StyledMenuItem key={gotra} value={GOTRA_ENUM[gotra]}>
                      {gotra}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Charana/Pada"
                select
                fullWidth={true}
                id="charana"
                name="charana"
                value={formik.values.charana}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.charana
                    ? Boolean(formik.errors.charana)
                    : false
                }
                helperText={formik.touched.charana && formik.errors.charana}
                isRequired={false}
                variant={"standard"}
              >
                {Object.keys(CHARANA_ENUM).map((charana) => {
                  return (
                    <StyledMenuItem key={charana} value={CHARANA_ENUM[charana]}>
                      {charana}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Rashi"
                select
                fullWidth={true}
                id="rashi"
                name="rashi"
                value={formik.values.rashi}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.rashi ? Boolean(formik.errors.rashi) : false
                }
                helperText={formik.touched.rashi && formik.errors.rashi}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(RASHI_ENUM).map((rashi) => {
                  return (
                    <StyledMenuItem key={rashi} value={RASHI_ENUM[rashi]}>
                      {rashi}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Nakshatra"
                select
                fullWidth={true}
                id="nakshatra"
                name="nakshatra"
                value={formik.values.nakshatra}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.nakshatra
                    ? Boolean(formik.errors.nakshatra)
                    : false
                }
                helperText={formik.touched.nakshatra && formik.errors.nakshatra}
                isRequired={true}
                variant={"standard"}
              >
                {NAKSHATHRA_ENUM[formik?.values?.rashi]?.length ? (
                  NAKSHATHRA_ENUM[formik?.values?.rashi].map((rashi) => {
                    return (
                      <StyledMenuItem key={rashi} value={rashi}>
                        {rashi}
                      </StyledMenuItem>
                    );
                  })
                ) : (
                  <StyledMenuItem value={""}>No Data Found</StyledMenuItem>
                )}
              </CustomTextField>
            </Grid>
          </Grid>
          <HorizontalLine />
          <HeadTitle title={"PERSONAL DETAILS"} />
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Where do Vadhu/Vara Stay currently (Region) ?"
                select
                fullWidth={true}
                id="region"
                name="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.region ? Boolean(formik.errors.region) : false
                }
                helperText={formik.touched.region && formik.errors.region}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(LOCATION_REGION).map((region) => {
                  return (
                    <StyledMenuItem
                      key={region}
                      value={LOCATION_REGION[region]}
                    >
                      {region}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Where do Vadhu/Vara Stay currently (Place) ?"
                id="location"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.location
                    ? Boolean(formik.errors.location)
                    : false
                }
                helperText={formik.touched.location && formik.errors.location}
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Height in feet"
                select
                fullWidth={true}
                id="height"
                name="height"
                value={formik.values.height}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.height ? Boolean(formik.errors.height) : false
                }
                helperText={formik.touched.height && formik.errors.height}
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(HEIGHT_ENUM).map((height) => {
                  return (
                    <StyledMenuItem key={height} value={HEIGHT_ENUM[height]}>
                      {height}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              margin={"auto 0"}
              padding={"16px 0 16px 0"}
            >
              <Grid item xs={12} md={2} margin={"auto 0"} marginLeft={"1vw"}>
                <Typography
                  color={"#434343"}
                  marginRight={5}
                  style={{
                    fontWeight: 900,
                    fontSize: "20pt",
                    borderRadius: "0.7rem",
                  }}
                >
                  <strong>Build</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} margin={"auto 0"}>
                <RadioGroup
                  row={true}
                  id="build"
                  name="build"
                  value={formik.values.build}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(BUILD_ENUM)?.map((build) => {
                    return (
                      <Grid item xs={6} md={3} margin={"auto 0"}>
                        <FormControlLabel
                          value={BUILD_ENUM[build]}
                          control={<Radio />}
                          label={build}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
            </Grid>
            <FormHelperText
              error={
                formik.touched.build ? Boolean(formik.errors.build) : false
              }
            >
              {!!formik.touched.build && formik.errors.build}
            </FormHelperText>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <Box style={{ marginTop: 15 }}>
                <Typography variant="body1" color={"#434343"}>
                  About themselves
                </Typography>
                <CustomTextField
                  fullWidth={true}
                  multiline={true}
                  label=""
                  rows={2}
                  id="about"
                  name="about"
                  value={formik.values.about}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.about ? Boolean(formik.errors.about) : false
                  }
                  helperText={formik.touched.about && formik.errors.about}
                  style={{ marginBottom: "16px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <Box style={{ marginTop: 15 }}>
                <Typography variant="body1" color={"#434343"}>
                  Partner Preferences
                </Typography>
                <CustomTextField
                  fullWidth={true}
                  multiline={true}
                  label=""
                  rows={2}
                  id="partnerPreferences"
                  name="partnerPreferences"
                  value={formik.values.partnerPreferences}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.partnerPreferences
                      ? Boolean(formik.errors.partnerPreferences)
                      : false
                  }
                  helperText={
                    formik.touched.partnerPreferences &&
                    formik.errors.partnerPreferences
                  }
                  style={{ marginBottom: "16px" }}
                />
              </Box>
            </Grid>
          </Grid>
          <HorizontalLine />
          <HeadTitle
            title={"EDUCATION QUALIFICATIONS & PROFESSIONAL DETAILS"}
          />
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Highest Qualification"
                select
                fullWidth={true}
                id="higherQualification"
                name="higherQualification"
                value={formik.values.higherQualification}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.higherQualification
                    ? Boolean(formik.errors.higherQualification)
                    : false
                }
                helperText={
                  formik.touched.higherQualification &&
                  formik.errors.higherQualification
                }
                isRequired={true}
                variant={"standard"}
              >
                {Object.keys(HIGHEST_EDUCATION_ENUM).map((h_edu) => {
                  return (
                    <StyledMenuItem
                      key={h_edu}
                      value={HIGHEST_EDUCATION_ENUM[h_edu]}
                    >
                      {h_edu}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Post-Graduation"
                select
                fullWidth={true}
                id="postGraduation"
                name="postGraduation"
                value={formik.values.postGraduation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postGraduation
                    ? Boolean(formik.errors.postGraduation)
                    : false
                }
                helperText={
                  formik.touched.postGraduation && formik.errors.postGraduation
                }
                isRequired={false}
                variant={"standard"}
              >
                {Object.keys(POST_GRADUATION).map((pg) => {
                  return (
                    <StyledMenuItem key={pg} value={POST_GRADUATION[pg]}>
                      {pg}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Completion Year (PG)"
                select
                fullWidth={true}
                id="postGraduationYear"
                name="postGraduationYear"
                value={formik.values.postGraduationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postGraduationYear
                    ? Boolean(formik.errors.postGraduationYear)
                    : false
                }
                helperText={
                  formik.touched.postGraduationYear &&
                  formik.errors.postGraduationYear
                }
                isRequired={false}
                variant={"standard"}
              >
                {YEARS_LIST?.map((year) => {
                  return (
                    <StyledMenuItem key={year} value={year}>
                      {year}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Graduation"
                select
                fullWidth={true}
                id="graduation"
                name="graduation"
                value={formik.values.graduation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.graduation
                    ? Boolean(formik.errors.graduation)
                    : false
                }
                helperText={
                  formik.touched.graduation && formik.errors.graduation
                }
                isRequired={false}
                variant={"standard"}
              >
                {Object.keys(GRADUATION_ENUM).map((grad) => {
                  return (
                    <StyledMenuItem key={grad} value={GRADUATION_ENUM[grad]}>
                      {grad}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Completion Year (UG)"
                select
                fullWidth={true}
                id="graduationYear"
                name="graduationYear"
                value={formik.values.graduationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.graduationYear
                    ? Boolean(formik.errors.graduationYear)
                    : false
                }
                helperText={
                  formik.touched.graduationYear && formik.errors.graduationYear
                }
                isRequired={false}
                variant={"standard"}
              >
                {YEARS_LIST?.map((year) => {
                  return (
                    <StyledMenuItem key={year} value={year}>
                      {year}
                    </StyledMenuItem>
                  );
                })}
              </CustomTextField>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Current Employer"
                id="employer"
                name="employer"
                value={formik.values.employer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.employer
                    ? Boolean(formik.errors.employer)
                    : false
                }
                helperText={formik.touched.employer && formik.errors.employer}
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Previous Employement"
                id="previousEmployment"
                name="previousEmployment"
                value={formik.values.previousEmployment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.previousEmployment
                    ? Boolean(formik.errors.previousEmployment)
                    : false
                }
                helperText={
                  formik.touched.previousEmployment &&
                  formik.errors.previousEmployment
                }
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Working Since"
                id="workingSince"
                name="workingSince"
                value={formik.values.workingSince}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.workingSince
                    ? Boolean(formik.errors.workingSince)
                    : false
                }
                helperText={
                  formik.touched.workingSince && formik.errors.workingSince
                }
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Total Working years"
                id="workExperience"
                name="workExperience"
                value={formik.values.workExperience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.workExperience
                    ? Boolean(formik.errors.workExperience)
                    : false
                }
                helperText={
                  formik.touched.workExperience && formik.errors.workExperience
                }
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"} paddingBottom="1.5em">
              <CustomTextField
                label="Monthly Income (Rs)"
                id="income"
                name="income"
                value={formik.values.income}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.income ? Boolean(formik.errors.income) : false
                }
                helperText={formik.touched.income && formik.errors.income}
                isRequired={false}
                variant={"standard"}
              />
            </Grid>
          </Grid>
          <HorizontalLine />
          <HeadTitle title={"FAMILY & TRADITIONAL DETAILS"} />
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid
              container
              xs={12}
              md={12}
              margin={"auto 0"}
              padding={"16px 0 16px 0"}
            >
              <Grid item xs={12} md={2.5} margin={"auto 0"} marginLeft={"1vw"}>
                <Typography color={"#434343"} marginRight={5}>
                  Family Values<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid item xs={12} md={9} margin={"auto 0"}>
                <RadioGroup
                  row={true}
                  id="familyValues"
                  name="familyValues"
                  value={formik.values.familyValues}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(FAMILY_VALUE_ENUM)?.map((build) => {
                    return (
                      <Grid item xs={6} md={3} margin={"auto 0"}>
                        <FormControlLabel
                          value={FAMILY_VALUE_ENUM[build]}
                          control={<Radio />}
                          label={build}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
              <FormHelperText
                error={
                  formik.touched.familyValues
                    ? Boolean(formik.errors.familyValues)
                    : false
                }
              >
                {!!formik.touched.familyValues && formik.errors.familyValues}
              </FormHelperText>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              margin={"auto 0"}
              padding={"16px 0 16px 0"}
            >
              <Grid item xs={12} md={2.5} margin={"auto 0"} marginLeft={"1vw"}>
                <Typography color={"#434343"} marginRight={5}>
                  {"Family Type"}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} margin={"auto 0"}>
                <RadioGroup
                  row={true}
                  id="familyType"
                  name="familyType"
                  value={formik.values.familyType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(FAMILY_TYPE_ENUM)?.map((build) => {
                    return (
                      <Grid item xs={6} md={3} margin={"auto 0"}>
                        <FormControlLabel
                          value={FAMILY_TYPE_ENUM[build]}
                          control={<Radio />}
                          label={build}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
              <FormHelperText
                error={
                  formik.touched.familyType
                    ? Boolean(formik.errors.familyType)
                    : false
                }
              >
                {!!formik.touched.familyType && formik.errors.familyType}
              </FormHelperText>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              margin={"auto 0"}
              padding={"16px 0 16px 0"}
            >
              <Grid item xs={12} md={2.5} margin={"auto 0"} marginLeft={"1vw"}>
                <Typography color={"#434343"} marginRight={5}>
                  Family Status<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} margin={"auto 0"} paddingBottom="1.5em">
                <RadioGroup
                  row={true}
                  id="familyStatus"
                  name="familyStatus"
                  value={formik.values.familyStatus}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.keys(FAMILY_STATUS_ENUM)?.map((build) => {
                    return (
                      <Grid item xs={6} md={3} margin={"auto 0"}>
                        <FormControlLabel
                          value={FAMILY_STATUS_ENUM[build]}
                          control={<Radio />}
                          label={build}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
              <FormHelperText
                error={
                  formik.touched.familyStatus
                    ? Boolean(formik.errors.familyStatus)
                    : false
                }
              >
                {!!formik.touched.familyStatus && formik.errors.familyStatus}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <Typography variant="body1" color={"#434343"}>
                Any other information of your family
              </Typography>
              <CustomTextField
                fullWidth={true}
                multiline={true}
                label=""
                rows={2}
                id="familyOtherInfo"
                name="familyOtherInfo"
                value={formik.values.familyOtherInfo}
                onChange={formik.handleChange}
                error={
                  formik.touched.familyOtherInfo
                    ? Boolean(formik.errors.familyOtherInfo)
                    : false
                }
                helperText={
                  formik.touched.familyOtherInfo &&
                  formik.errors.familyOtherInfo
                }
                style={{ marginBottom: "16px" }}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <Typography variant="body1" color={"#434343"}>
                Your expectations on family value & traditions
              </Typography>
              <CustomTextField
                fullWidth={true}
                multiline={true}
                label=""
                rows={2}
                id="familyViews"
                name="familyViews"
                value={formik.values.familyViews}
                onChange={formik.handleChange}
                error={
                  formik.touched.familyViews
                    ? Boolean(formik.errors.familyViews)
                    : false
                }
                helperText={
                  formik.touched.familyViews && formik.errors.familyViews
                }
                style={{ marginBottom: "16px" }}
              />
            </Grid>
          </Grid>
          <hr />
          <Grid item display={"flow-root"} xs={12} md={12} margin={"auto 0"}>
            <Button
              xs={12}
              md
              variant="contained"
              size="large"
              type="submit"
              className="edit_buttons"
              style={{
                marginLeft: 16,
                fontFamily: "Roboto",
                fontWeight: 700,
                color: "#663399",
                borderRadius: "0.7rem",
                marginBottom: "1em",
                float: "right",
              }}
              disabled={!formik.isValid}
            >
              Save
            </Button>
            <Button
              xs={12}
              md
              variant="outlined"
              size="large"
              style={{
                fontFamily: "Roboto",
                fontWeight: 700,
                borderRadius: "0.7rem",
                marginBottom: "1em",
                float: "right",
                color: "#000000",
                borderColor: "#000000",
              }}
              onClick={(e) => {
                props.history.push("/my-profile");
              }}
              disabled={!formik.isValid}
              className="edit_buttons"
            >
              Cancel
            </Button>
          </Grid>
        </form>
      </Container>
    </Spinner>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchProfileDetails: (type, onSuccess, onError) =>
    dispatch(FetchProfileDetails(type, onSuccess, onError)),
  dispatchUpdateProfileDetails: (data, onSuccess, onError) =>
    dispatch(UpdateProfileDetails(data, onSuccess, onError)),
  dispatchDeleteUserImage: (data, onSuccess, onError) =>
    dispatch(DeleteUserImage(data, onSuccess, onError)),
  dispatchGetImageUploadUrl: (onSuccess, onError) =>
    dispatch(GetImageUploadUrl(onSuccess, onError)),
  dispatchGetImageUrl: (onSuccess, onError) =>
    dispatch(GetImageUrl(onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
