/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./MyInbox.scss";
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  Stack,
  Pagination,
} from "@mui/material";
import { connect } from "react-redux";
import { FetchMatchInvite } from "../../Store/Actions/matchesActionCreator";
import InboxUserCard from "../../Components/Cards/InboxUserCard";
import { CustomTextField, Spinner, StyledMenuItem } from "../../Components/UI";
import { EmptyImage } from "../../Assets/svg";
import { INVITE_STATUS_ENUM } from "../../Utils/constants";
import { showMessage } from "../../Utils/helper";
import ToggleButton from "@mui/material/ToggleButton";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function MyInbox({ user, dispatchFetchMatchInvite, ...props }) {
  const isMobile = window.innerWidth < 600;
  const filterInfo = {
    ...(props?.location?.state?.filterInfo ?? {}),
  };
  const [value, setValue] = React.useState(
    ...(filterInfo?.type === "sent" ? "2" : "1")
  );
  const [loading, setLoading] = React.useState(false);
  const [filterPayload, setFilterPayload] = React.useState({
    type: "received",
    category: "",
    ...filterInfo,
  });
  const [inviteResp, setInviteResp] = React.useState({ matchData: [] });
  const [invites, setInvites] = React.useState({
    [INVITE_STATUS_ENUM.SENT]: [],
    // [INVITE_STATUS_ENUM.RECEIVED]: [],
    [INVITE_STATUS_ENUM.ACCEPTED]: [],
    [INVITE_STATUS_ENUM.USER_ACCEPTED]: [],
    [INVITE_STATUS_ENUM.SYSTEM_ACCEPTED]: [],
    [INVITE_STATUS_ENUM.REJECTED]: [],
    [INVITE_STATUS_ENUM.SYSTEM_REJECTED]: [],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFilterPayload({
      ...filterPayload,
      type: newValue === "1" ? "received" : "sent",
      category: "",
      page: 1,
    });
    setInviteResp({ matchData: [] });
  };

  const fetchInvites = (filters = {}) => {
    setLoading(true);
    const payload = { ...filterPayload, ...filters };
    dispatchFetchMatchInvite(
      payload,
      (resp) => {
        if (resp?.status) {
          setInviteResp(resp?.result ?? {});
          let newInvtes = { ...invites };
          Object.keys(newInvtes).forEach((item) => {
            newInvtes = {
              ...newInvtes,
              [item]: resp?.result?.matchData?.filter(
                (i) => i.invitationStatus === item
              ),
            };
          });

          setInvites(newInvtes);
          setLoading(false);
        } else {
          showMessage(resp?.result, "error");
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    window.scrollTo({
      x: 0,
      y: 0,
    });
    fetchInvites({
      category:
        filterPayload?.category === "all" ? "" : filterPayload?.category,
    });
  }, [filterPayload]);

  const pageNo = Number(inviteResp?.page ?? 0);

  const CardGrid = ({ item, type }) => {
    return (
      <Grid item className="inboxlist" padding={0} paddingBottom="1rem">
        <InboxUserCard
          type={type}
          fetchInvites={fetchInvites}
          setCategoryType={(val) => {
            setFilterPayload({
              ...filterPayload,
              category: val,
            });
          }}
          filterPayload={filterPayload}
          data={{ ...item }}
        />
      </Grid>
    );
  };
  const filterByInput = isMobile ? (
    <CustomTextField
      label="Filter By"
      select
      fullWidth={true}
      value={filterPayload?.category ?? ""}
      onChange={(e) => {
        setFilterPayload({
          ...filterPayload,
          category: e.target.value,
          page: 1,
        });
      }}
      isRequired={true}
      variant={"standard"}
    >
      <StyledMenuItem key="ACCEPTED" value="ACCEPTED">
        ACCEPTED
      </StyledMenuItem>
      <StyledMenuItem key="SENT" value="SENT">
        PENDING
      </StyledMenuItem>
      <StyledMenuItem key="REJECTED" value="REJECTED">
        DECLINED
      </StyledMenuItem>
    </CustomTextField>
  ) : (
    <Tabs
      value={filterPayload?.category}
      variant="scrollable"
      scrollButtons="auto"
      textColor="primary"
      indicatorColor="text"
      style={{ display: "inline-block" }}
    >
      <Typography
        style={{ display: "flex", margin: "auto", paddingRight: "1em" }}
      >
        Filter By :
      </Typography>
      <ToggleButton
        className="toggleButtoncss"
        value="SENT"
        label="Pending"
        onClick={(_, newValue) => {
          setFilterPayload({
            ...filterPayload,
            category: filterPayload?.category === "SENT" ? "" : "SENT",
            page: 1,
          });
        }}
        selected="true"
        style={{ marginRight: "2rem", borderRadius: "5em" }}
      >
        {" "}
        Pending{" "}
      </ToggleButton>
      <ToggleButton
        className="toggleButtoncss"
        value="ACCEPTED"
        label="Accepted"
        style={{ marginRight: "2rem", borderRadius: "5em" }}
        onClick={(_, newValue) => {
          setFilterPayload({
            ...filterPayload,
            category: filterPayload?.category === "ACCEPTED" ? "" : "ACCEPTED",
            page: 1,
          });
        }}
      >
        {" "}
        Accepted{" "}
      </ToggleButton>
      <ToggleButton
        className="toggleButtoncss"
        value="REJECTED"
        label="Declined"
        onClick={(_, newValue) => {
          setFilterPayload({
            ...filterPayload,
            category: filterPayload?.category === "REJECTED" ? "" : "REJECTED",
            page: 1,
          });
        }}
        style={{ marginRight: "2rem", borderRadius: "5em" }}
      >
        {" "}
        Declined{" "}
      </ToggleButton>
    </Tabs>
  );
  return (
    <Spinner loading={loading}>
      <div id="MyInbox" className="myinbox">
        <Container className="base-container" maxWidth="unset">
          <div className="maindivinbox">
            <center>
              {user?.prime ? (
                <div>
                  <Typography
                    fontSize={"small"}
                    sx={{
                      width: "100%",
                      fontWeight: "bolder",
                      textAlign: "center",
                      color: "green",
                      paddingBottom: "1em",
                    }}
                  >
                    You are a VVS PRIME member. You can view the contacts for
                    the invites you have sent.
                    <br />
                    Your PRIME membership ends on {user.primeExpiryDate}
                  </Typography>
                  <Typography
                    fontSize={"small"}
                    sx={{
                      width: "100%",
                      fontWeight: "bolder",
                      textAlign: "center",
                      color: "gray",
                      paddingBottom: "1em",
                    }}
                  >
                    Invites will be auto-declined if no action is taken in 30
                    days
                    <br />
                  </Typography>
                </div>
              ) : (
                <div>
                  <Typography
                    fontSize={"small"}
                    sx={{
                      width: "100%",
                      fontWeight: "bolder",
                      textAlign: "center",
                      paddingBottom: "1em",
                      fontSize: 16,
                      color: "#b0001f",
                    }}
                  >
                    {" "}
                    You can view the Contact Details, once the invitation is
                    Accepted. <br />
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={(e) => {
                        props.history.push("/my-account");
                      }}
                    >
                      Upgrade to VVS PRIME
                    </span>
                    &nbsp;- to view the Contact Details immediately of the
                    Invites you have sent.
                  </Typography>
                  <Typography
                    fontSize={"small"}
                    sx={{
                      width: "100%",
                      fontWeight: "bolder",
                      textAlign: "center",
                      color: "gray",
                      paddingBottom: "1em",
                    }}
                  >
                    Invites will be auto-declined if no action is taken in 30
                    days
                    <br />
                  </Typography>
                </div>
              )}

              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="text"
                style={{
                  display: "inline-flex",
                  borderRadius: "5em",
                  backgroundColor: "wheat",
                }}
              >
                <Tab
                  value="1"
                  label="RECEIVED"
                  style={{ marginRight: "2rem", borderRadius: "5em" }}
                />
                <Tab value="2" label="SENT" style={{ borderRadius: "5em" }} />
              </Tabs>
            </center>
            <TabPanel index="1" value={value}>
              <center>{filterByInput}</center>
              {!inviteResp?.matchData?.length && (
                <Grid
                  className="inboxlist"
                  padding={0}
                  paddingBottom="1rem"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <EmptyImage width={300} height={300} />
                </Grid>
              )}
              {!!invites.SENT?.length && (
                <>
                  <Grid className="inboxlist" padding="1rem 0">
                    <Typography
                      color="#515f6d"
                      className="indexnotificationcount"
                    >
                      Pending ({invites.SENT?.length})
                    </Typography>
                  </Grid>
                  {invites.SENT?.map((item) => {
                    return CardGrid({ item, type: "RECEIVED" });
                  })}
                </>
              )}
              {(!!invites.USER_ACCEPTED?.length ||
                !!invites.SYSTEM_ACCEPTED?.length ||
                !!invites.ACCEPTED?.length) && (
                <>
                  <Grid className="inboxlist" padding="1rem 0">
                    <Typography
                      color="#00AA44"
                      className="indexnotificationcount"
                    >
                      Accepted (
                      {invites.ACCEPTED?.length +
                        invites.USER_ACCEPTED?.length +
                        invites.SYSTEM_ACCEPTED?.length}
                      )
                    </Typography>
                  </Grid>
                  {invites.USER_ACCEPTED?.map((item) => {
                    return CardGrid({ item, type: "RECEIVED" });
                  })}
                  {invites.SYSTEM_ACCEPTED?.map((item) => {
                    return CardGrid({ item, type: "RECEIVED" });
                  })}
                </>
              )}
              {(!!invites.REJECTED?.length ||
                !!invites.SYSTEM_REJECTED?.length) && (
                <>
                  <Grid className="inboxlist" padding="1rem 0">
                    <Typography color="red" className="indexnotificationcount">
                      Declined (
                      {invites.REJECTED?.length +
                        invites.SYSTEM_REJECTED?.length}
                      )
                    </Typography>
                  </Grid>
                  {invites.SYSTEM_REJECTED?.map((item) => {
                    return CardGrid({ item, type: "RECEIVED" });
                  })}
                    {invites.REJECTED?.map((item) => {
                    return CardGrid({ item, type: "RECEIVED" });
                  })}
                </>
              )}
            </TabPanel>
            <TabPanel index="2" value={value}>
              <center>{filterByInput}</center>
              {!inviteResp?.matchData?.length && (
                <Grid
                  className="inboxlist"
                  padding={0}
                  paddingBottom="1rem"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <EmptyImage width={300} height={300} />
                </Grid>
              )}
              {!!invites.SENT?.length && (
                <>
                  <Grid className="inboxlist" padding="1rem 0">
                    <Typography
                      color="#515f6d"
                      className="indexnotificationcount"
                    >
                      Pending ({invites.SENT?.length})
                    </Typography>
                  </Grid>
                  {invites.SENT?.map((item) => {
                    return CardGrid({ item, type: "SENT" });
                  })}
                </>
              )}
              {(!!invites.USER_ACCEPTED?.length ||
                !!invites.SYSTEM_ACCEPTED?.length ||
                !!invites.ACCEPTED?.length) && (
                <>
                  <Grid className="inboxlist" padding="1rem 0">
                    <Typography
                      color="#00AA44"
                      className="indexnotificationcount"
                    >
                      Accepted (
                      {invites.ACCEPTED?.length +
                        invites.USER_ACCEPTED?.length +
                        invites.SYSTEM_ACCEPTED?.length}
                      )
                    </Typography>
                  </Grid>
                  {invites.USER_ACCEPTED?.map((item) => {
                    return CardGrid({ item, type: "SENT" });
                  })}
                  {invites.SYSTEM_ACCEPTED?.map((item) => {
                    return CardGrid({ item, type: "SENT" });
                  })}
                </>
              )}
              {(!!invites.REJECTED?.length ||
                !!invites.SYSTEM_REJECTED?.length) && (
                <>
                  <Grid className="inboxlist" padding="1rem 0">
                    <Typography color="red" className="indexnotificationcount">
                      Declined (
                      {invites.REJECTED?.length +
                        invites.SYSTEM_REJECTED?.length}
                      )
                    </Typography>
                  </Grid>
                  {invites.SYSTEM_REJECTED?.map((item) => {
                    return CardGrid({ item, type: "SENT" });
                  })}
                  {invites.REJECTED?.map((item) => {
                    return CardGrid({ item, type: "SENT" });
                  })}
                </>
              )}
            </TabPanel>
            <Stack
              spacing={2}
              width="22em"
              margin={"2em auto 1em auto"}
              style={{ textAlign: "center" }}
            >
              <Pagination
                count={Math.ceil(
                  Number(inviteResp?.totalCount) / Number(inviteResp?.size) >= 1
                    ? Number(inviteResp?.totalCount) / Number(inviteResp?.size)
                    : 1
                )}
                page={pageNo}
                shape="rounded"
                onChange={(e, page) => {
                  setFilterPayload({
                    ...filterPayload,
                    page: page,
                  });
                }}
              />
            </Stack>
          </div>
        </Container>
      </div>
    </Spinner>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchMatchInvite: (type, onSuccess, onError) =>
    dispatch(FetchMatchInvite(type, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyInbox);
