import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import routes from "../routes";
import { RouteLayouts } from "../../../Utils/constants";
import MainLayout from "../../../Layouts/MainLayout";
import HomeLayout from "../../../Layouts/HomeLayout";
import BlankLayout from "../../../Layouts/BlankLayout";
import RegistrationLayout from "../../../Layouts/RegistrationLayout";

function AppRoute(props) {
  const getLayouts = (routes) => {
    let setRoutes = routes.map((prop, key) => {
      switch (prop.layout) {
        case RouteLayouts.HomeLayout:
          return (
            <HomeLayout
              {...props}
              key={key}
              path={prop.path}
              component={prop.component}
              name={prop.name}
            />
          );
        case RouteLayouts.MainLayout:
          return (
            <MainLayout
              {...props}
              key={key}
              path={prop.path}
              component={prop.component}
              name={prop.name}
            />
          );
        case RouteLayouts.BlankLayout:
          return (
            <BlankLayout
              {...props}
              key={key}
              path={prop.path}
              component={prop.component}
              name={prop.name}
            />
          );
        case RouteLayouts.RegistrationLayout:
          return (
            <RegistrationLayout
              {...props}
              key={key}
              path={prop.path}
              component={prop.component}
              name={prop.name}
            />
          );
        default:
          return (
            <MainLayout
              {...props}
              key={key}
              path={prop.path}
              component={prop.component}
              name={prop.name}
            />
          );
      }
    });
    return setRoutes;
  };
  return (
    <div className="app-routes">
      <BrowserRouter>
        <Switch>
          {getLayouts(routes)}
          <Route exact path="/" render={() => <Redirect to={"/login"} />} />
          <Route render={() => <Redirect to={"/404"} />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default AppRoute;
