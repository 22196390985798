import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import MessageIcon from "@mui/icons-material/Message";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, withRouter } from "react-router-dom";
import LoginModal from "../Modals/ModalContexts/LoginModal";
import { Badge, Divider, SwipeableDrawer } from "@mui/material";
import { Button } from "../UI";
import { connect } from "react-redux";
import { isMobileView, logout } from "../../Utils/helper";
import OtpLoginModal from "../Modals/ModalContexts/OtpLoginModal";
import "./styles.scss";
import { fetchChatCount } from "../../Store/Actions/conversationActionCreator";
import { POLL_CHAT_COUNT_API_MS } from "../../Utils/constants";
export const pages = [
  { label: "Home", path: "home" },
  { label: "About us", path: "about" },
  { label: "Working", path: "working" },
  { label: "Benefits", path: "benefits" },
  { label: "Success Stories", path: "success-story" },
  { label: "Donate", path: "Donate" },
];
export const privatePages = [
  { label: "MY Matches", path: "/my-matches" },
  { label: "Profile", path: "/my-profile" },
  { label: "My Inbox", path: "/my-inbox" },
  { label: "My Account", path: "/my-account" },
  { label: "My Conversations", path: "/my-conversations" },
  // { label: "Donate", path: "/donate" },
];
const settings = ["My Account", "Delete Account", "Logout"];

const Navbar = ({
  user,
  notificationCount,
  chatTotalCount,
  dispatchChatCount,
  ...props
}) => {
  const [, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLoginModalOpen, toggleLoginModal] = React.useState(false);
  const [isOtpLoginModalOpen, toggleOtpLoginModal] = React.useState(false);
  const [isDrawerOpen, toggleDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    toggleDrawerOpen(open);
  };

  const handleOpenNavMenu = (event) => {
    toggleDrawerOpen(true);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(!!anchorElUser ? null : event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(false);
  };

  const handleCloseUserMenu = (setting) => (e) => {
    setAnchorElUser(null);
    switch (setting) {
      case "Logout":
        logout();
        break;
      case "My Account":
        props.history.push("/my-account");
        break;
      case "Delete Account":
        props.history.push("/my-profile/delete");
        break;
      case "My Profile":
        props.history.push("/my-profile");
        break;
      case "My Inbox":
        props.history.push("/my-inbox");
        break;
      case "My Matches":
        props.history.push("/my-matches");
        break;
      case "My Conversations":
        props.history.push("/my-conversations");
        break;
      default:
        break;
    }
  };

  const handleNavItemAction = (page) => (e) => {
    if (isLoginUser && !isHomePage) {
      props.history.push(page.path?.toLowerCase());
    } else {
      handleCloseNavMenu(e);
      if (
        ["Donate", ...privatePages?.map((i) => i?.path)].includes(page.path)
      ) {
        window.scrollTo(0, 0);
        props.history.push(page.path);
      } else {
        const divEl = document.getElementById(page.path);
        if (!divEl) {
          window.scrollTo(0, 0);
          props.history.push("/", { divEl });
        } else {
          document.querySelector("#" + page.path).scrollIntoView();
          // divEl.scrollIntoView({
          //   behavior: "smooth",
          // });
        }
        setTimeout(() => {
          toggleDrawerOpen(false);
        }, 100);
      }
    }
  };

  React.useEffect(() => {
    let interval = null;
    if (!!user?.accessToken) {
      dispatchChatCount();
      interval = setInterval(() => {
        dispatchChatCount();
      }, POLL_CHAT_COUNT_API_MS);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const isLoginUser =
    !!Object.keys(user?.imageUrl ?? {}).length && !!user?.accessToken;
  const isHomePage = props.location?.pathname === "/";
  return (
    <div className="appbar">
      <AppBar position="fixed" color={"secondary"}>
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                mr: 2,
                display: "flex",
                color: "#fff",
                width: isMobileView ? "55%" : "100%",
              }}
            >
              <Link to={"/"}>
                <Avatar
                  alt=""
                  src={"/images/logo.webp"}
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    color: "#000",
                  }}
                />
              </Link>

              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                  keepMounted
                  open={isDrawerOpen}
                  onClose={(e) => toggleDrawer(false)(e)}
                  onOpen={(e) => toggleDrawer(true)(e)}
                  sx={{
                    display: { xs: "block", md: "none" },
                    width: "90%",
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: "90%",
                      boxSizing: "border-box",
                      backgroundColor: "#F0EBF5",
                    },
                  }}
                >
                  {!!user && isLoginUser && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    >
                      {!!user?.["imageUrl"]?.[
                        Object.keys(user?.imageUrl ?? {})?.[0]
                      ] ? (
                        <Avatar
                          alt={user?.firstName ?? "a"}
                          sx={{ width: 100, height: 100, objectFit: "cover" }}
                          src={
                            user?.["imageUrl"]?.[
                              Object.keys(user?.imageUrl ?? {})?.[0]
                            ]
                          }
                        />
                      ) : (
                        <Avatar
                          alt={user?.firstName}
                          sx={{ width: 100, height: 100, objectFit: "cover" }}
                          src={""}
                        />
                      )}
                      <Typography
                        variant="body1"
                        sx={{
                          mt: 1.5,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                        color={"text.primary"}
                        gutterBottom
                      >
                        {`${user?.firstName ?? ""} ${user?.lastName ?? ""}`}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="#000000"
                        sx={{ fontWeight: "bold" }}
                      >
                        <u>Profile ID : {`${user?.id ?? ""}`} </u>
                      </Typography>
                      <Typography
                        color={"#16ce05"}
                        onClick={(e) => {
                          props.history.push("/my-account");
                        }}
                      >
                        {user?.prime
                          ? "VVS PRIME user"
                          : "Upgrade to VVS PRIME"}
                      </Typography>
                    </Box>
                  )}
                  <Divider sx={{ borderColor: "#000", width: "100%" }} />
                  {(isLoginUser && !isHomePage
                    ? privatePages
                    : [
                        ...pages?.filter((i) =>
                          isLoginUser ? i.path !== "Donate" : true
                        ),
                        ...(isLoginUser ? privatePages : []),
                      ]
                  ).map((page, index) => {
                    // let isActive = index === 0;
                    // if (isLoginUser)
                    //   isActive = page.path === window.location.pathname;
                    return (
                      <MenuItem
                        key={page.label}
                        onClick={handleNavItemAction(page)}
                      >
                        <Badge
                          sx={{ width: "100%" }}
                          color="error"
                          badgeContent={
                            page?.label === "My Conversations"
                              ? chatTotalCount
                              : 0
                          }
                        >
                          <Typography textAlign="center">
                            {page.label}
                          </Typography>
                        </Badge>
                      </MenuItem>
                    );
                  })}
                </SwipeableDrawer>
                <Link to={"/"}>
                  <Avatar
                    alt=""
                    src={"/images/logo.webp"}
                    sx={{
                      display: { xs: "flex", md: "none" },
                      color: "#000",
                    }}
                  />
                </Link>
              </Box>
              {/* for desktop */}
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {(isLoginUser && !isHomePage
                  ? privatePages
                  : [
                      ...pages?.filter((i) =>
                        isLoginUser ? i.path !== "Donate" : true
                      ),
                      ...(isLoginUser ? privatePages : []),
                    ]
                ).map((page, index) => {
                  let isActive = index === 0;
                  if (isLoginUser && !isHomePage)
                    isActive = page.path === window.location.pathname;
                  return (
                    <Button
                      key={page.label}
                      onClick={handleNavItemAction(page)}
                      sx={{
                        // my: 2,
                        mx: 1,
                        color: isActive ? "" : "#fff",
                        fontWeight: isActive ? "bold" : "normal",
                        display: "block",
                        borderBottom: isActive ? "2px solid #FFB955" : "none",
                        // borderRadius: 0,
                      }}
                    >
                      <Badge
                        sx={{ width: "100%" }}
                        color="error"
                        badgeContent={
                          page?.label === "My Conversations"
                            ? chatTotalCount
                            : 0
                        }
                      >
                        {page.label}
                      </Badge>
                    </Button>
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isLoginUser ? (
                <>
                  {!isMobileView ? (
                    <Button
                      className={user?.prime ? "width120" : "width180"}
                      style={{
                        color: "#16ce05",
                        fontWeight: 600,
                      }}
                      onClick={(e) => {
                        props.history.push("/my-account");
                      }}
                    >
                      {user?.prime ? "VVS PRIME user" : "Upgrade to VVS PRIME"}
                    </Button>
                  ) : (
                    <></>
                  )}
                  {isMobileView && (
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Notification">
                        <IconButton
                          onClick={() => {
                            props.history.push("/my-conversations");
                          }}
                          sx={{ pr: 3 }}
                        >
                          <Badge
                            badgeContent={chatTotalCount ?? 0}
                            color="error"
                          >
                            <MessageIcon sx={{ color: "#fff" }} />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Notifications">
                      <IconButton
                        onClick={() => {
                          props.history.push("/my-notification");
                        }}
                        sx={{ pr: 3 }}
                      >
                        <Badge
                          badgeContent={notificationCount ?? 0}
                          color="error"
                        >
                          <NotificationsNoneOutlinedIcon
                            sx={{ color: "#fff",fontSize:"1.2em" }}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                          alt={""}
                          src={
                            user?.["imageUrl"]?.[
                              Object.keys(user?.imageUrl ?? {})?.[0]
                            ]
                          }
                          sx={(theme) => {
                            return {
                              backgroundColor: "#fff",
                            };
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="simple-menu"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu()}
                    >
                      {settings.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={handleCloseUserMenu(setting)}
                        >
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </>
              ) : (
                <Box sx={{ flexGrow: 0 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#F86969",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#FFB955",
                      },
                    }}
                    color="primary"
                    disableElevation={true}
                    onClick={(e) => {
                      toggleLoginModal(true);
                    }}
                  >
                    Login
                  </Button>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {isLoginModalOpen && (
        <LoginModal
          open={isLoginModalOpen}
          toggleOtpLoginModal={() => {
            toggleLoginModal(false);
            toggleOtpLoginModal(true);
          }}
          onClose={() => {
            toggleLoginModal(false);
          }}
        />
      )}
      {isOtpLoginModalOpen && (
        <OtpLoginModal
          open={isOtpLoginModalOpen}
          toggleLoginModal={() => {
            toggleOtpLoginModal(false);
            toggleLoginModal(true);
          }}
          onClose={() => {
            toggleOtpLoginModal(false);
          }}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
  notificationCount: state.systemInfo?.notificationCount ?? 0,
  chatTotalCount: state?.conversation?.chatTotalCount,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchChatCount: (onSuccess, onFailure) =>
    dispatch(fetchChatCount(onSuccess, onFailure)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
