import React, { Component } from "react";
import closeIcon from "./../assets/close-icon.png";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      imageError: false,
    };
  }

  componentDidUpdate(nextPrev) {
    if(nextPrev?.imageUrl !== this.props.imageUrl) {
      this.setState({
        imageError: false
      })
    }
  }

  openMenu(e) {
    this.setState({
      anchorEl: e.currentTarget,
    });
  }

  closeMenu() {
    this.setState({
      anchorEl: null,
    });
  }

  handleOptionSelection(option) {
    this.props?.handleOptionChange(option);
    this.closeMenu();
  }

  render() {
    const { anchorEl, imageError } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className="sc-header">
        {!imageError ? (
          <img
            className="sc-header--img"
            src={this.props.imageUrl}
            alt={this.props.teamName}
            onError={() => {
              this.setState({
                imageError: true,
              });
            }}
          />
        ) : (
          <Avatar
            className="sc-header--img"
            alt={this.props.teamName}
            src={this.props.imageUrl}
            sx={{marginLeft: 1}}
          />
        )}
        {/* <img className="sc-header--img" src={this.props.imageUrl} alt="" /> */}
        <div className="sc-header--team-name">{this.props.teamName}</div>
        <div className="sc-header--close-button">
          <IconButton
            id="basic-button"
            aria-controls={!!open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={!!open ? "true" : undefined}
            onClick={(e) => this.openMenu(e)}
          >
            <MoreVertIcon sx={{ color: "#fff" }} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              this.closeMenu();
            }}
            sx={{ zIndex: 9999 }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                this.handleOptionSelection("DELETE");
              }}
            >
              Delete Messages
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.handleOptionSelection("REPORT_PROFILE");
              }}
            >
              Report Profile
            </MenuItem>
          </Menu>
        </div>
        <div className="sc-header--close-button" onClick={this.props.onClose}>
          <img src={closeIcon} alt="" />
        </div>
      </div>
    );
  }
}

export default Header;
