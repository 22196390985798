import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import "./style.scss"
export default function Donate() {
  const isMobileView = window.innerWidth < 600;
  return (
    <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px", }}>
      <Typography
        variant="h4"
        color={"#000000"}
        fontWeight="bold"
        className="donate_heading_align"
        paddingTop={"1em"}
      >
        Donate Now
      </Typography>
      <Divider
        sx={{
          borderColor: "rgba(102, 51, 153, 0.3)",
          my: "16px",
          mb: "30px",
        }}
      />
      <Box
        className="donate_content"
      >
        <Typography
          variant="h8"
          color={"#F86969"}
          fontWeight="bold"
          paddingTop={"1em"}
        >
          Donations made from this page directly credits to the Organization and it
          will not enable PRIME Feature for you. To enable VVS PRIME feature make the donation only from My-account page by clicking the 'Upgrade To VVS Prime' button.
          
        </Typography>
        <div>
        <br></br>
          Welcome to Vadhu Vara Samyojana (VVS), the online matrimonial portal
          of Sri Jaya Satya Pramoda Nidhi (JSPN), the social services wing of
          Sri Uttaradi Math <br />
          <br />
          <p>
            Over the last 8 years, this portal has assisted numerous Madhwa families in seeking suitable alliances.
            And we have now come up with a fresh & more user friendly version of the portal to make the alliance
            seeking process much more engaging & fruitful.We have integrated multiple channels like sms,
            email and also provided the latest technology backbone to this portal.
            We have also engaged professional experts to enhance the design
            & provide more features in the revamped website as well as help with its smooth running.
            As a result we have associated costs with it. And we seek your support in smooth running
            of this portal.
            <br />
            <br />
            Additionally, We also solicit your generous support for the education scholarship program run by
            JSPN where in needy students are provided financial support to pursue courses of their choice.
            Nearly 200+ students benefit from this scholarship every year.
            <br />
            <br />
            Donations to these worthy causes also come with {" "}
            <strong style={{ color: "maroon" }}>
              80G income tax income tax benefit 
            </strong>
            <br />
            <br />
            <br />
            Bank details are provided below:
            <br />
          </p>
          <div style={{ paddingLeft: 20 }}>
            <strong>
              Name : Jaya Satya Pramoda Nidhi
              <br />
              Bank - State Bank of India, Basavanagudi Branch, Bangalore-4
              <br />
              Account No : 10421685708
              <br />
              IFSC : SBIN0003357
            </strong>
            <br />
          </div>
          <br />
          You can also send your cheque or DD to the below address:
          <div style={{ paddingLeft: 20 }}>
            <strong>
              Jaya Satya Pramoda Nidhi,
              <br />
              C/o, Uttaradi Math, Jayateertha Vidya Peetha,
              <br />
              Shri Satyapramoda Road, Basavanagudi,
              <br />
              Bangalore - 560004
            </strong>
          </div>
          <br />
          By Payment Gateway<strong></strong>
          <div style={{ paddingLeft: 20 }}>
            <strong>
              Please click below to pay through <b>"Razor Pay"</b> Payment
              Gateway.
              <div
                className="razorpay-embed-btn"
                data-url="https://pages.razorpay.com/pl_F47ty0U2JO953b/view"
                data-text="Donate Now"
                data-color="#EBD52F"
                data-size="medium"
              >
                <iframe
                  src="https://cdn.razorpay.com/static/embed_btn/embed.html?url=https%3A%2F%2Fpages.razorpay.com%2Fpl_F47ty0U2JO953b%2Fview&text=Donate%20Now&color=%23EBD52F&size=medium&referrer=https%3A%2F%2Fsrijspn.org%2Fvvms%2Fdonate.action"
                  scrolling="no"
                  frameBorder={0}
                  width="210px"
                  height="100px"
                />
              </div>
            </strong>
          </div>
          <p />
          <br />
          <br />
          <br /> For more information on JSPN, visit <a href="https://www.srijspn.org">www.srijspn.org</a>
        </div>
      </Box>
    </Box>
  );
}
