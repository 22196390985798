import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileUploader, Button, Spinner } from "../../Components/UI";
import "./Registration.scss";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import { useFormik } from "formik";
import * as yup from "yup";
import { HorizontalLine } from ".";
import { connect } from "react-redux";
import {
  DeleteUserImage,
  GetImageUploadUrl,
  GetImageUrl,
  PostAdvanceDetails,
} from "../../Store/Actions/userActionCreator";
import { isMobileView, logout, showMessage } from "../../Utils/helper";

const validationSchema = yup.object({});

function ProfileForm({
  dispatchGetImageUploadUrl,
  location,
  dispatchPostAdvanceDetails,
  dispatchGetImageUrl,
  dispatchDeleteUserImage,
  ...props
}) {
  const [uploadUrlLinks, setUploadUrlLinks] = useState({});
  const [uploadedLinks, setUploadedLinks] = useState({});
  const [loading, toggleLoading] = useState(false);

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      props.history.push("/?rg=1");
    },
  });

  const fetchUploadedProfileImages = () => {
    toggleLoading(true);
    dispatchGetImageUrl(
      (resp) => {
        if (!!resp?.status) {
          setUploadedLinks(resp?.result ?? {});
        } else {
          showMessage(resp?.result || "", "error");
        }
        toggleLoading(false);
      },
      (err) => {
        toggleLoading(false);
        showMessage(err, "error");
      }
    );
  };

  useEffect(() => {
    toggleLoading(true);
    dispatchGetImageUploadUrl(
      (resp) => {
        if (!!resp?.status) {
          setUploadUrlLinks(resp?.result ?? []);
          fetchUploadedProfileImages();
        } else {
          showMessage(resp?.result || "", "error");
        }
        toggleLoading(false);
      },
      (err) => {
        showMessage(err, "error");
        toggleLoading(false);
      }
    );
  }, []);

  const profilePhotoRegex = /Profile/g;
  const isSubmitButtonDisabled = Object.keys(uploadedLinks || {})?.some((e) =>
    profilePhotoRegex.test(e)
  );
  return (
    <Box className="registration-baisc-form">
      <a
        href="https://wa.me/message/BN2R4ZFIMY4JM1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <Typography variant="h4" fontWeight={"bold"}>
        Almost there!
      </Typography>
      <Typography variant="h6" color={"#434343"} marginBottom={5}>
        Please upload your Profile Photo (mandatory) to finish the account
        setup. You can also upload Family Photo and Horoscope (Kundali) photo or
        any 2 other photos which you would like to share for better matches.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Spinner loading={loading}>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: isMobileView ? "column" : "row",
              flexWrap: "wrap",
              flexFlow: "wrap",
              minHeight: "50vh",
            }}
          >
            {[...Object.keys(uploadUrlLinks)]?.map((key, idx) => (
              <Box
                key={idx}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  flexFlow: "wrap",
                  width: isMobileView ? "100%" : "32%",
                  marginBottom: "16px",
                  marginRight: "10px",
                }}
              >
                <FileUploader
                  // label={'Upload Support Document'}
                  placeholder={`Upload ${key?.split("/")?.[1] ?? ""}`}
                  defaultValue={uploadedLinks[key]}
                  uploadUrl={uploadUrlLinks[key]}
                  onFileUploaded={(url) => {
                    fetchUploadedProfileImages();
                  }}
                  // onRemoved={() => {
                  //   const newList = { ...uploadedLinks };
                  //   delete newList[key];
                  //   setUploadedLinks(newList);
                  // }}
                  onRemoved={() => {
                    toggleLoading(true);
                    dispatchDeleteUserImage(
                      key.split("/")[1],
                      (resp) => {
                        const newList = { ...uploadedLinks };
                        delete newList[key];
                        setUploadedLinks(newList);
                        toggleLoading(false);
                      },
                      (err) => {
                        toggleLoading(true);
                      }
                    );
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box
            style={{
              position: isMobileView ? "relative" : "absolute",
              bottom: 0,
              width: "100%",
            }}
          >
            <HorizontalLine />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                size="large"
                type="submit"
                endIcon={
                  <ArrowForwardOutlinedIcon
                    htmlColor="#663399"
                    fontSize="small"
                  />
                }
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  color: "#663399",
                }}
                disabled={!isSubmitButtonDisabled}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Spinner>
      </form>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetImageUploadUrl: (onSuccess, onError) =>
    dispatch(GetImageUploadUrl(onSuccess, onError)),
  dispatchGetImageUrl: (onSuccess, onError) =>
    dispatch(GetImageUrl(onSuccess, onError)),
  dispatchPostAdvanceDetails: (payload, onSuccess, onError) =>
    dispatch(PostAdvanceDetails(payload, onSuccess, onError)),
  dispatchDeleteUserImage: (data, onSuccess, onError) =>
    dispatch(DeleteUserImage(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
