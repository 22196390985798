import {
  Box,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  HIGHEST_EDUCATION_ENUM,
  LOCATION_REGION,
  MOTHER_TONGUE_ENUM,
  PROFILES_UPDATED_SINCE_ENUM,
} from "../../../Utils/constants";
import { CustomTextField, StyledMenuItem, Button } from "../../UI";
import Modal from "../Modal";
import { PrettoSlider } from "../../UI/Slider";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const styles = {
  box: { width: "100%" },
};

function MatchesFilterModal({
  open,
  selectedFilters,
  applyFilters,
  onClose,
  ...props
}) {
  const [isOpen, toggleModal] = useState(open);
  const [loading, toggleLoading] = useState(false);
  const { palette } = useTheme();
  const [state, setState] = useState({ ...selectedFilters });
  const handleClose = () => {
    // Setting form data to null before closing

    toggleModal(!isOpen);
    onClose && onClose();
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [educationFilter, setEducationFilter] = useState([]);

  const handleChange = (event) => {
    setState({
      ...state,
      languagelist: event.target.value?.join(","),
    });
  };

  const handleRegionChange = (event) => {
    setState({
      ...state,
      regionlist: event.target.value?.join(","),
    });
  };

  const actions = [];
  const handleInputChange = (value, key) => {
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const checkedStatus = (language) => {
    if (state.languagelist) {
      if (state.languagelist.split(",").indexOf(language) > -1) return true;
    }
    return false;
  };

  const checkedRegionStatus = (regionlist) => {
    if (state.regionlist) {
      if (state.regionlist.split(",").indexOf(regionlist) > -1) return true;
    }
    return false;
  };

  const educationChecked = (item) => {
    if (state.higherqualificationlist) {
      if (state.higherqualificationlist.split(",").indexOf(item) > -1)
        return true;
    }
    return false;
  };

  const handleEducationCheckChange = (event) => {
    let newEducationFilter = { ...educationFilter };
    if (!!event.target.checked)
      newEducationFilter = {
        ...newEducationFilter,
        [event.target.name]: event.target.checked,
      };
    else {
      delete newEducationFilter[event.target.name];
    }
    let higherqualificationlist = Object.keys(newEducationFilter);
    setEducationFilter(newEducationFilter);
    setState({
      ...state,
      higherqualificationlist: higherqualificationlist?.join(","),
    });
  };
  const isMobileView = window.innerWidth < 600;
  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      title={
        <>
          <div
            style={{
              textAlign: "center",
              width: "100%",
              fontWeight: "bolder",
              fontFamily: "Roboto",
            }}
          >
            ADD FILTERS TO YOUR SEARCH
          </div>
        </>
      }
      headStyle={{
        backgroundColor: "rgba(255,185,85, 0.59)",
      }}
      actions={actions}
      loading={loading}
      isCustomTitle={true}
      maxWidth="md"
    >
      <Box style={{ ...styles.box, paddingTop: "16px" }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          Profiles Updated since
        </Typography>
        <CustomTextField
          label={
            !state?.profileupdatedsinceindays ? "Profiles Updated since" : ""
          }
          select
          fullWidth={true}
          value={
            state?.profileupdatedsinceindays
              ? state?.profileupdatedsinceindays
              : "All Profiles"
          }
          onChange={(e) =>
            handleInputChange(
              e.target.value === "All Profiles" ? "" : e.target.value,
              "profileupdatedsinceindays"
            )
          }
          defaultValue=""
          style={{ marginBottom: "16px" }}
          isRequired={true}
        >
          {Object.keys(PROFILES_UPDATED_SINCE_ENUM).map((value) => {
            return (
              <StyledMenuItem value={value}>
                {PROFILES_UPDATED_SINCE_ENUM?.[value] ?? ""}
              </StyledMenuItem>
            );
          })}
          <StyledMenuItem value={"All Profiles"}>All Profiles</StyledMenuItem>
        </CustomTextField>
      </Box>
      <Box style={styles.box}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          Age Gap Less than (in years)
        </Typography>
        <Box sx={{ mx: "16px" }}>
          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            max={10}
            min={1}
            value={state?.agegap ? state?.agegap / 366 : 10}
            valueLabelFormat={(val) => `${val}`}
            onChange={(event, newValue) => {
              handleInputChange(newValue * 366, "agegap");
            }}
          />
        </Box>
      </Box>
      <Box style={styles.box}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          Guna Greater Than
        </Typography>
        <Box sx={{ mx: "16px" }}>
          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            max={36}
            min={18}
            value={state?.guna ?? 8}
            valueLabelFormat={(val) => `>${val}`}
            onChange={(event, newValue) => {
              handleInputChange(newValue, "guna");
            }}
          />
        </Box>
      </Box>
      {/* <Box style={styles.box}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          Education *
        </Typography>
        <Box
          style={{
            border: "1px solid rgba(102,51,153,0.3)",
            padding: 20,
            borderRadius: 16,
          }}
        >
          <FormGroup>
            {[
              "Any",
              "Bachelors - Engineering / Others",
              "Masters - Engineering / Others",
              "Bachelors - Medicine - General / Surgeon /Others",
              "Masters - Medicine - General / Surgeon /Others",
            ].map((item) => {
              return (
                <FormControlLabel
                  control={<Checkbox sx={{}} name={item} />}
                  label={item}
                />
              );
            })}
          </FormGroup>
        </Box>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: isMobileView ? "column" : "row",
          paddingTop: "16px",
        }}
      >
        <Box style={styles.box}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            Higher Qualification
          </Typography>
          <Box
            style={{
              border: "1px solid rgba(102,51,153,0.3)",
              padding: 20,
              borderRadius: 16,
              maxHeight: 430,
              overflow: "auto",
            }}
          >
            <FormGroup>
              {Object.keys(HIGHEST_EDUCATION_ENUM).map((item) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={educationChecked(item)}
                        onChange={handleEducationCheckChange}
                        name={item}
                      />
                    }
                    label={[item]}
                  />
                );
              })}
            </FormGroup>
          </Box>
        </Box>
        <Box sx={{ padding: isMobileView ? 2 : 5 }} />
        <Box style={styles.box}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            Current location
          </Typography>
          <div>
            <FormControl sx={{ m: 1, width: 350 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Current location
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={state.regionlist ? state.regionlist.split(",") : []}
                onChange={handleRegionChange}
                input={<OutlinedInput label="Current location" />}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                {Object.keys(LOCATION_REGION).map((region) => (
                  <MenuItem key={region} value={region}>
                    <Checkbox
                      name={region}
                      checked={checkedRegionStatus(region)}
                    />
                    <ListItemText primary={region} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            Mother Tongue
          </Typography>
          <div>
            <FormControl sx={{ m: 1, width: 350 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Mother Tongue
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={state.languagelist ? state.languagelist.split(",") : []}
                onChange={handleChange}
                input={<OutlinedInput label="Mother Tongue" />}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                {Object.keys(MOTHER_TONGUE_ENUM).map((language) => (
                  <MenuItem key={language} value={language}>
                    <Checkbox
                      name={language}
                      checked={checkedStatus(language)}
                    />
                    <ListItemText primary={language} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            Other Filters
          </Typography>
          <Box
            style={{
              border: "1px solid rgba(102,51,153,0.3)",
              padding: 20,
              borderRadius: 16,
            }}
          >
            <FormGroup
              onChange={(e) => {
                handleInputChange(e.target.checked, e.target.name);
              }}
            >
              {[
                {
                  label: "Height Gap - Vara is taller than Vadhu",
                  name: "heightgap",
                },
                {
                  label: "Dont list profiles with Eknadi Dosha",
                  name: "excludeeknadi",
                },
                {
                  label: "Similar Education as mine",
                  name: "similareducation",
                },
                {
                  label: "Exclude Contacted or Invited matches",
                  name: "excludecontacted",
                },
                {
                  label: "Shortlisted Profiles",
                  name: "isfavourite",
                },
                {
                  label: "Blocked Profiles",
                  name: "isblocked",
                },
              ].map((item) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{}}
                        name={item?.name}
                        checked={state?.[item?.name]}
                      />
                    }
                    label={item?.label}
                  />
                );
              })}
            </FormGroup>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#FFB955",
          color: palette.secondary.main,
          fontWeight: "bolder",
          width: "100%",
          margin: "16px 0",
          "&:hover": {
            backgroundColor: palette.secondary.main,
            borderColor: palette.secondary.main,
            color: "white",
          },
        }}
        disableElevation={true}
        onClick={(e) => {
          !!applyFilters &&
            applyFilters({ ...selectedFilters, ...state, page: 1 });
        }}
      >
        FIND MY MATCHES
      </Button>
      <Button
        variant="outlined"
        sx={{
          backgroundColor: "#fff",
          color: palette.secondary.main,
          width: "100%",
          borderColor: palette.secondary.main,
          "&:hover": {
            backgroundColor: palette.secondary.main,
            borderColor: palette.secondary.main,
            color: "white",
          },
        }}
        disableElevation={true}
        onClick={(e) => {
          !!applyFilters && applyFilters({});
        }}
      >
        CLEAR ALL FILTERS
      </Button>
    </Modal>
  );
}

export default MatchesFilterModal;
