/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  CheckRegisteredUser,
  SendMobileOtp,
  UpdateMobileNumber,
  VerifyMobileOtp,
  VerifyMobileUpdateOtp,
} from "../../../Store/Actions/userActionCreator";
import { Button, CustomTextField } from "../../UI";
import Modal from "../Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { withRouter } from "react-router-dom";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import { MobileOTP } from "../../../Assets/svg";
import { showMessage } from "../../../Utils/helper";

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: "flex",
    flex: "1 1 15%",
    // border: '1px solid black',
  },

  otpInputStyle: {
    width: "48px !important",
    height: "48px !important",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "#663399",
    color: "#663399",
    fontSize: "24px",
    fontWeight: 600,
  },
  error: {
    border: "1px solid red !important",
  },
}));

const validationSchema = yup.object({
  key: yup
    .string("Invalid contact no.")
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .length(10, "Mobile Number should 10 digit")
    .trim(),
  otp: yup
    .string()
    .length(4, "OTP should be 4 digit")
    .required("OTP cannot be empty"),
});

function MobileOtpVerificationModal({
  data,
  open,
  onClose,
  next,
  blockClose = true,
  dispatchSendMobileOtp,
  dispatchVerifyMobileOtp,
  dispatchUpdateMobileNumber,
  dispatchCheckRegisteredUser,
  dispatchVerifyMobileUpdateOtp,
  ...props
}) {
  const classes = useStyles();
  const [isOpen, toggleModal] = useState(open);
  const [loading, toggleLoading] = useState(false);
  const [contactNumber, setContactNumber] = useState(data?.mobileNumber ?? "");
  const [countDown, setCountDown] = useState(60);

  const [hasError, setHasError] = useState(false);
  let interval = null;

  const handleClose = () => {
    // Setting form data to null before closing

    toggleModal(!isOpen);
    onClose && onClose();
  };

  const otpHandler = (otp) => {
    formik.setFieldValue("otp", otp, true);
    if (otp.length !== 4) {
      setHasError(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
      key: data?.mobileNumber ?? null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      const payload = {
        ...values,
      };
      toggleLoading(true);
      if (!!data?.validateNewOTP) {
        dispatchVerifyMobileUpdateOtp(
          {
            ...payload,
          },
          (resp) => {
            if (!!resp?.status) {
              toggleLoading(false);
              showMessage(resp?.result, "success");
              !!next &&
                next({
                  ...values,
                });
            } else {
              toggleLoading(false);
              showMessage(resp?.result, "error");
            }
          },
          (err) => {
            toggleLoading(false);
            showMessage(err, "error");
          }
        );
      } else
        dispatchVerifyMobileOtp(
          payload,
          (resp) => {
            if (!!resp?.status) {
              toggleLoading(false);
              showMessage(resp?.result, "success");
              props?.history?.push("/register/basic");
            } else {
              toggleLoading(false);
              showMessage(resp?.result, "error");
            }
          },
          (err) => {
            toggleLoading(false);
            showMessage(err, "error");
          }
        );
    },
  });

  const SendOTP = (cb) => {
    toggleLoading(true);
    dispatchSendMobileOtp(
      {},
      (resp) => {
        if (resp.status) {
          showMessage(resp?.result, "success");
          !!cb && cb();
        } else {
          showMessage(resp?.result, "error");
        }
        toggleLoading(false);
      },
      (err) => {
        toggleLoading(false);
        showMessage(err, "error");
      }
    );
  };

  const resetOtpTimer = () => {
    setCountDown(60);
    interval = setInterval(() => {
      setCountDown((lastTimerCount) => {
        if (lastTimerCount <= 1) {
          clearInterval(interval);
        }
        return lastTimerCount - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    formik.setFieldValue("key", data?.mobileNumber ?? null);
    if (!!data?.mobileNumber && !data?.validateNewOTP) {
      SendOTP(() => {
        resetOtpTimer();
      });
    } else {
      resetOtpTimer();
    }
  }, []);

  const actions = [];
  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      blockClose={blockClose}
      title={
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            marginTop: "8px",
            // marginBottom: "24px",
            // fontFamily: "Montserrat",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          Mobile Verification
        </Typography>
      }
      isCustomTitle={true}
      actions={actions}
      loading={loading}
      isForm={true}
      onSubmit={formik.handleSubmit}
      maxWidth="sm"
    >
      <Box
        style={{
          // padding: "0 15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <MobileOTP style={{ width: "40%", height: "100%" }} />
        <Typography
          variant="body1"
          color={"#000000"}
          marginTop={"20px"}
          marginBottom={"20px"}
          textAlign="center"
        >
          {`Please enter the  OTP sent to your registered  Mobile ${contactNumber} to continue setting up your account`}
        </Typography>
        {!data?.validateNewOTP && (
          <CustomTextField
            label="10 digit Mobile Number"
            id="key"
            name="key"
            value={formik.values.key}
            onChange={formik.handleChange}
            onBlur={(e) => {
              if (formik.values.key?.length === 10)
                dispatchCheckRegisteredUser(
                  {
                    userId: formik.values.key,
                  },
                  (resp) => {
                    if (!resp?.status) {
                      formik.setFieldError(
                        "key",
                        "Mobile Number is already registered"
                      );
                    }
                  },
                  (err) => {
                    showMessage(err ?? "Server Error", "error");
                  }
                );
              formik.handleBlur(e);
            }}
            error={formik.touched.key ? Boolean(formik.errors.key) : false}
            helperText={formik.touched.key && formik.errors.key}
            style={{ marginBottom: "16px", width: "45%" }}
            isRequired={true}
            // variant={"standard"}
          />
        )}
        <Typography
          variant="body1"
          color={"#000000"}
          marginBottom={"5px"}
          marginLeft={"-30px"}
        >
          Enter the 4 digit OTP
        </Typography>
        {
          <OtpInput
            inputStyle={classes.otpInputStyle}
            numInputs={4}
            hasErrored={hasError}
            errorStyle={classes.error}
            onChange={otpHandler}
            separator={<span>&nbsp;</span>}
            isInputNum={true}
            shouldAutoFocus
            value={formik.values.otp}
            disabledStyle={{ borderColor: "#898686" }}
            isDisabled={formik.values.key !== contactNumber}
          />
        }

        {formik.values.key !== contactNumber &&
        formik?.values?.key?.length === 10 ? (
          <Button
            variant="contained"
            color="primary"
            style={{ width: "50%", marginTop: "70px", fontWeight: "bold" }}
            type="button"
            onClick={(e) => {
              toggleLoading(true);
              dispatchUpdateMobileNumber(
                {
                  mobileNumber: formik.values.key,
                },
                (resp) => {
                  if (resp.status) {
                    showMessage(resp?.result, "success");
                    SendOTP(() => {
                      resetOtpTimer();
                      setContactNumber(formik.values.key);
                    });
                  } else {
                    showMessage(resp?.result, "error");
                  }
                  toggleLoading(false);
                },
                (err) => {
                  showMessage(err, "error");
                  toggleLoading(false);
                }
              );
            }}
          >
            Update & Resend OTP
          </Button>
        ) : (
          <>
            <Typography
              variant="caption"
              color={"#898686"}
              marginTop={"1px"}
              marginBottom={"50px"}
            >
              {countDown !== 0
                ? `Resent OTP in ${countDown} seconds`
                : "Havent received yet? "}
              {countDown === 0 && (
                <Typography
                  variant="caption"
                  color={countDown === 0 ? "#5671FF" : "#898686"}
                  marginTop={"1px"}
                  marginBottom={"50px"}
                  style={{ cursor: countDown === 0 ? "pointer" : "" }}
                  onClick={(e) => {
                    if (countDown === 0)
                      SendOTP(() => {
                        clearInterval(interval);
                        resetOtpTimer();
                      });
                  }}
                >
                  Resend OTP
                </Typography>
              )}
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >

              {!!data?.validateNewOTP && (
                <Button
                  style={{
                    width: "40%",
                    fontWeight: 900,
                    color: "#000000",
                    borderColor: "#000000",
                  }}
                  variant="outlined"
                  type="button"
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                style={{ width: "40%", fontWeight: "bold", marginLeft: "1em", }}
                type="submit"
                disabled={formik.values.key !== contactNumber}
              >
                VERIFY
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSendMobileOtp: (payload, onSuccess, onError) =>
    dispatch(SendMobileOtp(payload, onSuccess, onError)),
  dispatchVerifyMobileOtp: (payload, onSuccess, onError) =>
    dispatch(VerifyMobileOtp(payload, onSuccess, onError)),
  dispatchUpdateMobileNumber: (payload, onSuccess, onError) =>
    dispatch(UpdateMobileNumber(payload, onSuccess, onError)),
  dispatchCheckRegisteredUser: (payload, onSuccess, onError) =>
    dispatch(CheckRegisteredUser(payload, onSuccess, onError)),
  dispatchVerifyMobileUpdateOtp: (payload, onSuccess, onError) =>
    dispatch(VerifyMobileUpdateOtp(payload, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MobileOtpVerificationModal));
