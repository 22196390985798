import React, { Component } from "react";
import Message from "./Messages";
import { Checkbox } from "@mui/material";

class MessageList extends Component {
  render() {
    const { showDelete, onDeleteRowSelected, selectedDeleteRow } = this.props;
    return (
      <div /*ref={el => this.scrollList = el}*/>
        {this.props.messages?.reverse().map((message, i) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                opacity: message?.id === 0 ? 0.5 : 1,
              }}
            >
              <Message message={message} key={i} />
              {!!showDelete && !!message.loggedInUser && (
                <Checkbox
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    let newRows = !isChecked
                      ? selectedDeleteRow?.filter((id) => id !== message?.id)
                      : [...selectedDeleteRow, message?.id];
                    onDeleteRowSelected(newRows);
                  }}
                  {...{ inputProps: { "aria-label": "Checkbox demo" } }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default MessageList;
