import React, { useEffect, useState } from "react";
import "./styles.scss";
import { UploadIcon } from "../../../Assets/svg";
// import {
//   AWS_BUCKET_REGION,
//   AWS_BUCKET_POOL,
//   AWS_BUCKET_NAME,
//   AWS_BUCKET_ACL,
// } from "../../../config/env/env";
import { Spinner } from "../index";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { LightBoxImage } from "../index";
import { FormHelperText, Typography, Box } from "@mui/material";
import axios from "axios";
import imageCompression from 'browser-image-compression'
// const IMAGE_FILES = ['.jpg', '.png', '.jpeg', '.bmp'];
const NON_IMAGE_FILES = [".xlsx", ".csv", ".pdf", ".html"];

export function FileUploader({
  render,
  label,
  labelStyles,
  placeholder,
  uploadUrl,
  onFileUploaded,
  defaultValue,
  helperText,
  disabled,
  error,
  onRemoved,
  lightboxConfig = {},
  isRequired,
  ...props
}) {
  const [uploadedFile, setuploadedFile] = useState(defaultValue ?? "");
  const [loading, toggleLoading] = useState(false);

  useEffect(() => {
    setuploadedFile(defaultValue);
  }, [defaultValue]);

  const uploadfile = async (e, uploadTo) => {
    await toggleLoading(true);
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    const compressedFile = await imageCompression(imageFile, options);
    delete axios.defaults.headers.common["Authorization"];
    axios
      .put(uploadTo, compressedFile, {
        headers: {
          "Content-Type": "image/png",
        },
        onUploadProgress: (ProgressEvent) => {
        },
      })
      .then((res) => {
        toggleLoading(false);
        onFileUploaded("");
      })
      .catch((err) => {
        toggleLoading(false);
      });
  };

  const removeimage = () => {
    setuploadedFile("");
    onRemoved && onRemoved();
  };

  function getFileNameFromS3() {
    return uploadedFile.split("/")[uploadedFile.split("/").length - 1];
  }

  return (
    <>
      {!!label && (
        <Typography
          variant="body1"
          style={
            labelStyles
              ? {
                  fontSize: "14px",
                  fontWeight: "normal",
                  marginTop: 10,
                  marginBottom: 0,
                  padding: 0,
                  color: Boolean(error) ? "#F44336" : "#14112D",
                  ...labelStyles,
                }
              : {
                  fontSize: "14px",
                  fontWeight: "normal",
                  marginTop: 10,
                  marginBottom: 0,
                  padding: 0,
                  color: Boolean(error) ? "#F44336" : "#515f6d",
                }
          }
        >
          {label}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
      )}
      <Spinner loading={loading}>
        <div className="file-upload">
          {!!uploadedFile ? (
            <div
              className={`image-upload-wrap ${
                Boolean(error) ? "img-border-error" : ""
              }`}
            >
              {!disabled && (
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    position: "absolute",
                    top: -10,
                    right: -10,
                    zIndex: 99,
                    color: "#fff",
                    width: 25,
                    height: 25,
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => removeimage()}
                >
                  {"X"}
                </div>
              )}
              {/* <div className="drag-text"> */}
              {!!uploadedFile &&
              !NON_IMAGE_FILES.find((file) =>
                uploadedFile.toLowerCase().includes(file)
              ) ? (
                <div className="drag-text image-drag">
                  <LightBoxImage {...lightboxConfig} source={uploadedFile} />
                </div>
              ) : (
                <div className="drag-text">
                  <AttachFileIcon />
                  {
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      {/* <AttachFileIcon /> */}
                      <Typography
                        // variant="body1"
                        style={{
                          color: "red",
                          marginLeft: "2px",
                          width: "100%",
                          wordWrap: "break-word",
                        }}
                      >
                        {/* {`${uploadedFile}`} */}
                        {`${getFileNameFromS3()}`}
                      </Typography>
                    </Box>
                  }
                </div>
              )}
              {/* </div> */}
            </div>
          ) : !render ? (
            <div
              className={`image-upload-wrap ${
                Boolean(error) ? "img-border-error" : ""
              }`}
            >
              <input
                className="file-upload-input"
                type="file"
                onChange={(e) => uploadfile(e, uploadUrl)}
                accept="image/*"
              />
              <div className="drag-text">
                <UploadIcon />
                {!!placeholder && (
                  <Typography variant="body1">{placeholder}</Typography>
                )}
              </div>
            </div>
          ) : (
            <div className={` ${Boolean(error) ? "img-border-error" : ""}`}>
              <input
                className="file-upload-input"
                type="file"
                onChange={(e) => uploadfile(e, uploadUrl)}
              />
              {render()}
            </div>
          )}
        </div>
        {Boolean(error) && (
          <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
            {helperText}
          </FormHelperText>
        )}
      </Spinner>
    </>
  );
}
