/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  SendAccountOtp,
  UpdatePassword,
} from "../../../Store/Actions/userActionCreator";
import { CustomTextField, Button } from "../../UI";
import Modal from "../Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { withRouter } from "react-router-dom";
import { showMessage } from "../../../Utils/helper";
import { MobileOTP, OTPVerificationImage } from "../../../Assets/svg";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import "./style.scss";
const useStyles = makeStyles((theme) => ({
  topBar: {
    display: "flex",
    flex: "1 1 15%",
    // border: '1px solid black',
  },

  otpInputStyle: {
    width: "48px !important",
    height: "48px !important",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "#663399",
    color: "#663399",
    fontSize: "24px",
    fontWeight: 600,
  },
  error: {
    border: "1px solid red !important",
  },
}));

const validationSchema = yup.object({
  userId: yup.string().when("isEmail", {
    is: "1",
    then: yup
      .string()
      .email("Please enter valid email")
      .required("Email / Phone Number cannot be empty"),
    otherwise: yup
      .string()
      .required("Email / Phone Number cannot be empty")
      .length(10, "Phone Number must be 10 char"),
  }),
  otp: yup
    .string()
    .length(4, "OTP should be 4 digit")
    .required("OTP cannot be empty"),
  password: yup
    .string()
    .test("password", "Both password need to be the same", function (value) {
      return this.parent.cpassword === value;
    })
    .nullable()
    .required("Password cannot be empty"),
  cpassword: yup
    .string()
    .test("cpassword", "Both password need to be the same", function (value) {
      return this.parent.password === value;
    })
    .nullable()
    .required("Confirm password cannot be empty"),
});

function ResetPasswordModal({
  data,
  open,
  onClose,
  next,
  dispatchUpdatePassword,
  dispatchSendAccountOtp,
  ...props
}) {
  const classes = useStyles();
  const [isOpen, toggleModal] = useState(open);
  const [loading, toggleLoading] = useState(false);
  const [showOtpView, setOtpView] = useState(false);
  const [countDown, setCountDown] = useState(60);
  let interval = null;

  const handleClose = () => {
    // Setting form data to null before closing

    toggleModal(!isOpen);
    onClose && onClose();
  };

  const otpHandler = (otp) => {
    formik.setFieldValue("otp", otp, true);
    if (otp.length !== 4) {
      formik.setFieldError("otp", "OTP must be in 4 Digit");
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
      userId: data?.emailId ?? "",
      password: "",
      cpassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      toggleLoading(true);
      dispatchUpdatePassword(
        {
          ...values,
        },
        (resp) => {
          if (!!resp?.status) {
            toggleLoading(false);
            showMessage(resp?.result, "success");
            !!next && next();
          } else {
            toggleLoading(false);
            showMessage(resp?.result, "error");
          }
        }
      );
    },
  });

  const resetOtpTimer = () => {
    setCountDown(60);
    interval = setInterval(() => {
      setCountDown((lastTimerCount) => {
        if (lastTimerCount <= 1) {
          clearInterval(interval);
        }
        return lastTimerCount - 1;
      });
    }, 1000);
  };

  const resendOtp = () => {
    toggleLoading(true);
    dispatchSendAccountOtp(
      { userId: data?.emailId },
      (resp) => {
        toggleLoading(false);
        resetOtpTimer();
        showMessage("OTP Sent!", "success");
      },
      (err) => {
        toggleLoading(false);
        // showMessage(err, "error");
      }
    );
  };

  useEffect(() => {
  }, [formik.values]);

  useEffect(() => {
    resetOtpTimer();
    if (!!data?.emailId) {
      formik.setFieldValue("isEmail", !!Number(data?.emailId) ? "0" : "1");
    }
    formik.validateForm();
  }, []);

  const actions = [];
  return (
    <>
      <Modal
        onClose={handleClose}
        open={isOpen}
        title={""}
        isCustomTitle={true}
        actions={actions}
        loading={loading}
        isForm={true}
        onSubmit={formik.handleSubmit}
        maxWidth="sm"
      >
        {showOtpView ? (
          <Box
            style={{
              // padding: "0 15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              marginBottom={"20px"}
              fontWeight="bold"
              color={"#000"}
            >
              OTP Verification
            </Typography>
            <OTPVerificationImage style={{ width: "40%", height: "100%" }} />
            <Typography
              variant="body1"
              color={"#000000"}
              marginTop={"20px"}
              marginBottom={"40px"}
              textAlign="center"
              width={"85%"}
            >
              {`Please enter the OTP sent to your registered Email/Mobile ${data?.emailId} to
          continue setting up your account`}
            </Typography>
            <Typography
              variant="body1"
              color={"#000000"}
              marginBottom={"15px"}
              marginLeft={"-30px"}
            >
              Enter the 4 digit OTP
            </Typography>
            <OtpInput
              inputStyle={classes.otpInputStyle}
              numInputs={4}
              hasErrored={formik.errors.otp}
              errorStyle={classes.error}
              onChange={otpHandler}
              separator={<span>&nbsp;</span>}
              isInputNum={true}
              shouldAutoFocus
              value={formik.values.otp}
            />
            <Typography
              variant="caption"
              color={"#898686"}
              marginTop={"1px"}
              marginBottom={"50px"}
            >
              {countDown !== 0
                ? `Remaining time ${countDown}s `
                : "Havent received yet? "}
              <Typography
                variant="caption"
                color={countDown === 0 ? "#5671FF" : "#898686"}
                marginTop={"1px"}
                marginBottom={"50px"}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  resendOtp();
                }}
              >
                Resend OTP
              </Typography>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "40%", fontWeight: "bold" }}
              type="button"
              onClick={async () => {
                formik.validateForm().then((err) => {
                  if (!err.otp) {
                    setOtpView(false);
                  } else {
                    showMessage(err?.otp, "error");
                  }
                });
                // dispatchVerifyForgetPwOtp(
                //   {
                //     key: formik?.values?.userId,
                //     otp: formik?.values?.otp,
                //   },
                //   (resp) => {
                //     if (resp?.status) {
                //       setOtpView(false);
                //     } else {
                //       showMessage(resp?.result, "error");
                //     }
                //   },
                //   (err) => {}
                // );
              }}
            >
              VERIFY
            </Button>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              marginBottom={"20px"}
              fontWeight="bold"
              color={"#000"}
              textAlign="center"
              width={"100%"}
            >
              Reset Password
            </Typography>
            <Box className="mainbox">
              <MobileOTP className="forgetimg" />
              <Box className="modelforum">
                {/* <OtpInput
                  inputStyle={classes.otpInputStyle}
                  numInputs={4}
                  hasErrored={formik.errors.otp}
                  errorStyle={classes.error}
                  onChange={otpHandler}
                  separator={<span>&nbsp;</span>}
                  isInputNum={true}
                  value={formik.values.otp}
                /> */}
                <CustomTextField
                  fullWidth={true}
                  label="Enter OTP"
                  id="otp"
                  name="otp"
                  InputProps={{
                    type: "number",
                  }}
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.otp ? Boolean(formik.errors.otp) : false
                  }
                  helperText={formik.touched.otp && formik.errors.otp}
                  style={{ width: "100%" }}
                />
                <Typography
                  variant="caption"
                  color={"#898686"}
                  marginTop={"4px"}
                  marginBottom={"16px"}
                >
                  {countDown !== 0
                    ? `Remaining time ${countDown}s `
                    : "Havent received yet? "}
                  <Typography
                    variant="caption"
                    color={countDown === 0 ? "#5671FF" : "#898686"}
                    marginTop={"1px"}
                    marginBottom={"50px"}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      resendOtp();
                    }}
                  >
                    Resend OTP
                  </Typography>
                </Typography>
                <CustomTextField
                  fullWidth={true}
                  label="Enter Password"
                  id="password"
                  name="password"
                  InputProps={{
                    type: "password",
                  }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password
                      ? Boolean(formik.errors.password)
                      : false
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  style={{ marginBottom: "16px", width: "100%" }}
                />
                <CustomTextField
                  fullWidth={true}
                  label="Confirm Password"
                  id="cpassword"
                  name="cpassword"
                  InputProps={{
                    type: "password",
                  }}
                  value={formik.values.cpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.cpassword
                      ? Boolean(formik.errors.cpassword)
                      : false
                  }
                  helperText={
                    formik.touched.cpassword && formik.errors.cpassword
                  }
                  style={{ marginBottom: "16px", width: "100%" }}
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              color="primary"
              style={{
                fontWeight: "bold",
                marginTop: "1em",
              }}
              className="responsive_width"
              type="submit"
              disabled={!formik.isValid}
            >
              UPDATE PASSWORD
            </Button>
            <Button
              color="primary"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
              }}
              sx={{
                marginTop: "unset",
              }}
              className="responsive_width"
              onClick={() => {
                handleClose();
              }}
              type="button"
            >
              Back
            </Button>
          </Box>
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdatePassword: (payload, onSuccess, onError) =>
    dispatch(UpdatePassword(payload, onSuccess, onError)),
  dispatchSendAccountOtp: (payload, onSuccess, onError) =>
    dispatch(SendAccountOtp(payload, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPasswordModal));
