import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function VVSPrime(props) {
  const isMobileView = window.innerWidth < 600;
  return (
    <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Tooltip title="GO BACK">
          <IconButton aria-label="delete" sx={{ mr: "16px" }} onClick={e => {
            props?.history?.goBack()
          }}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>

        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color={"#000000"}
          fontWeight="bold"
          textAlign={"left"}
        >
          What is V V S PRIME?
        </Typography>
      </Box>
      <Divider
        sx={{
          borderColor: "rgba(102, 51, 153, 0.3)",
          my: "16px",
          mb: "30px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: isMobileView ? "100%" : "80%",
          flexDirection: isMobileView ? "column" : "row",
          color: "#000",
        }}
      >
        <div>
          <ul
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <li>
              VVS PRIME is a premium membership to the VVS platform that enables INSTANT ACCESS to contact information of prospective brides & grooms.
              The membership is valid for 6 months & can be renewed.
            </li>
            <br />
            <li>
              VVS PRIME gives you instant access to all contacts as soon as you express your
              interest in ‘MY MATCHES’. Go to "MY INBOX" and for all the sent Invites "View Contact" button will be enabled.
              You would not need to wait till the interest expressed by you is accepted by the other party to get contact details which is the case with non VVS PRIME users.
            </li>
            <br />
            <li>
              Matches generated for a PRIME user and non-PRIME users will be exactly same. Only difference will be the instant access to contacts as soon as you express your interest.
            </li>
            <br />
            <li>
              VVS PRIME membership is not for sale but is provided as a complimentary feature to all registered users who make donations of Rs.500 and above.
              The feature gets re-enabled if a person donates again post expiry.
            </li>
            <br />
            <li>
              The donations collected from VVS PRIME are used exclusively for social service activities of JSPN.
              Through the VVS PRIME membership, you can contribute to the society while finding your perfect match.
            </li>
            <br />
            <li>
              VVS PRIME membership can be enabled Only for registered users. Please check "MY ACCOUNT" section to donate and become a VVS PRIME member.

            </li>
            <br />
            <li>
              VVS PRIME membership cannot be cancelled or the donation refunded during the membership period.

            </li>         <br />
            <li>
              For any queries on VVS PRIME please email us at jspn.vvs@umapps.in

            </li>
            <br />

            <br />
          </ul>
          <hr />
        </div>
      </Box>
    </Box>
  );
}
