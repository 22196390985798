import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Modal from "../Modal";
import { withRouter } from "react-router-dom";
import { RegistrationSuccesImage } from "../../../Assets/svg";
import { Button } from "../../UI";

function RegistrationSuccessModal({ open, onClose, ...props }) {
  const [isOpen, toggleModal] = useState(open);

  const handleClose = () => {
    // Setting form data to null before closing

    toggleModal(!isOpen);
    onClose && onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      blockClose={true}
      open={isOpen}
      keepMounted
      actions={[]}
      isCustomTitle={true}
      maxWidth="md"
    >
      <Box
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <RegistrationSuccesImage style={{ width: "30%", height: "70%" }} />
        <Typography
          style={{
            color: "#000000",
            fontWeight: "bold",
            fontSize: 22,
            marginTop: 30,
          }}
        >
          Registration Successful!
        </Typography>
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 22,
            marginTop: 18,
          }}
          align="center"
        >
          Welcome to Vadhu Vara Samyojana By JSPN
        </Typography>
        <Typography
          style={{
            color: "#000000",
            fontWeight: "normal",
            fontSize: 14,
            marginTop: 10,
            textAlign: "center",
            width: "75%",
          }}
        >
          Search through a list of verified Madhwa Community profiles to find your soul
          partner. Lets start matching and get your dream Vadhu/Vara!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "50%", fontWeight: "bold", marginTop: 30 }}
          onClick={(e) => {
            props.history.push("/my-matches");
          }}
        >
          VIEW MY MATCHES
        </Button>
      </Box>
    </Modal>
  );
}

export default withRouter(RegistrationSuccessModal);
