import "./styles.scss";
import RouteWithTitle from "../../Components/Navigation/AppRoute/RouteWithTitle";
import { withRouter } from "react-router-dom";
import { Notifier } from "../../Components/UI";
import React, { Component } from "react";
import ScrollToTop from "../../Utils/ScrollToTop";

class BlankLayout extends Component {
  render() {
    const { path, component, name } = this.props;
    return (
      <div className="blank-layout">
        <div className="container">
          {path && component && (
            <RouteWithTitle
              key={name}
              exact={true}
              path={path}
              component={component}
              name={name}
            />
          )}
        </div>
        <Notifier />
        <ScrollToTop />
      </div>
    );
  }
}

export default withRouter(BlankLayout);
