import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  login,
  forgotPasswordOTP,
} from "../../../Store/Actions/userActionCreator";
import { CustomTextField, Button } from "../../UI";
import Modal from "../Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { withRouter } from "react-router-dom";
import { ForgetPasswordImage } from "../../../Assets/svg";
import ResetPasswordModal from "./ResetPasswordModal";
import "./style.scss";
import { showMessage } from "../../../Utils/helper";

// const validationSchema = yup.object({
//   userId: yup
//     .string("Please enter valid email")
//     .when("userId", {
//       is: (userId) => !!Number(userId),
//       then: yup
//         .string("Please enter valid email")
//         .email("Please enter valid email"),
//       otherwise: yup.number("Please enter valid email"),
//     })
//     .trim()
//     .required("Email/Mobile Number cannot be empty"),
// });

const validationSchema = yup.lazy((values) => {
  return yup.object({
    userId: yup.string().when("isEmail", {
      is: "1",
      then: yup
        .string()
        .email("Please enter valid email")
        .required("Email / Phone Number cannot be empty"),
      otherwise: yup
        .string()
        .length(10, "Phone Number must be 10 char")
        .required("Email / Phone Number cannot be empty"),
    }),
  });
});

function ForgetPasswordModal({
  open,
  onClose,
  dispatchForgotPasswordOTP,
  ...props
}) {
  const [isOpen, toggleModal] = useState(open);
  const [loading, toggleLoading] = useState(false);
  const [openEmailOtpModal, setOpenEmailOtpModal] = useState(false);
  const handleClose = () => {
    // Setting form data to null before closing

    toggleModal(!isOpen);
    onClose && onClose();
  };

  const formik = useFormik({
    initialValues: {
      userId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      toggleLoading(true);
      dispatchForgotPasswordOTP(
        { userId: values.userId },
        (resp) => {
          if (!!resp?.status) {
            toggleLoading(false);
            setOpenEmailOtpModal(true);
            toggleModal(!isOpen);
          } else {
            showMessage(resp?.result, "error");
            toggleLoading(false);
          }
        },
        (err) => {
          toggleLoading(false);
          handleClose();
          // showMessage(err, "error");
        }
      );
    },
  });

  const actions = [];
  return (
    <>
      <Modal
        onClose={handleClose}
        open={isOpen}
        title={""}
        actions={actions}
        loading={loading}
        isForm={true}
        onSubmit={formik.handleSubmit}
        maxWidth="md"
        className="forgotmodelcss"
      >
        <Box className="mainbox">
          <ForgetPasswordImage className="forgetimg" />
          <Box className="modelforum">
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                marginTop: "8px",
                marginBottom: "24px",
                // fontFamily: "Montserrat",
                fontWeight: "bold",
              }}
            >
              Forgot Password?
            </Typography>
            <Typography
              //   variant="h6"
              style={{
                marginTop: "8px",
                marginBottom: "24px",
                // fontFamily: "Montserrat",
                color: "#000000",
              }}
            >
              Please enter the Email address/Mobile Number associated with the
              account
            </Typography>
            <Typography fontSize={"0.8em"} color={"red"} marginBottom={"10px"}>
              For Email OTP, Please check the Spam/Promotions folder.
            </Typography>
            <CustomTextField
              fullWidth={true}
              label="Enter E-Mail/Mobile Number"
              id="userId"
              name="userId"
              value={formik.values.userId}
              // onChange={formik.handleChange}
              onChange={(event) => {
                formik.handleChange("userId")(event);
                if (Number(event.target.value)) {
                  formik.handleChange("isEmail")("0");
                } else {
                  formik.handleChange("isEmail")("1");
                }
              }}
              error={
                formik.touched.userId ? Boolean(formik.errors.userId) : false
              }
              helperText={formik.touched.userId && formik.errors.userId}
              style={{ marginBottom: "16px" }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{
                fontWeight: "bold",
              }}
              type="submit"
            >
              SEND OTP
            </Button>
          </Box>
        </Box>
      </Modal>
      {!!openEmailOtpModal && (
        <ResetPasswordModal
          open={openEmailOtpModal}
          data={{ emailId: formik?.values?.userId, validateViaOTP: true }}
          onClose={() => {
            setOpenEmailOtpModal(false);
          }}
          next={() => {
            setOpenEmailOtpModal(false);
            handleClose();
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (payload, onSuccess, onError) =>
    dispatch(login(payload, onSuccess, onError)),
  dispatchForgotPasswordOTP: (payload, onSuccess, onError) =>
    dispatch(forgotPasswordOTP(payload, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgetPasswordModal));
