import { Box, Typography } from "@mui/material";
import React from "react";
import { EMPTY_VALUE } from "../../../Utils/constants";

export default function KeyValueInfo({ label, value, ...props }) {
  const isMobileView = window.innerWidth < 600;
  return (
    <Box
      sx={{
        ml: isMobileView ? 0 : 10,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: isMobileView ? "column" : "row",
        width: "calc(100% - 10px)",
        marginLeft: 0,
      }}
    >
      <Typography
        variant="caption"
        color="#707070"
        sx={{ width: isMobileView ? "100%" : "25%", fontSize: 16 }}
      >
        {label ?? ""}
      </Typography>

      {!isMobileView && <Typography
        variant="caption"
        color="#000000"
        sx={{ fontWeight: "bolder", fontSize: 16, mx: 5 }}
      >
        {" - "}
      </Typography>}
      <Typography
        variant="caption"
        color="#000000"
        sx={{
          width: isMobileView ? "100%" : "70%",
          fontWeight: "bolder",
          fontSize: 16,
          wordBreak: "break-word"
        }}
      >
        {!!value ? value : EMPTY_VALUE}
      </Typography>
      {!!isMobileView  && <br />}
    </Box>
  );
}
