import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function CancellationPolicy(props) {
  const isMobileView = window.innerWidth < 600;
  return (
    <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Tooltip title="GO BACK">
          <IconButton aria-label="delete" sx={{ mr: "16px" }} onClick={e => {
            props?.history?.goBack()
          }}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>

        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color={"#000000"}
          fontWeight="bold"
          textAlign={"left"}
        >
          Cancellation Policy : Updated on 25-Oct-2022
        </Typography>
      </Box>
      <Divider
        sx={{
          borderColor: "rgba(102, 51, 153, 0.3)",
          my: "16px",
          mb: "30px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: isMobileView ? "100%" : "80%",
          flexDirection: isMobileView ? "column" : "row",
          color: "#000",
        }}
      >
        <div>
          <ol
            style={{ textAlign: "justify", paddingLeft: 20, paddingRight: 20 }}
          >
            <li>
              JSPN currently accepts donations through its Online Payment Gateway Facility for various charitable / social purposes.
              Any payment made multiple times due to error, or any other kind of erroneous payment may be brought to the notice
              of the Site officials and the payment would be refunded if the Site official is satisfied with the cause and/or reason of error by the payee.
            </li>
            <br />
            <li>
              All donations and/or payment made through the Site, the acceptance of the same is at the sole discretion of the JSPN management.
              The management reserves its sole rights to accept or cancel any donation and/or payment without assigning any reason thereof.  <br />
            </li>
            <br />
            <li>

              Please note that donations once made cannot be charged back/returned, except in cases of fraudulent use,
              once investigation is carried out by necessary Government / Bank authorities and proofs substantiated.
              In case any donation receipt is issued against the same, that shall stand cancelled,
              and any income tax benefit so derived shall be null and void.
            </li>
            <br />
            <li>

              Please write to jspn.vvs@umapps.in for any queries.
            </li>
            <br />

            <br />
          </ol>
        </div>
      </Box>
    </Box>
  );
}
