/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./MyMatches.scss";
import {
  Box,
  Container,
  Grid,
  Button,
  TextField,
  FormGroup,
  Stack,
  Pagination,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import { Close } from "@mui/icons-material";
import UserProfileCard from "../../Components/Cards/UserProfileCard";
import { connect } from "react-redux";
import {
  fetchMyMatches,
  fetchUserStats,
} from "../../Store/Actions/matchesActionCreator";
import FilterModal from "../../Components/Modals/ModalContexts/MatchesFilterModal";
import { EmptyImage } from "../../Assets/svg";
import { NotificationCount } from "../../Store/Actions/systemActionCreator";
// import { FilteredState } from "./FilterState";
import { fetchChatHistory } from "../../Store/Actions/conversationActionCreator";

function MyMatches({
  user,
  dispatchFetchUserStatsData,
  dispatchFetchMyMatches,
  dispatchNotificationCount,
  dispatchFetchChatHistory,
  ...props
}) {
  // const { height, width } = useWindowDimensions();
  const [sortBy, setSortBy] = React.useState(
    props?.location?.state?.filterInfo?.sortby ?? "logintime"
  );
  const filterInfo = {
    ...(props?.location?.state?.filterInfo ?? {}),
    sortby: sortBy,
    ...(!!localStorage.getItem("filters")
      ? JSON.parse(window.atob(localStorage.getItem("filters")) ?? {})
      : {}),
  };
  const isMobile = window.innerWidth < 600;
  const [matchesResp, setMatchesResp] = React.useState({ matchData: [] });
  const [userStats, setUserStats] = React.useState({});
  const [filterPayload, setfilterPayload] = React.useState({
    ...filterInfo,
  });
  const [searchValue, setSearchValue] = React.useState(
    filterPayload?.name ?? ""
  );
  const [loading, toggleLoading] = React.useState(false);
  const [openFilterModal, setFilterModal] = React.useState(false);
  const [messageList, setMessageList] = React.useState([]);
  const [userProfile, setUserProfile] = React.useState({
    id: "",
    teamName: "",
    imageUrl: "",
  });
  const handleBasicFilterChange = (value, key) => {
    const { page, ...payload } = { ...filterPayload };

    setfilterPayload({
      ...payload,
      [key]: value,
    });
  };

  const fetchMatchData = (filters = {}) => {
    // window.scrollTo({ top: 0 });
    toggleLoading(true);
    const payload = { ...filterPayload, ...filters };
    dispatchFetchMyMatches(
      payload,
      (resp) => {
        if (resp?.status) {
          setMatchesResp(resp?.result ?? {});
        }
        toggleLoading(false);
      },
      (err) => {
        setMatchesResp({ matchData: [] });
        toggleLoading(false);
      }
    );
  };

  const fetchUserStatsData = (filters = {}) => {
    toggleLoading(true);
    dispatchFetchUserStatsData(
      (resp) => {
        if (resp?.status) {
          setUserStats(resp?.result ?? {});
        }
        toggleLoading(false);
      },
      (err) => {
        setMatchesResp({ matchData: [] });
        toggleLoading(false);
      }
    );
  };

  const fetchChatHistoryData = (chatId) => {
    dispatchFetchChatHistory(
      chatId,
      (resp) => {
        if (resp?.status & (resp?.result != null)) {
          setMessageList(resp.result);
        }
      },
      (err) => {
        // do nothing here
      }
    );
  };

  const handleClearSearch = () => {
    setSearchValue("");
    const { name, ...filterPayloadUpdated } = filterPayload;
    setfilterPayload({
      ...filterPayloadUpdated,
    });
  };
  function fetchNotificationCount() {
    dispatchNotificationCount();
  }

  useEffect(() => {
    if (!user?.accessToken) {
      props.history.push("/");
    }
    fetchNotificationCount();
    fetchUserStatsData();
  }, []);

  useEffect(() => {
    localStorage.setItem("filters", window.btoa(JSON.stringify(filterPayload)));
    fetchMatchData(filterPayload);
  }, [filterPayload]);

  const handleChange = (event) => {
    var sort = event.target.value;
    setSortBy(sort);
    setfilterPayload({
      ...filterPayload,
      sortby: sort,
      page: 1,
    });
  };
  const totalFilterCount = Object?.keys(filterPayload)?.filter((itemKey) => {
    let DoNotRender = ["page", "sortby"];
    return !!filterPayload?.[itemKey] && !DoNotRender?.includes(itemKey);
  })?.length;
  const pageNo = Number(matchesResp?.page ?? 0);
  return (
    <div id="MyMatches" className="mymatches">
      <Container className="base-container" maxWidth="unset">
        <Grid container={true} justifyContent={"space-between"}>
          <Grid
            item
            xs={5}
            md={9}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              variant="h5"
              fontFamily="Roboto"
              fontWeight={900}
              className="responsive_text"
            >
              <span>Welcome </span> {`${user?.firstName ?? ""}`}!
            </Typography>
            {/* {!!user?.id && (
              <Typography
                variant="h5"
                fontFamily="Roboto"
                fontWeight={600}
                color="black"
                className="responsive_text"
              >
                (Profile ID: {user?.id})
              </Typography>
            )} */}
          </Grid>
          <Grid item xs={7} md={3}>
            <Grid container={true}>
              <Grid item margin={"auto"} xs={7} md={5}>
                <Typography
                  variant="h4"
                  fontFamily="Roboto"
                  fontWeight={600}
                  className="matchCount"
                >
                  {matchesResp?.totalCount ?? 0}
                </Typography>
              </Grid>
              <Grid item margin={"auto"} xs={5} md={7}>
                <Typography
                  variant="h5"
                  fontFamily="Roboto"
                  fontWeight={600}
                  color="black"
                  className="responsive_text"
                >
                  Matches Found
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className="hideMobile"
          style={{ alignItems: "center", display: "flex" }}
        >
          {/* 
          remove dashboard part 1 start
          <Grid
            item
            justifyContent="center"
            md={12}
            style={{ alignItems: "center", display: "flex" }}
            container
            spacing={0}
          >
            <Card
              variant="elevation"
              style={{
                backgroundColor: "#fff",
                width: "60vw",
                transitionDuration: "5s",
              }}
            >
              <CardContent style={{ padding: "0.5em" }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    margin={"auto"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h8" component="div" fontWeight={800}>
                      Total Invites &nbsp;
                    </Typography>
                    <Typography
                      variant="h8"
                      component="div"
                      color="green"
                      fontWeight={600}
                    >
                      {parseInt(
                        isNaN(userStats?.RECEIVED?.SENT)
                          ? 0
                          : userStats?.RECEIVED?.SENT,
                        10
                      ) +
                        parseInt(
                          isNaN(userStats?.RECEIVED?.REJECTED)
                            ? 0
                            : userStats?.RECEIVED?.REJECTED,
                          10
                        ) +
                        parseInt(
                          isNaN(userStats?.RECEIVED?.SYSTEM_ACCEPTED)
                            ? 0
                            : userStats?.RECEIVED?.SYSTEM_ACCEPTED,
                          10
                        ) +
                        parseInt(
                          isNaN(userStats?.RECEIVED?.USER_ACCEPTED)
                            ? 0
                            : userStats?.RECEIVED?.USER_ACCEPTED,
                          10
                        )}
                      /
                    </Typography>
                    <Typography
                      variant="h8"
                      component="div"
                      color="blue"
                      fontWeight={600}
                    >
                      {parseInt(
                        isNaN(userStats?.SENT?.SENT)
                          ? 0
                          : userStats?.SENT?.SENT,
                        10
                      ) +
                        parseInt(
                          isNaN(userStats?.SENT?.REJECTED)
                            ? 0
                            : userStats?.SENT?.REJECTED,
                          10
                        ) +
                        parseInt(
                          isNaN(userStats?.SENT?.SYSTEM_ACCEPTED)
                            ? 0
                            : userStats?.SENT?.SYSTEM_ACCEPTED,
                          10
                        ) +
                        parseInt(
                          isNaN(userStats?.SENT?.USER_ACCEPTED)
                            ? 0
                            : userStats?.SENT?.USER_ACCEPTED,
                          10
                        )}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    margin={"auto"}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Typography variant="h8" component="div" fontWeight={800}>
                      Accepted &nbsp;
                    </Typography>
                    <Typography
                      variant="h8"
                      component="div"
                      color="green"
                      fontWeight={600}
                    >
                      {parseInt(
                        isNaN(userStats?.RECEIVED?.SYSTEM_ACCEPTED)
                          ? 0
                          : userStats?.RECEIVED?.SYSTEM_ACCEPTED,
                        10
                      ) +
                        parseInt(
                          isNaN(userStats?.RECEIVED?.USER_ACCEPTED)
                            ? 0
                            : userStats?.RECEIVED?.USER_ACCEPTED,
                          10
                        )}
                      /
                    </Typography>
                    <Typography
                      variant="h8"
                      component="div"
                      color="blue"
                      fontWeight={600}
                    >
                      {parseInt(
                        isNaN(userStats?.SENT?.SYSTEM_ACCEPTED)
                          ? 0
                          : userStats?.SENT?.SYSTEM_ACCEPTED,
                        10
                      ) +
                        parseInt(
                          isNaN(userStats?.SENT?.USER_ACCEPTED)
                            ? 0
                            : userStats?.SENT?.USER_ACCEPTED,
                          10
                        )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    margin={"auto"}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Typography variant="h8" component="div" fontWeight={800}>
                      Pending &nbsp;
                    </Typography>
                    <Typography
                      variant="h8"
                      component="div"
                      color="green"
                      fontWeight={600}
                    >
                      {parseInt(
                        isNaN(userStats?.RECEIVED?.SENT)
                          ? 0
                          : userStats?.RECEIVED?.SENT,
                        10
                      )}
                      /
                    </Typography>
                    <Typography
                      variant="h8"
                      component="div"
                      color="blue"
                      fontWeight={600}
                    >
                      {parseInt(
                        isNaN(userStats?.SENT?.SENT)
                          ? 0
                          : userStats?.SENT?.SENT,
                        10
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
           remove dashboard part 1 end */}
          {/* <Grid item
            justifyContent="center"

            md={4}
            style={{ alignItems: "center", display: "flex" }} container spacing={0}>
            <Card variant="elevation" style={{ backgroundColor: "rgb(186 206 242)" }}>
              <CardContent style={{ padding: "0.5em" }} >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="h8" component="div" >
                    Total Invites &nbsp;
                  </Typography>
                  <Typography variant="h8" component="div" color="green" fontWeight={600}>
                    20/
                  </Typography>
                  <Typography variant="h8" component="div" color="blue" fontWeight={600}>
                    20
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item
            justifyContent="center"

            md={4}
            style={{ alignItems: "center", display: "flex" }} container spacing={0}>
            <Card variant="elevation" style={{ backgroundColor: "rgb(194 244 196)" }}>
              <CardContent style={{ padding: "0.5em" }} >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="h8" component="div" >
                    Accepted &nbsp;
                  </Typography>
                  <Typography variant="h8" component="div" color="green" fontWeight={600}>
                    20/
                  </Typography>
                  <Typography variant="h8" component="div" color="blue" fontWeight={600}>
                    20
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item
            justifyContent="center"

            md={4}
            style={{ alignItems: "center", display: "flex" }} container spacing={0}>
            <Card variant="elevation" style={{ backgroundColor: "pink" }}>
              <CardContent style={{ padding: "0.5em" }} >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="h8" component="div" >
                    Pending &nbsp;
                  </Typography>
                  <Typography variant="h8" component="div" color="green" fontWeight={600} >
                    20/
                  </Typography>
                  <Typography variant="h8" component="div" color="blue" fontWeight={600}>
                    20
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid> */}
          {/* <Grid item
            xs={6}
            md={3}
            style={{ alignItems: "center", display: "flex" }} container spacing={0}>
            <Card variant="elevation">
              <CardContent>
                <Typography variant="h8" component="div">
                  Declined
                </Typography>

                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  20/20
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>

        {/* remove dashboard part 2 start
        <Grid display="flex" alignItems="center" justifyContent="center">
          <Box
            sx={{
              width: 10,
              height: 10,
              backgroundColor: "green",
            }}
          />
          <Typography
            variant="h8"
            fontFamily="Roboto"
            fontWeight={400}
            color="green"
            paddingRight="1em"
          >
            &nbsp;Received Invites
          </Typography>
          <Box
            sx={{
              width: 10,
              height: 10,
              backgroundColor: "blue",
            }}
          />
          <Typography
            variant="h8"
            fontFamily="Roboto"
            fontWeight={400}
            color="blue"
          >
            &nbsp;Sent Invites
          </Typography>
        </Grid>
        remove dashboard part 2 end */}
        {/* <Card className="hideMobile" variant="elevation">
          <CardContent alignItems="right">
            <Box
              sx={{
                width: 300,
                height: 300,
                backgroundColor: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
            />
            <Typography variant="h8" component="div">
              Legend
            </Typography>

            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

            </Typography>
          </CardContent>
        </Card> */}
      </Container>
      <Container
        className="base-container hideMobile"
        maxWidth="unset"
        style={{ backgroundColor: "#ffb95545" }}
      >
        <Box className="multiSelectToggle">
          <Grid container={true} gap={2}>
            <ToggleButton
              onChange={(e) => {
                if (filterPayload?.profileupdatedsinceindays !== 30) {
                  handleBasicFilterChange(30, "profileupdatedsinceindays");
                } else {
                  handleBasicFilterChange(null, "profileupdatedsinceindays");
                }
              }}
              selected={filterPayload?.profileupdatedsinceindays === 30}
              className="toggleButtoncss"
              value="profileupdatedsinceindays"
              aria-label="list"
            >
              New Profiles
            </ToggleButton>
            <ToggleButton
              onChange={(e) =>
                handleBasicFilterChange(!filterPayload?.heightgap, "heightgap")
              }
              selected={!!filterPayload?.heightgap}
              className="toggleButtoncss"
              value="heightgap"
              aria-label="heightgap"
            >
              {"Vara Height > Vadhu Height"}
            </ToggleButton>
            <ToggleButton
              onChange={(e) =>
                handleBasicFilterChange(
                  !filterPayload?.samelanguage,
                  "samelanguage"
                )
              }
              selected={!!filterPayload?.samelanguage}
              className="toggleButtoncss"
              value="samelanguage"
              aria-label="samelanguage"
            >
              Same Language
            </ToggleButton>
            <ToggleButton
              onChange={(e) =>
                handleBasicFilterChange(
                  !filterPayload?.similareducation,
                  "similareducation"
                )
              }
              selected={!!filterPayload?.similareducation}
              className="toggleButtoncss"
              value="similareducation"
              aria-label="similareducation"
            >
              Similar Education
            </ToggleButton>
          </Grid>
        </Box>
      </Container>
      <Container className="base-container" maxWidth="unset">
        <Grid container={true} justifyContent={"space-between"}>
          <Grid item xs={12} md={7} style={{ paddingBottom: "1em" }}>
            {window.innerWidth < 1000 ? (
              <FormGroup row>
                <TextField
                  size={"small"}
                  style={{ width: "70%", borderRadius: "10px 0 0 10px" }}
                  variant="outlined"
                  placeholder="Search by Name/ID"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e?.target?.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <Close
                        style={{ cursor: "pointer" }}
                        onClick={handleClearSearch}
                      />
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    width: "20vw",
                    color: "white",
                    backgroundColor: "#663399",
                    borderRadius: "0 10px 10px 0",
                  }}
                  onClick={(e) => {
                    setfilterPayload({
                      ...filterPayload,
                      name: searchValue,
                      page: 1,
                    });
                  }}
                >
                  GO
                </Button>
              </FormGroup>
            ) : (
              <FormGroup row>
                <TextField
                  size={"small"}
                  style={{ width: "30vw", borderRadius: "10px 0 0 10px" }}
                  variant="outlined"
                  placeholder="Search by Name/ID"
                  label="Search by Name/ID"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e?.target?.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <Close
                        style={{ cursor: "pointer" }}
                        onClick={handleClearSearch}
                      />
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    width: "20%",
                    color: "white",
                    backgroundColor: "#663399",
                    borderRadius: "0 10px 10px 0",
                  }}
                  onClick={(e) => {
                    setfilterPayload({
                      ...filterPayload,
                      name: searchValue,
                      page: 1,
                    });
                  }}
                >
                  GO
                </Button>
              </FormGroup>
            )}
          </Grid>
          <Grid item xs={12} md={3} sx={{ textAlign: "right" }}>
            <Button
              style={{ width: "100%", fontWeight: 900 }}
              variant="contained"
              disableElevation
              size="large"
              sx={{
                "&:hover": {
                  color: "white",
                  background: "#663399",
                },
              }}
              onClick={() => {
                setFilterModal(true);
              }}
            >
              Add More Filters
              {/* {window.innerWidth < 1000 ? <FilterAltIcon /> : "Add Filter"} */}
            </Button>
            {!!totalFilterCount && (
              <Box className="filterIndication" sx={{ mt: 2 }}>
                <ToggleButton
                  onChange={(e) =>
                    setfilterPayload({
                      sortby: sortBy,
                      page: 1,
                    })
                  }
                  className="toggleButtoncss"
                >
                  {`${totalFilterCount} Filter(s) applied`}
                  <Close
                    style={{
                      cursor: "pointer",
                      marginLeft: 5,
                      backgroundColor: "#fff",
                      borderRadius: 50,
                      fontSize: "20px",
                      color: "#000",
                    }}
                  />
                </ToggleButton>
              </Box>
            )}
            {/* <Chip
              label={
                <>
                  <b></b>
                </>
              }
              variant="outlined"
              color="primary"
              // className={classes.chipRoot}
              onDelete={() => {}}
              style={{
                backgroundColor: "#f5f6ff",
                color: "#000",
                marginTop: 8,
              }}
            /> */}
          </Grid>
        </Grid>

        {/* <Grid container={true} justifyContent={"space-between"}>
          <Grid>
            <FilteredState
              filteredValues={filterPayload}
              onDelete={(filterKey) => {
                let newFilteredValues = { ...filterPayload };
                delete newFilteredValues?.[filterKey];
                setfilterPayload({
                  ...newFilteredValues,
                });
              }}
              selectInfo={true}
            />
          </Grid>
        </Grid> */}

        <FormControl style={{ marginTop: "0.5em" }}>
          <Grid
            item
            xs={6}
            md={3}
            margin={"auto 0"}
            style={{ display: isMobile ? "" : "flex" }}
          >
            <FormLabel
              style={{ marginTop: "0.5em", color: "#663399" }}
              id="demo-controlled-radio-buttons-group"
            >
              Sort By:&nbsp;&nbsp;
            </FormLabel>
            <RadioGroup
              row={true}
              id="sortby"
              name="sortby"
              defaultValue="logintime"
              onChange={handleChange}
              value={sortBy}
            >
              <Grid item xs={6} md={3} margin={"auto 0"}>
                <FormControlLabel
                  value="logintime"
                  control={<Radio />}
                  label="Active Profiles"
                />
              </Grid>
              <Grid item xs={6} md={3} margin={"auto 0"}>
                <FormControlLabel
                  value="createdtime"
                  control={<Radio />}
                  label="New Profiles"
                />
              </Grid>
            </RadioGroup>
          </Grid>
        </FormControl>
        {user?.active === false ? (
          <Typography
            sx={{
              width: "100%",
              // fontWeight: "bolder",
              textAlign: "center",
              fontSize: "2em",
              color: "rgb(255, 39, 183)",
            }}
          >
            Your profile is inactive as some of your account details are not
            valid.
            <br></br>
            Contact admin at <strong>jspn.vvs@umapps.in</strong> or whatsapp at <strong>+91 63639 21008</strong> to make your
            profile active.
          </Typography>
        ) : (
          <></>
        )}
      </Container>
      <Container className="base-container" maxWidth="unset">
        <Grid container rowGap={2} columnGap={16}>
          {loading ? (
            Array.from({ length: 6 }, (_, i) => (
              <Grid
                item
                xs={10}
                md={3}
                paddingBottom={"1em"}
                className="mobileMargin"
                key={i}
              >
                <UserProfileCard loading={loading} />
              </Grid>
            ))
          ) : matchesResp?.matchData?.length ? (
            matchesResp?.matchData?.map((item, i) => (
              <Grid
                item
                xs={10}
                md={3}
                paddingBottom={"1em"}
                className="mobileMargin"
                key={item?.id}
              >
                <UserProfileCard
                  data={{ ...item, filterPayload, disabledChat: !user?.prime }}
                  loggedInUser={user}
                />
              </Grid>
            ))
          ) : (
            <Box
              className="inboxlist"
              padding={0}
              paddingBottom="1rem"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <EmptyImage width={400} height={400} />
            </Box>
          )}
        </Grid>

        <Stack
          spacing={2}
          width="22em"
          margin={"2em auto 1em auto"}
          style={{ textAlign: "center" }}
        >
          <Pagination
            count={Math.ceil(
              Number(matchesResp?.totalCount) / Number(matchesResp?.size) >= 1
                ? Number(matchesResp?.totalCount) / Number(matchesResp?.size)
                : 1
            )}
            page={pageNo}
            shape="rounded"
            onChange={(e, page) => {
              setfilterPayload({
                ...filterPayload,
                page,
              });
            }}
          />
        </Stack>
      </Container>
      {openFilterModal && (
        <FilterModal
          open={openFilterModal}
          onClose={() => {
            setFilterModal(false);
          }}
          selectedFilters={filterPayload}
          applyFilters={(filters) => {
            setfilterPayload({ ...filters });
            setFilterModal(false);
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchMyMatches: (data, onSuccess, onError) =>
    dispatch(fetchMyMatches(data, onSuccess, onError)),
  dispatchFetchUserStatsData: (onSuccess, onError) =>
    dispatch(fetchUserStats(onSuccess, onError)),
  dispatchNotificationCount: (onSuccess, onError) =>
    dispatch(NotificationCount(onSuccess, onError)),
  dispatchFetchChatHistory: (chatId, onSuccess, onError) =>
    dispatch(fetchChatHistory(chatId, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyMatches);
