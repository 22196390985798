import { Box, Divider, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { login } from "../../../Store/Actions/userActionCreator";
import { CustomTextField, Button } from "../../UI";
import Modal from "../Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { USER_ROLE_ENUM } from "../../../Utils/constants";
import { withRouter } from "react-router-dom";
import { showMessage } from "../../../Utils/helper";
import ForgetPasswordModal from "./ForgetPasswordModal";
import { Link } from "react-router-dom";

const validationSchema = yup.object({
  userId: yup.string().when("isEmail", {
    is: "1",
    then: yup
      .string()
      .email("Please enter valid email")
      .required("Email / Phone Number cannot be empty"),
    otherwise: yup
      .string()
      .required("Email / Phone Number cannot be empty")
      .length(10, "Phone Number must be 10 char"),
  }),
  password: yup.string().required("Password cannot be empty"),
});

function LoginModal({ open, onClose, toggleOtpLoginModal, ...props }) {
  const [isOpen, toggleModal] = useState(open);
  const [loading, toggleLoading] = useState(false);
  const [openForgetPwModal, setopenForgetPwModal] = useState(false);
  const { palette } = useTheme();
  const handleClose = () => {
    toggleModal(!isOpen);
    onClose && onClose();
  };

  const formik = useFormik({
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      isEmail: "0",
      userId: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      const payload = {
        ...values,
      };
      await toggleLoading(true);
      await props.dispatchLogin(
        payload,
        (resp) => {
          if (!!resp?.status) {
            toggleLoading(false);
            handleClose();
            if (!resp?.result?.emailVerified) {
              props.history.push("/register", {
                ...resp?.result,
              });
            } else if (!resp?.result?.mobileVerified) {
              props.history.push("/register", {
                ...resp?.result,
              });
            } else if (
              !!resp?.result &&
              checkNormalUser(resp)
            ) {
              props.history.push("/register/basic");
            } else if (
              !!resp?.result &&
              checkRegisteredUser(resp) &&
              !Object.keys(resp?.result?.imageUrl ?? {})?.length
            ) {
              props.history.push("/register/profile");
            } else {
              handleClose();
              props.history.push("/my-matches");
            }
          } else {
            showMessage(resp?.result, "error");
            toggleLoading(false);
          }
        },
        (err) => {
          showMessage(err, "error");
        }
      );
    },
  });

  const actions = [];
  return (
    <>
      <Modal
        onClose={handleClose}
        open={isOpen}
        title={""}
        actions={actions}
        loading={loading}
        isForm={true}
        onSubmit={formik.handleSubmit}
      >
        <Box
          style={{
            padding: "0 15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              marginTop: "8px",
              marginBottom: "24px",
              // fontFamily: "Montserrat",
              fontWeight: "bold",
              color: palette.primary.main,
            }}
          >
            Welcome!
          </Typography>
          <CustomTextField
            fullWidth={true}
            label="Enter Email ID / Mobile No."
            id="userId"
            name="userId"
            value={formik.values.userId}
            onChange={(event) => {
              formik.handleChange("userId")(event);
              if (Number(event.target.value)) {
                formik.handleChange("isEmail")("0");
              } else {
                formik.handleChange("isEmail")("1");
              }
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.userId ? Boolean(formik.errors.userId) : false
            }
            helperText={formik.touched.userId && formik.errors.userId}
            style={{ marginBottom: "16px" }}
          />
          <CustomTextField
            fullWidth={true}
            label="Enter Password"
            inputProps={{
              type: "password",
            }}
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password ? Boolean(formik.errors.password) : false
            }
            helperText={formik.touched.password && formik.errors.password}
            style={{ marginBottom: "16px" }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{
              fontWeight: "bold",
            }}
            type="submit"
          >
            Login
          </Button>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography fontSize={'1em'} color="#663399">By logging in, I agree to &nbsp;</Typography>
            <Typography fontSize={'1em'} color="#5671FF" to="/terms-and-conditions" component={Link}>
              T&C
            </Typography>
          </div>
          <Divider
            style={{
              margin: "20px 0 8px 0",
              borderWidth: 2,
              borderColor: "#000",
              borderRadius: 100,
              width: "100%",
            }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#5671FF",
              width: "100%",
            }}
          >
            <Button
              sx={{ textTransform: "none", "&:hover": { color: "none" } }}
              onClick={() => {
                toggleModal(!isOpen);
                setopenForgetPwModal(true);
              }}
            >
              <Typography color={"#5671FF"}>Forgot Password?</Typography>
            </Button>
            <Typography color={"#5671FF"}>|</Typography>
            <Button
              sx={{ textTransform: "none", "&:hover": { color: "none" } }}
              onClick={() => {
                toggleOtpLoginModal();
                // formik.validateForm(formik.values).then((err) => {
                //   if (!err.userId && !Number(formik.values.userId)) {
                //     setOpenEmailOtpModal(true);
                //   } else {
                //     setOpenEmailOtpModal(true);
                //   }
                // });
              }}
            >
              <Typography color={"#5671FF"}>Login via OTP</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
      {!!openForgetPwModal && (
        <ForgetPasswordModal
          open={openForgetPwModal}
          data={{ emailId: formik?.values?.userId, validateViaOTP: true }}
          onClose={() => {
            setopenForgetPwModal(false);
            handleClose();
          }}
          next={() => {
            setopenForgetPwModal(false);
            handleClose();
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (payload, onSuccess, onError) =>
    dispatch(login(payload, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginModal));


function checkRegisteredUser(resp) {
  if(!resp?.result?.roles)
  return false;
  let roles = resp?.result?.roles;
  let isRegisteredUser = false;
  for (let i=0; i< roles.length; i++)
  {
    if(roles[i]?.authority === USER_ROLE_ENUM.ROLE_REGISTERED_USER)
    isRegisteredUser= true;
  }
  return isRegisteredUser;
}

function checkNormalUser(resp) {
  if(!resp?.result?.roles)
  return false;
  let roles = resp?.result?.roles;
  let isRegisteredUser = false;
  for (let i=0; i< roles.length; i++)
  {
    if(roles[i]?.authority === USER_ROLE_ENUM.ROLE_NORMAL_USER)
    isRegisteredUser= true;
  }
  return isRegisteredUser;
}