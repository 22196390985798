import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import RouteWithTitle from "../../Components/Navigation/AppRoute/RouteWithTitle";
import Navbar from "../../Components/Navbar";
import { Notifier } from "../../Components/UI";
import "./styles.scss";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Utils/ScrollToTop";

function HomeLayout({ path, component, name, ...props }) {
  return (
    <div className="home-layout">
      <Navbar />
      {path && component && (
        <RouteWithTitle
          key={name}
          exact={true}
          path={path}
          component={component}
          name={name}
        />
      )}
      <Notifier />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default withRouter(HomeLayout);
