import * as constants from '../constants';

export const GetUserNotifications = (params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/notifications',
        method: 'GET',
        params,
        success: response => {
            return {
                type: constants.DO_NOTHING,
                payload: response?.result,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const MarkUserNotificationSeen = (params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/view-notification',
        method: 'GET',
        params,
        success: response => {
            return {
                type: constants.DO_NOTHING,
                payload: response?.result,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const NotificationCount = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/notification-count',
        method: 'GET',
        success: response => {
            return {
                type: constants.SET_NOTIFICATION_COUNT,
                payload: response?.result,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
