import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EMPTY_VALUE } from "../../Utils/constants";
import LoadingUserCard from "./LoadingProfileCard";
import { ChatWindowLauncher } from "../../Chat";
import StarIcon from "@mui/icons-material/Star";
import StarOutline from "@mui/icons-material/StarOutline";
import { Spinner } from "../../Components/UI";
import {
  addFavourite,
  removeFavourite,
  unblockUser,
} from "../../Store/Actions/matchesActionCreator";
import { connect } from "react-redux";
import { showMessage } from "../../Utils/helper";
import { useConfirm } from "../ConfirmationModal";

function UserProfileCard({
  loggedInUser,
  data,
  disabled,
  loading = false,
  dispatchAddFavourite,
  dispatchRemoveFavourite,
  dispatchUnblockUser,
  ...props
}) {
  const dispatch = useDispatch();
  const {
    id,
    firstName,
    age,
    higherQualification,
    imageUrl,
    language,
    location,
    filterPayload,
    isPrime,
    disabledChat,
    isFavourite,
    isBlocked,
  } = data ?? {};
  const [isFavouriteFE, setIsFavourite] = useState(isFavourite);
  const [isCurrentlyBlocked, setisCurrentlyBlocked] = useState(isBlocked);
  const [loader, setloading] = useState(false);
  const ConfirmationModal = useConfirm();
  if (loading) {
    return <LoadingUserCard />;
  }
  const addFavorite = (userId) => {
    dispatch(
      dispatchAddFavourite(
        userId,
        (resp) => {
          if (resp?.status) {
            setIsFavourite(true);
            showMessage(
              "Profile " +
                userId +
                " has been added to your shortlisted profiles.",
              "success"
            );
          } else {
            showMessage(
              "Unable to shortlist the profile. Please try again later.",
              "error"
            );
          }
        },
        (err) => {
          showMessage(
            "Unable to shortlist the profile. Please try again later.",
            "error"
          );
        }
      )
    );
  };

  const removeFavorite = (userId) => {
    dispatch(
      dispatchRemoveFavourite(
        userId,
        (resp) => {
          if (resp?.status) {
            setIsFavourite(false);
            showMessage(
              "Profile " +
                userId +
                " has been removed from your shortlisted profiles.",
              "error"
            );
          } else {
            showMessage(
              "Unable to remove from shortlist. Please try again later.",
              "error"
            );
          }
        },
        (err) => {
          showMessage(
            "Unable to remove from shortlist. Please try again later.",
            "error"
          );
        }
      )
    );
  };
  return (
    <Spinner loading={loader}>
      <div style={{ opacity: isCurrentlyBlocked ? 0.3 : 1 }}>
        <Card
          key={id}
          variant="outlined"
          sx={{
            minWidth: 250,
            borderColor: "rgba(102,51,153, 0.5)",
            borderRadius: "15px",
            cursor: "pointer",
            backgroundColor: isCurrentlyBlocked
              ? "rgba(100, 100, 100, 0.5)"
              : "",
            pointerEvents: isCurrentlyBlocked ? "none" : "auto",
          }}
          onClick={() => {
            if (loading) {
              return;
            }
            if (!!id)
              props.history.push(`/my-matches/${id}`, {
                data,
                filterInfo: filterPayload,
                pathname: "/my-matches/",
              });
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: disabled
                ? "rgba(112,112,112, 0.25)"
                : "rgba(255,39,183, 0.1)",
              width: "100%",
              position: "relative",
            }}
          >
            {isPrime && (
              <Chip
                label="PRIME"
                sx={{ position: "absolute", right: 5, top: 5 }}
              />
            )}
            {loggedInUser.prime ? (
              isFavouriteFE ? (
                <StarIcon
                  sx={{
                    position: "absolute",
                    left: 8,
                    top: 5,
                    color: "darkorange",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from propagating to the Card
                    removeFavorite(id ?? EMPTY_VALUE);
                  }}
                  fontSize="large"
                />
              ) : (
                <StarOutline
                  sx={{
                    position: "absolute",
                    left: 8,
                    top: 5,
                    color: "darkorange",
                    cursor: "pointer",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from propagating to the Card
                    addFavorite(id ?? EMPTY_VALUE);
                  }}
                  fontSize="large"
                />
              )
            ) : (
              <StarOutline
                sx={{
                  position: "absolute",
                  left: 8,
                  top: 5,
                  color: "gray",
                  cursor: "pointer",
                }}
                variant="outlined"
                fontSize="large"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click event from propagating to the Card
                  showMessage(
                    "Upgrade to VVS PRIME to shortlist the profiles",
                    "error"
                  );
                }}
              />
            )}

            {!!Object.keys(imageUrl)[0] ? (
              <Avatar
                alt={data?.firstName ?? "a"}
                sx={{ width: 150, height: 150, objectFit: "cover" }}
                src={imageUrl[Object.keys(imageUrl)[0]]}
              />
            ) : (
              <Avatar
                alt={firstName}
                sx={{ width: 150, height: 150, objectFit: "cover" }}
                src={""}
              />
            )}
            <Typography
              variant="h6"
              sx={{ mt: 1.5, fontWeight: "bold", textTransform: "uppercase" }}
              color={disabled ? "#000" : "text.primary"}
              gutterBottom
              title={`${data?.firstName ?? ""} ${data?.lastName ?? ""}`}
            >
              {(() => {
                const name = `${data?.firstName ?? ""} ${data?.lastName ?? ""}`;
                return name?.length < 17
                  ? name ?? EMPTY_VALUE
                  : `${data?.firstName ?? EMPTY_VALUE} ${
                      data?.lastName ?? EMPTY_VALUE
                    }`.slice(0, 15) + "...";
              })()}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                width: "inherit",
              }}
            >
              <Typography variant="body2" color="#000000">
                <u>Profile ID : {id ?? EMPTY_VALUE}</u>
              </Typography>
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={disabledChat ? "Update to Prime" : ""}
                placement="top"
              >
                <ChatWindowLauncher
                  userProfile={{
                    id: data?.id,
                    teamName: `${data?.firstName ?? ""} ${
                      data?.lastName ?? ""
                    }`,
                    imageUrl: Object.values(imageUrl)[0],
                  }}
                  disabled={disabledChat}
                >
                  <Button
                    loading={true}
                    sx={{
                      mt: 1,
                      width: "100%",
                      borderRadius: 2,
                      textTransform: "none",
                      borderColor: "#663399",
                      color: "#663399",
                      "&:hover": {
                        borderColor: "#663399",
                        backgroundColor: "#663399",
                        color: "#fff",
                      },
                    }}
                    variant="outlined"
                    disabled={disabledChat}
                  >
                    Chat
                  </Button>
                </ChatWindowLauncher>
              </Tooltip>
            </Box>
          </CardContent>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Typography
                sx={{ width: "33%" }}
                variant="caption"
                color="#000000"
              >
                Location
              </Typography>
              <Typography variant="caption" color="#000000">
                {" - "}
              </Typography>
              <Typography
                sx={{ width: "50%", fontWeight: "bolder" }}
                variant="caption"
                color="#000000"
              >
                {location ?? EMPTY_VALUE}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Typography
                sx={{ width: "33%" }}
                variant="caption"
                color="#000000"
              >
                Education
              </Typography>
              <Typography variant="caption" color="#000000">
                {" - "}
              </Typography>
              <Typography
                sx={{ width: "50%", fontWeight: "bolder" }}
                variant="caption"
                color="#000000"
              >
                {higherQualification ?? EMPTY_VALUE}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Typography
                sx={{ width: "33%" }}
                variant="caption"
                color="#000000"
              >
                Age
              </Typography>
              <Typography variant="caption" color="#000000">
                {" - "}
              </Typography>
              <Typography
                sx={{ width: "50%", fontWeight: "bolder" }}
                variant="caption"
                color="#000000"
              >
                {age ?? EMPTY_VALUE}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </div>
      <div>
        <Box>
          {isCurrentlyBlocked && (
            <div style={{ position: "relative" }}>
              <Button
                variant="contained"
                sx={{
                  color: "secondary", // Ensure the text is visible
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -300%)",
                  opacity: "1", // Ensure full opacity for the button
                  width: "60%", // Increase the width of the button
                  "&:hover": {
                    backgroundColor: "#663399",
                    color: "#fff",
                  },
                }}
                onClick={() =>
                  ConfirmationModal({
                    title: "Are you sure you want to unblock this profile? ",
                    description:
                      "They will be able to view your profile and send Invitation.",
                    confirmationText: "Yes",
                    confirmationButtonProps: {
                      variant: "contained",
                      style: {
                        // backgroundColor: '',
                        color: "white",
                      },
                    },
                    cancellationButtonProps: {
                      style: {
                        // backgroundColor: '',
                        color: "red",
                      },
                    },
                    cancellationText: "No",
                  }).then(() => {
                    setloading(true);
                    dispatch(
                      dispatchUnblockUser(
                        id,
                        (resp) => {
                          if (resp?.status) {
                            setloading(false);
                            showMessage(resp?.result, "success");
                            setisCurrentlyBlocked(false);
                          } else {
                            setloading(false);
                            showMessage(resp?.result, "error");
                          }
                        },
                        (err) => {
                          setloading(false);
                          showMessage(
                            "Unable to unblock the user. Please try again later",
                            "error"
                          );
                        }
                      )
                    );
                  })
                }
              >
                Unblock User
              </Button>
            </div>
          )}
        </Box>
      </div>
    </Spinner>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatchAddFavourite: (userId, onSuccess, onError) =>
    dispatch(addFavourite(userId, onSuccess, onError)),
  dispatchRemoveFavourite: (userId, onSuccess, onError) =>
    dispatch(removeFavourite(userId, onSuccess, onError)),
  dispatchUnblockUser: (userId, onSuccess, onError) =>
    dispatch(unblockUser(userId, onSuccess, onError)),
});

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfileCard));
