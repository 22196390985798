import axios from "axios";
import * as constants from "./constants";
import { checkHttpStatus, logout, showMessage } from "../Utils/helper";
import { API_URL } from "../Config/env/env";

// let lastApiCalled = {};

/**
 * Common middleware for all redux store actions
 * @param {*} param0
 * @returns
 */
export const apiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    // Applicable only for APIs
    if (action.type !== constants.API) return next(action);

    const {
      url,
      method,
      success,
      data,
      params,
      postProcessSuccess,
      postProcessError,
      overwriteHeaders,
    } = action.payload;
    if (action.thirdParty === true) {
      axios({
        method,
        url: url,
      })
        .then((response) => {
          if (success) {
            let data = response.data;

            if (data.status && data.status.code && data.status.code !== 200) {
              postProcessError(response.data);
            } else {
              dispatch(success(response));
            }
          }
          if (postProcessSuccess) postProcessSuccess(response.data);
        })
        .catch((err) => {
          if (!err.response) console.warn(err);
          else {
            if (
              err.response &&
              (err.response.status === 403 || err.response.status === 401)
            ) {
              logout();
            }
            if (
              err.response.data &&
              err.response.data.error &&
              err.response.data.error.message
            ) {
              if (postProcessError)
                postProcessError(err.response.data.error.message);
            } else {
              if (postProcessError)
                postProcessError("Unable to fetch IFSC data");
            }
          }
        });
    } else {
      const BASE_URL = API_URL;
      const AUTH_TOKEN_INFO = getState().user.accessToken;
      if (AUTH_TOKEN_INFO) {
        axios.defaults.headers.common["Authorization"] = AUTH_TOKEN_INFO;
      }
      if (overwriteHeaders) {
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          ...overwriteHeaders,
        };
      }
      // if (action.payload.url !== "/auth/refresh-login")
      // lastApiCalled = {
      //   ...action,
      // };
      axios({
        method,
        url: BASE_URL + url,
        data: !!data ? data : null,
        params: !!params ? params : null,
      })
        .then(async (response) => {
          if (success) {
            let data = response.data;
            // Backend error code to be 401 to force user logout
            if (!response.status && response.status === 401) {
              logout();
            }

            if (!response.status && response.status === 501) {
              // dispatch(
              //   refreshToken((resp) => {
              //     console.log("refresh token resp", resp);
              //     dispatch({ type: constants.TOGGLE_LOADER });
              //     dispatch(lastApiCalled);
              //   })
              // );
            }

            if (
              response.status &&
              response.status !== 200 &&
              !checkHttpStatus({
                code: response.status,
                message: data?.result,
              })
            ) {
              if (postProcessError) postProcessError(response.data?.result);
            } else {
              dispatch(success(response.data));
              if (postProcessSuccess) {
                postProcessSuccess(response.data);
              }
            }
          }
        })
        .catch((err) => {
          if (
            !checkHttpStatus({
              code: err?.response?.status,
              message: err,
            })
          ) {
            if (postProcessError) {
              showMessage("unable to connect to sever. Please retry", "error");
              postProcessError(err);
            }
          }
        });
    }
  };
