/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./UserProfile.scss";
import {
  Container,
  Grid,
  Button,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

import Typography from "@mui/material/Typography";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";

import { connect } from "react-redux";
import { FetchProfileDetails } from "../../Store/Actions/userActionCreator";
import { CustomTextField, Spinner, StyledMenuItem } from "../../Components/UI";
import { EMPTY_VALUE, MOTHER_TONGUE_ENUM } from "../../Utils/constants";
import KeyValueInfo from "../DetailProfile/components/KeyValueInfo";
import { useFormik } from "formik";
import * as yup from "yup";
import "./UserProfile.scss";
const validationSchema = yup.object({
  matha: yup
    .string("Invalid matha")
    .trim()
    .nullable(true)
    .required("matha cannot be empty"),
  motherTongue: yup
    .string("Invalid mother tongue")
    .trim()
    .nullable(true)
    .required("mother tongue cannot be empty"),
  fatherName: yup
    .string("Invalid father name")
    .trim()
    .nullable(true)
    .required("father name cannot be empty"),
  fatherOccupation: yup
    .string("Invalid father occupation")
    .trim()
    .nullable(true)
    .required("father occupation cannot be empty"),
  motherName: yup
    .string("Invalid mother name")
    .trim()
    .nullable(true)
    .required("mother name cannot be empty"),
  motherOccupation: yup
    .string("Invalid mother occupation")
    .trim()
    .nullable(true)
    .required("mother occupation cannot be empty"),
  brotherCount: yup
    .number("Invalid brother count")
    .required("brother count cannot be empty"),
  sisterCount: yup
    .number("Invalid sister count")
    .required("sister count cannot be empty"),
  alternateContact: yup
    .string("Invalid alternate contact no.")
    .trim()
    .nullable(true),
  house: yup.string("Invalid house").nullable(true),
  address: yup
    .string("Invalid address")
    .trim()
    .nullable(true)
    .required("address cannot be empty"),
  gotra: yup
    .string("Invalid gotra")
    .trim()
    .nullable()
    .required("gotra cannot be empty"),
  rashi: yup
    .string("Invalid rashi")
    .trim()
    .nullable()
    .required("rashi cannot be empty"),
  nakshatra: yup
    .string("Invalid nakshatra")
    .trim()
    .nullable()
    .required("nakshatra cannot be empty"),
  height: yup
    .string("Invalid height")
    .trim()
    .nullable()
    .required("height cannot be empty"),
  location: yup
    .string("Invalid location")
    .trim()
    .nullable()
    .required("location cannot be empty"),
  charana: yup.string("Invalid charana").trim().nullable(),
  build: yup.string("Invalid build").trim().nullable(),
  about: yup.string("Invalid about").trim().nullable(),
  partnerPreferences: yup
    .string("Invalid partner preferences")
    .trim()
    .nullable(),
  higherQualification: yup
    .string("Invalid higher qualification")
    .trim()
    .nullable()
    .required("higher qualification cannot be empty"),
  postGraduation: yup.string("Invalid post graduation").trim().nullable(),
  // .required("post graduation cannot be empty"),
  postGraduationYear: yup
    .string("Invalid post graduation year")
    .trim()
    .nullable(),
  // .required("post graduation year cannot be empty"),
  graduation: yup.string("Invalid graduation").trim().nullable(),
  // .required("graduation cannot be empty"),
  graduationYear: yup.string("Invalid graduation year").trim().nullable(),
  // .required("graduation year cannot be empty"),

  employer: yup.string("Invalid employer").trim().nullable(),
  previousEmployment: yup
    .string("Invalid previous employment")
    .trim()
    .nullable(),
  workingSince: yup.string("Invalid working since").trim().nullable(),
  workExperience: yup.string("Invalid work experience").trim().nullable(),
  income: yup.string("Invalid work income").trim().nullable(),
  familyValues: yup
    .string("Invalid family values")
    .trim()
    .nullable()
    .required("family values cannot be empty"),
  familyType: yup
    .string("Invalid family type")
    .trim()
    .nullable()
    .required("family type cannot be empty"),
  familyStatus: yup
    .string("Invalid family status")
    .trim()
    .nullable()
    .required("family status cannot be empty"),
  familyOtherInfo: yup.string("Invalid family other info").trim().nullable(),
  familyViews: yup.string("Invalid family views").trim().nullable(),
});

function LoginUserProfile({ dispatchFetchProfileDetails, user, ...props }) {
  // const { height, width } = useWindowDimensions();
  const [loading, setLoading] = React.useState(false);
  const [isEdit, toggleEdit] = React.useState(false);
  const [accordionExpanded, setAccordionExpanded] = React.useState({
    personal: false,
  });
  const [profileInfo, setProfileResp] = React.useState({});

  const handleAccordionChange = (e, expaned) => {
    setAccordionExpanded({
      ...accordionExpanded,
      [e.currentTarget.id]: expaned,
    });
  };

  const fetchProfileDetails = () => {
    setLoading(true);
    dispatchFetchProfileDetails(
      {},
      (resp) => {
        if (resp?.status) {
          setProfileResp(resp?.result);
          formik.setValues({
            ...resp?.result,
          });
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  let parseHeight = (value) => {
    var inches = (value * 0.393700787).toFixed(0);
    var feet = Math.floor(inches / 12);
    inches %= 12;

    let returnValue = feet + " feet " + inches + " inch";

    return returnValue;
  };

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      matha: "",
      motherTongue: "",
      fatherName: "",
      fatherOccupation: "",
      motherName: "",
      motherOccupation: "",
      brotherCount: "",
      sisterCount: "",
      alternateContact: "",
      address: "",
      contactPreference: "",
      house: "",
      gotra: "",
      rashi: "",
      nakshatra: "",
      height: "",
      location: "",
      charana: "",
      build: "",
      about: "",
      partnerPreferences: "",
      higherQualification: "",
      postGraduation: "",
      postGraduationYear: "",
      graduation: "",
      graduationYear: "",
      employer: "",
      previousEmployment: "",
      workingSince: "",
      workExperience: "",
      income: "",
      familyValues: "",
      familyType: "",
      familyStatus: "",
      familyOtherInfo: "",
      familyViews: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {},
  });

  useEffect(() => {
    window.scrollTo({
      x: 0,
      y: 0,
    });
    if (!user?.accessToken) {
      props.history.push("/");
    }
    fetchProfileDetails();
  }, []);

  const handleEditProfile = () => {
    props.history.push("/my-profile/edit", {
      data: profileInfo,
    });
  };
  // const handleProfileDelete = () => {
  //   props.history.push("/my-profile/delete", {
  //     data: profileInfo,
  //   });
  // };

  const KeyValueGrid = (label, value) => {
    if (!label && !value) return <></>;
    var valueString = profileInfo?.[value] ?? EMPTY_VALUE;
    if (label == "Height") {
      valueString = parseHeight(profileInfo?.[value] ?? EMPTY_VALUE);
    }

    const isOK = !!isEdit;
    return (
      <Grid container={true} sx={{ display: "flex", alignItems: "center" }}>
        <Typography width={!!isOK ? "100%" : "30%"} color="#707070">
          {label}
        </Typography>
        {!isEdit && <Typography style={{ margin: "0 1em" }}>-</Typography>}
        <Typography fontWeight={600} borderBottom="1px solid">
          {valueString}
        </Typography>
      </Grid>
    );
  };
  const KeyValueLi = (label, value) => {
    if (!label && !value) return <></>;
    return (
      <li>
        <Typography color={"black"} fontWeight={600} variant="subtitle2">
          {label} : {profileInfo?.[value] ?? EMPTY_VALUE}
        </Typography>
      </li>
    );
  };

  return (
    <Spinner loading={loading}>
      <a
        href="https://wa.me/message/BN2R4ZFIMY4JM1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <form onSubmit={formik.handleSubmit}>
        <div id="LoginUserProfile" className="loginuserprofile">
          <Container className="base-container" maxWidth="unset">
            <Grid container={true} justifyContent={"space-between"}>
              <Grid xs={12} md={2}>
                <center>
                  {!!Object.keys(profileInfo?.imageUrl ?? {})[0] ? (
                    <Avatar
                      alt={profileInfo?.firstName ?? "a"}
                      sx={{
                        width: 150,
                        height: 150,
                        objectFit: "cover",
                        alignItems: "center",
                        marginTop: "0.5em",
                        marginBottom: "0.5em",
                        borderRadius: "1em",
                      }}
                      src={
                        profileInfo?.imageUrl[
                          Object.keys(profileInfo?.imageUrl)[0]
                        ]
                      }
                    />
                  ) : (
                    <Avatar
                      alt={profileInfo?.firstName ?? ""}
                      sx={{
                        width: 150,
                        height: 150,
                        objectFit: "cover",
                        alignItems: "center",
                        marginTop: "0.5em",
                        marginBottom: "0.5em",
                        borderRadius: "1em",
                      }}
                      src={""}
                    />
                  )}
                </center>
              </Grid>

              <Grid
                container={true}
                xs={12}
                md={10}
                justifyContent={"space-between"}
                style={{ padding: "1em", position: "relative" }}
              >
                <Grid item md={4} xs={12} className="responsivePosition">
                  <Typography variant="h5" fontFamily="Roboto" fontWeight={900}>
                    {`${profileInfo?.firstName ?? EMPTY_VALUE} ${
                      profileInfo?.lastName ?? ""
                    }`}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontFamily="Roboto"
                    fontWeight={600}
                    color="black"
                  >
                    <div
                      style={{
                        borderBottom: "1px solid black",
                        display: "inline-block",
                      }}
                    >
                      Profile ID:{profileInfo?.id}
                    </div>
                  </Typography>
                  <Typography
                    variant="h5"
                    fontFamily="Roboto"
                    fontWeight={600}
                    color="black"
                    className="responsive_text onlyMobile"
                  >
                    <LocationIcon sx={{ fontSize: "1em" }} />
                    {profileInfo?.location}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontFamily="Roboto"
                    fontWeight={500}
                    color="#707070"
                    className="hideMobile"
                  >
                    {profileInfo?.higherQualification}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontFamily="Roboto"
                    fontWeight={600}
                    color="#707070"
                    sx={{
                      paddingTop: "1em",
                      alignItems: "center",
                      display: "flex",
                    }}
                    className="hideMobile"
                  >
                    <MailIcon
                      style={{ color: "#663399", marginRight: "0.5em" }}
                    />{" "}
                    {profileInfo?.emailId}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontFamily="Roboto"
                    fontWeight={600}
                    color="#707070"
                    sx={{ alignItems: "center", display: "flex" }}
                    className="hideMobile"
                  >
                    <PhoneIcon
                      style={{ color: "#663399", marginRight: "0.5em" }}
                    />{" "}
                    {profileInfo?.mobileNumber}
                  </Typography>
                  {/* <Typography
                    variant="subtitle1"
                    fontFamily="Roboto"
                    fontWeight={600}
                    color="#707070"
                    sx={{ alignItems: "center", display: "flex" }}
                    className="hideMobile"
                  >
                    Profile ID :{profileInfo?.id}
                  </Typography> */}
                </Grid>
                <Grid item md={8} xs={12}>
                  {isEdit ? (
                    <Grid
                      md={12}
                      container={true}
                      className="hideMobile"
                      style={{ justifyContent: "space-between" }}
                    >
                      <Button
                        style={{
                          width: "40%",
                          fontWeight: 900,
                          marginRight: "1em",
                        }}
                        variant="contained"
                        size="large"
                        disableElevation
                        type="submit"
                        disabled={!formik.isValid}
                      >
                        Save
                      </Button>
                      <Button
                        style={{
                          width: "40%",
                          fontWeight: 900,
                          color: "#F86969",
                          borderColor: "#F86969",
                          marginLeft: "1em",
                        }}
                        variant="outlined"
                        size="large"
                        onClick={() => {
                          toggleEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  ) : (
                    <Grid
                      md={12}
                      container={true}
                      className="hideMobile"
                      style={{ justifyContent: "space-between" }}
                    >
                      <Button
                        style={{
                          width: "40%",
                          fontWeight: 900,
                          marginRight: "1em",
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#FFB955",
                          },
                        }}
                        variant="contained"
                        size="large"
                        disableElevation
                        onClick={handleEditProfile}
                      >
                        Edit Profile
                      </Button>
                      {/* <Button
                        style={{
                          width: "40%",
                          fontWeight: 900,
                          color: "#F86969",
                          borderColor: "#F86969",
                          marginLeft: "1em",
                        }}
                        variant="outlined"
                        size="large"
                        onClick={handleProfileDelete}
                      >
                        Delete Account
                      </Button> */}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item md={8} xs={12} className="onlyMobile">
                {isEdit ? (
                  <Grid
                    md={12}
                    container={true}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      style={{
                        fontWeight: 900,
                        // marginRight: "1em",
                      }}
                      disableElevation
                      variant="contained"
                      size="large"
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Save
                    </Button>
                    <Button
                      style={{
                        fontWeight: 900,
                        color: "#F86969",
                        borderColor: "#F86969",
                        // marginLeft: "1em",
                      }}
                      variant="outlined"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Grid>
                ) : (
                  <Grid
                    md={12}
                    container={true}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      style={{
                        fontWeight: 900,
                        // marginRight: "1em",
                      }}
                      disableElevation
                      variant="contained"
                      size="large"
                      onClick={handleEditProfile}
                    >
                      Edit Profile
                    </Button>
                    {/* <Button
                      style={{
                        fontWeight: 900,
                        color: "#F86969",
                        borderColor: "#F86969",
                        // marginLeft: "1em",
                      }}
                      variant="outlined"
                      size="large"
                      onClick={handleProfileDelete}
                    >
                      Delete Account
                    </Button> */}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
          <Container
            className="base-container-without-top alternatesectioncolor hideMobile"
            maxWidth="unset"
          >
            <Grid
              container={true}
              justifyContent={"space-between"}
              sx={{ paddingTop: "2rem" }}
            >
              <Grid item md={5}>
                <Grid item>
                  <Typography color={"black"} fontWeight={600} variant={"h5"}>
                    About
                  </Typography>
                  <Typography
                    color={"black"}
                    fontWeight={400}
                    variant="subtitle2"
                    marginTop={"2em"}
                    style={{
                      minHeight: "10em",
                    }}
                  >
                    {profileInfo?.about ?? EMPTY_VALUE}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color={"black"}
                    fontWeight={600}
                    variant={"h5"}
                    marginTop={"2rem"}
                    paddingBottom={"1rem"}
                  >
                    Education Details
                  </Typography>
                  <ul>
                    {KeyValueLi("Higher Qualification", "higherQualification")}
                    {KeyValueLi("Post Graduation", "postGraduation")}
                    {KeyValueLi("Post Graduation Year", "postGraduationYear")}
                    {KeyValueLi("Graduation", "graduation")}
                    {KeyValueLi("Graduation Year", "graduationYear")}
                  </ul>
                </Grid>
                <Grid item>
                  <Typography
                    color={"black"}
                    fontWeight={600}
                    variant={"h5"}
                    marginTop={"2rem"}
                    paddingBottom={"1rem"}
                  >
                    Professional Details
                  </Typography>
                  <ul>
                    {KeyValueLi("Work Experience", "workExperience")}
                    {KeyValueLi("Working Since", "workingSince")}
                    {KeyValueLi("Employer", "employer")}
                    {KeyValueLi("Previous Employment", "previousEmployment")}
                    {KeyValueLi("Income", "income")}
                  </ul>
                </Grid>
              </Grid>
              <Grid md={6}>
                <Grid item>
                  <Typography
                    color={"black"}
                    fontWeight={600}
                    variant={"h5"}
                    marginTop={"2rem"}
                    paddingBottom={"1rem"}
                  >
                    Horoscope Details
                  </Typography>
                  {/* loop this for multiple datapoints */}
                  {KeyValueGrid("Gotra", "gotra")}
                  {KeyValueGrid("Rashi", "rashi")}
                  {KeyValueGrid("Nakshatra", "nakshatra")}
                  {KeyValueGrid("Charana/Pada", "charana")}
                </Grid>
                <Grid item>
                  <Typography
                    color={"black"}
                    fontWeight={600}
                    variant={"h5"}
                    marginTop={"2rem"}
                    paddingBottom={"1rem"}
                  >
                    Personal Information
                  </Typography>

                  {KeyValueGrid("Matha", "matha")}
                  {KeyValueGrid("Mother Tongue", "motherTongue")}
                  {KeyValueGrid("Category", "category")}
                  {KeyValueGrid("DOB", "dateOfBirth")}
                  {KeyValueGrid("Height", "height")}
                  {KeyValueGrid("Build", "build")}
                  {KeyValueGrid("Current Region of stay", "region")}
                  {KeyValueGrid("Current Place of stay", "location")}
                  {KeyValueGrid("Alternate Contact", "alternateContact")}
                  {KeyValueGrid("Address", "address")}
                  {KeyValueGrid("Preferred Contact Time", "contactPreference")}
                  {KeyValueGrid("Partner Preferences", "partnerPreferences")}
                </Grid>
                <Grid item marginBottom="5em">
                  <Typography
                    color={"black"}
                    fontWeight={600}
                    variant={"h5"}
                    marginTop={"2rem"}
                    paddingBottom={"1rem"}
                  >
                    Family Details
                  </Typography>
                  {KeyValueGrid("Father Name", "fatherName")}
                  {KeyValueGrid("Father Occupation", "fatherOccupation")}
                  {KeyValueGrid("Mother Name", "motherName")}
                  {KeyValueGrid("Mother Occupation", "motherOccupation")}
                  {KeyValueGrid("No. of Brothers", "brotherCount")}
                  {KeyValueGrid("No. of Sisters", "sisterCount")}
                  {KeyValueGrid("Family Values", "familyValues")}
                  {KeyValueGrid("Family Type", "familyType")}
                  {KeyValueGrid("Family Status", "familyStatus")}
                  {KeyValueGrid(
                    "Views on Family Value & Traditions",
                    "familyViews"
                  )}
                  {KeyValueGrid(
                    "Other information of my family",
                    "familyOtherInfo"
                  )}
                  {/* <Grid container={true}> */}
                  {/* loop this for multiple datapoints */}
                  {/* <Typography className="tag">5 ft 10"</Typography>
                  <Typography className="tag tag_alternate_color">
                    5 ft 10"
                  </Typography>
                </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Container>
          {/* For mobile only below code */}
          <Container
            className="base-container onlyMobile nobackgroudncolormobile"
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
              backgroundColor: "#F0EBF5",
            }}
          >
            <Accordion
              className="bordercoloraccordion bottommargin"
              id="personal"
              onChange={handleAccordionChange}
              expanded={accordionExpanded?.personal}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      border: "1px solid #663399",
                      borderRadius: "1em",
                      color: "#663399",
                    }}
                    color="#663399"
                  />
                }
                aria-controls="panel1a-content"
                id="personal"
                className="reverseflex bordercoloraccordion"
              >
                <Typography
                  style={{
                    marginLeft: "1em",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  My Personal Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <KeyValueInfo
                  label={"About Me"}
                  value={profileInfo?.about ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Date of Birth"}
                  value={profileInfo?.dateOfBirth ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Height"}
                  value={
                    profileInfo
                      ? profileInfo.height
                        ? parseHeight(profileInfo.height)
                        : EMPTY_VALUE
                      : EMPTY_VALUE
                  }
                />
                <KeyValueInfo
                  label={"Alternate Contact"}
                  value={profileInfo?.alternateContact ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Current Region of stay"}
                  value={profileInfo?.region ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Current Location of stay"}
                  value={profileInfo?.location ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Address"}
                  value={profileInfo?.address ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Preferred Contact Time"}
                  value={profileInfo?.contactPreference ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Build"}
                  value={profileInfo?.build ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Partner Preferences"}
                  value={profileInfo?.partnerPreferences ?? EMPTY_VALUE}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="bordercoloraccordion bottommargin"
              id="general"
              onChange={handleAccordionChange}
              expanded={accordionExpanded?.general}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      border: "1px solid #663399",
                      borderRadius: "1em",
                      color: "#663399",
                    }}
                    color="#663399"
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="reverseflex bordercoloraccordion"
              >
                <Typography
                  style={{
                    marginLeft: "1em",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  General Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <KeyValueInfo
                  label={"Mother Tongue"}
                  value={profileInfo?.motherTongue ?? EMPTY_VALUE}
                />
              </AccordionDetails>
              <AccordionDetails>
                <KeyValueInfo
                  label={"Category"}
                  value={profileInfo?.category ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Matha"}
                  value={profileInfo?.matha ?? EMPTY_VALUE}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="bordercoloraccordion bottommargin"
              id="horoscope"
              onChange={handleAccordionChange}
              expanded={accordionExpanded.horoscope}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      border: "1px solid #663399",
                      borderRadius: "1em",
                      color: "#663399",
                    }}
                    color="#663399"
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="reverseflex bordercoloraccordion"
              >
                <Typography
                  style={{
                    marginLeft: "1em",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  Horoscope Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <KeyValueInfo
                  label={"Gotra"}
                  value={profileInfo?.gotra ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Rashi"}
                  value={profileInfo?.rashi ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Nakshatra"}
                  value={profileInfo?.nakshatra ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Charana/Pada"}
                  value={profileInfo?.charana ?? EMPTY_VALUE}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="bordercoloraccordion bottommargin"
              id="education"
              onChange={handleAccordionChange}
              expanded={accordionExpanded.education}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      border: "1px solid #663399",
                      borderRadius: "1em",
                      color: "#663399",
                    }}
                    color="#663399"
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="reverseflex bordercoloraccordion"
              >
                <Typography
                  style={{
                    marginLeft: "1em",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  Education / Career
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <KeyValueInfo
                  label={"Higher Qualification"}
                  value={profileInfo?.higherQualification ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Post Graduation"}
                  value={profileInfo?.postGraduation ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Post Graduation Year"}
                  value={profileInfo?.postGraduationYear ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Graduation"}
                  value={profileInfo?.graduation ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Graduation Year"}
                  value={profileInfo?.graduationYear ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Work Experience"}
                  value={profileInfo?.workExperience ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Working Since"}
                  value={profileInfo?.workingSince ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Employer"}
                  value={profileInfo?.employer ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Previous Employment"}
                  value={profileInfo?.previousEmployment ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Monthly Income (Rs)"}
                  value={profileInfo?.income ?? EMPTY_VALUE}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="bordercoloraccordion bottommargin"
              id="family"
              onChange={handleAccordionChange}
              expanded={accordionExpanded.family}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      border: "1px solid #663399",
                      borderRadius: "1em",
                      color: "#663399",
                    }}
                    color="#663399"
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="reverseflex bordercoloraccordion"
              >
                <Typography
                  style={{
                    marginLeft: "1em",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  My Family Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <KeyValueInfo
                  label={"Father Name"}
                  value={profileInfo?.fatherName ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Father Occupation"}
                  value={profileInfo?.fatherOccupation ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Mother Name"}
                  value={profileInfo?.motherName ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Mother Occupation"}
                  value={profileInfo?.motherOccupation ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"No. of Brothers"}
                  value={profileInfo?.brotherCount ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"No. of Sisters"}
                  value={profileInfo?.sisterCount ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Family Values"}
                  value={profileInfo?.familyValues ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Family Type"}
                  value={profileInfo?.familyType ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Family Status"}
                  value={profileInfo?.familyStatus ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Views on Family Value & Traditions"}
                  value={profileInfo?.familyViews ?? EMPTY_VALUE}
                />
                <KeyValueInfo
                  label={"Other information of my family"}
                  value={profileInfo?.familyOtherInfo ?? EMPTY_VALUE}
                />
              </AccordionDetails>
            </Accordion>
          </Container>
        </div>
      </form>
    </Spinner>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchProfileDetails: (type, onSuccess, onError) =>
    dispatch(FetchProfileDetails(type, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginUserProfile);
