/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  SendAccountOtp,
  UpdatePassword,
} from "../../../Store/Actions/userActionCreator";
import { Button } from "../../UI";
import Modal from "../Modal";
import { withRouter } from "react-router-dom";
import { EMPTY_VALUE } from "../../../Utils/constants";

function InviteSharedInfoModal({ data, open, onClose, next }) {
  const [isOpen, toggleModal] = useState(open);

  const handleClose = () => {
    toggleModal(!isOpen);
    onClose && onClose();
  };

  const actions = [
    <Button
      variant="contained"
      style={{
        height: "2.5em",
        fontWeight: 900,
        margin: "1em 0",
        fontSize: "10pt",
        displat: "block",
      }}
      size="large"
      disableElevation={true}
      onClick={handleClose}
    >
      Close
    </Button>,
  ];
  return (
    <>
      <Modal
        onClose={handleClose}
        open={isOpen}
        title={"Contact Details"}
        isCustomTitle={true}
        actions={actions}
        isForm={false}
        maxWidth="sm"
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <InfoItem label={"Name: "} value={data?.name ?? EMPTY_VALUE} />
          <InfoItem
            label={"Email:"}
            value={data?.emailIdentifier ?? EMPTY_VALUE}
          />
          <InfoItem
            label={"Mobile Number:"}
            value={data?.mobileNumber ?? EMPTY_VALUE}
          />
          <InfoItem
            label={"Alternate Contact: "}
            value={data?.alternateContact ?? EMPTY_VALUE}
          />
          <InfoItem
            label={"Profile Created By:"}
            value={data?.profileCreatedBy ?? EMPTY_VALUE}
          />
          <div>
            <br />
            <Typography>
              Preferred Contact Time:
            </Typography>
            <Typography
              variant="caption"
              color="#000000"
              sx={{

                fontWeight: "bolder",
                fontSize: 16,
              }}
            >
              {data?.contactPreference ?? EMPTY_VALUE}
            </Typography>

          </div>
          {!!data?.primeContactViewCount && (
            <div>
              <br />
              <Typography>
                No of Contacts Viewed with PRIME privileges:
              </Typography>
              <Typography
                variant="caption"
                color="#000000"
                sx={{

                  fontWeight: "bolder",
                  fontSize: 16,
                }}
              >
                {data?.primeContactViewCount ?? EMPTY_VALUE}
              </Typography>


            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InviteSharedInfoModal));

const InfoItem = ({ label, value }) => {
  const isMobileView = window.innerWidth < 600;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <Typography
        variant="caption"
        color="#707070"
        sx={{ width: isMobileView ? "initial%" : "30%", fontSize: 16 }}
      >
        {label ?? ""}
      </Typography>
      <Typography
        variant="caption"
        color="#000000"
        sx={{
          width: isMobileView ? "initial" : "50%",
          fontWeight: "bolder",
          fontSize: 16,
        }}
      >
        {value ?? EMPTY_VALUE}
      </Typography>
    </Box>
  );
};
