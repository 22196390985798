// GLOBAL Static Constant to use
export const RouteLayouts = Object.freeze({
  HomeLayout: "HOME_LAYOUT",
  MainLayout: "MAIN_LAYOUT",
  BlankLayout: "BLANK_LAYOUT",
  RegistrationLayout: "REGISTRATION_LAYOUT",
});

export const USER_ROLE_ENUM = Object.freeze({
  ROLE_NORMAL_USER: "ROLE_NORMAL_USER",
  ROLE_REGISTERED_USER: "ROLE_REGISTERED_USER",
});

const startYear = 1970;
export const YEARS_LIST = ['N/A', ...Array.from(
  Array(new Date().getFullYear() - (startYear - 1)),
  (_, i) => (i + startYear).toString(),
)];

export const MATHA_ENUM = Object.freeze({
  "Uttaradi-Matha": "Uttaradi-Matha",
  "Rayara-Matha": "Rayara-Matha",
  "Mulbagalu Sripadaraja-Matha": "Mulbagalu Sripadaraja-Matha",
  "Palimaru-Matha": "Palimaru-Matha",
  "Adamaru-Matha": "Adamaru-Matha",
  "Krishnapura-Matha": "Krishnapura-Matha",
  "Puttige-Matha": "Puttige-Matha",
  "Shirooru-Matha": "Shirooru-Matha",
  "Sode-Matha": "Sode-Matha",
  "Kaniyooru-Matha": "Kaniyooru-Matha",
  "Pejavara-Matha": "Pejavara-Matha",
  "Bhandarakeri-Matha": "Bhandarakeri-Matha",
  "Subramanya-Matha": "Subramanya-Matha",
  "Chitrapura-Matha": "Chitrapura-Matha",
  "Bhimanakatte-Matha": "Bhimanakatte-Matha",
  "Sagarakatte Vyasaraja-Matha": "Sagarakatte Vyasaraja-Matha",
  "Sosale Vyasaraja-Matha": "Sosale Vyasaraja-Matha",
  "Kundapura Vyasaraja-Matha": "Kundapura Vyasaraja-Matha",
  "Baligaru Arya Akshobhya Tirtha-Matha":
    "Baligaru Arya Akshobhya Tirtha-Matha",
  "Kudli Arya Akshobhya Tirtha-Matha": "Kudli Arya Akshobhya Tirtha-Matha",
  "Tambehalli Madhava Tirtha-Matha": "Tambehalli Madhava Tirtha-Matha",
  "Shankara-Matha": "Shankara-Matha",
  Others: "Others",
});

export const MOTHER_TONGUE_ENUM = Object.freeze({
  Kannada: "Kannada",
  Marathi: "Marathi",
  Hindi: "Hindi",
  Konkani: "Konkani",
  Telugu: "Telugu",
  Tamil: "Tamil",
  Others: "Others",
});

// radio button
export const HOUSE_ENUM = Object.freeze({
  Own: "Own",
  Rented: "Rented",
  Leased: "Leased",
});

export const GOTRA_ENUM = Object.freeze({
  Aashmarathya: "Aashmarathya",
  Aatreyasa: "Aatreyasa",
  Aayasya: "Aayasya",
  Agastya: "Agastya",
  Aghamarshana: "Aghamarshana",
  Aja: "Aja",
  Angirasa: "Angirasa",
  Aoushanasa: "Aoushanasa",
  Arshtishena: "Arshtishena",
  Atithi: "Atithi",
  Atmabhu: "Atmabhu",
  Atri: "Atri",
  Aushija: "Aushija",
  Badarayana: "Badarayana",
  Baijamathita: "Baijamathita",
  Beejavapa: "Beejavapa",
  Bharadwaja: "Bharadwaja",
  Bhargava: "Bhargava",
  Bida: "Bida",
  Bruhaduktha: "Bruhaduktha",
  Deerghatamas: "Deerghatamas",
  Devarat: "Devarat",
  Dhanajaya: "Dhanajaya",
  Dhananjaya: "Dhananjaya",
  Durbhavaaha: "Durbhavaaha",
  Gaathina: "Gaathina",
  Galava: "Galava",
  Garga: "Garga",
  Gargeya: "Gargeya",
  Gautama: "Gautama",
  Gavishthi: "Gavishthi",
  Ghritakaushika: "Ghritakaushika",
  Haritasa: "Haritasa",
  Himodaka: "Himodaka",
  Hiranyaretas: "Hiranyaretas",
  Idhmavaaha: "Idhmavaaha",
  IndraKaushika: "IndraKaushika",
  Jamadagnya: "Jamadagnya",
  Kaamakaayana: "Kaamakaayana",
  Kanva: "Kanva",
  Kapi: "Kapi",
  Kapotaretas: "Kapotaretas",
  Karenupali: "Karenupali",
  Kashyapa: "Kashyapa",
  Kata: "Kata",
  Kathaka: "Kathaka",
  Kaundinya: "Kaundinya",
  Kaushika: "Kaushika",
  Koumanda: "Koumanda",
  Kushika: "Kushika",
  Kutsa: "Kutsa",
  Lohita: "Lohita",
  Mandavya: "Mandavya",
  Maunabhargava: "Maunabhargava",
  Mitrayu: "Mitrayu",
  Moudgalya: "Moudgalya",
  Mudgala: "Mudgala",
  Nidhruva: "Nidhruva",
  Paanika: "Paanika",
  Parashara: "Parashara",
  Poornamaasa: "Poornamaasa",
  Purana: "Purana",
  Putamanasa: "Putamanasa",
  Raghugana: "Raghugana",
  Raghuva: "Raghuva",
  Rathitara: "Rathitara",
  Reebha: "Reebha",
  Rouhina: "Rouhina",
  Roukshaka: "Roukshaka",
  Ruksha: "Ruksha",
  Saahala: "Saahala",
  Saambhavaaha: "Saambhavaaha",
  Saaravaaha: "Saaravaaha",
  Shaalaksha: "Shaalaksha",
  Shaandilya: "Shaandilya",
  Sharadvata: "Sharadvata",
  Shatamarshana: "Shatamarshana",
  Shatharamathara: "Shatharamathara",
  Shaunaka: "Shaunaka",
  Shhalankaayana: "Shhalankaayana",
  Shravanaka: "Shravanaka",
  Shrivatsa: "Shrivatsa",
  Shunaka: "Shunaka",
  Somarajaka: "Somarajaka",
  Somavaaha: "Somavaaha",
  Sumangala: "Sumangala",
  Suvarnaretas: "Suvarnaretas",
  SwantantraKapila: "SwantantraKapila",
  Swantantrapi: "Swantantrapi",
  Upamanyu: "Upamanyu",
  Utathya: "Utathya",
  Vaashishta: "Vaashishta",
  Vaatsya: "Vaatsya",
  Vabhdutak: "Vabhdutak",
  Vadhryashva: "Vadhryashva",
  Vainava: "Vainava",
  Vainya: "Vainya",
  Valeya: "Valeya",
  Vamadeva: "Vamadeva",
  Vamarathya: "Vamarathya",
  Vardoolasa: "Vardoolasa",
  Vatsa: "Vatsa",
  Vatsapurodhas: "Vatsapurodhas",
  Vedavishwasjyotisha: "Vedavishwasjyotisha",
  Vishnuvardhana: "Vishnuvardhana",
  Vishnuvriddha: "Vishnuvriddha",
  Vishwamitra: "Vishwamitra",
  Yagnavaaha: "Yagnavaaha",
  Yaska: "Yaska",
});

export const CHARANA_ENUM = Object.freeze({
  1: "1",
  2: "2",
  3: "3",
  4: "4",
});

export const RASHI_ENUM = Object.freeze({
  Mesha: "Mesha",
  Vrishabha: "Vrishabha",
  Mithuna: "Mithuna",
  Karka: "Karka",
  Simha: "Simha",
  Kanya: "Kanya",
  Tula: "Tula",
  Vrischika: "Vrischika",
  Dhanasu: "Dhanasu",
  Makara: "Makara",
  Kumbha: "Kumbha",
  Meena: "Meena",
});

export const NAKSHATHRA_ENUM = Object.freeze({
  "": [],
  Mesha: ["Ashvini", "Bharani", "Krttika"],
  Vrishabha: ["Krttika", "Rohini", "Mrigashira"],
  Mithuna: ["Mrigashira", "Ardra", "Punarvasu"],
  Karka: ["Punarvasu", "Pushya", "Ashlesha"],
  Simha: ["Magha", "PoorvaPhalguni", "UttaraPhalguni"],
  Kanya: ["UttaraPhalguni", "Hasta", "Citra"],
  Tula: ["Citra", "Swati", "Vishakha"],
  Vrischika: ["Vishakha", "Anuradha", "Jyestha"],
  Dhanasu: ["Moola", "Poorvashadha", "Uttarashadha"],
  Makara: ["Uttarashadha", "Shravana", "Dhanishta"],
  Kumbha: ["Dhanishta", "Shatabhisha", "PoorvaBhadra"],
  Meena: ["PoorvaBhadra", "UttaraBhadra", "Revati"],
});

export const HEIGHT_ENUM = Object.freeze({
  "4 feet": "122",
  "4 feet 1 inches": 125,
  "4 feet 2 inches": 127,
  "4 feet 3 inches": 130,
  "4 feet 4 inches": 132,
  "4 feet 5 inches": 135,
  "4 feet 6 inches": 137,
  "4 feet 7 inches": 140,
  "4 feet 8 inches": 142,
  "4 feet 9 inches": 145,
  "4 feet 10 inches": 147,
  "4 feet 11 inches": 150,
  "5 feet": 152,
  "5 feet 1 inches": 155,
  "5 feet 2 inches": 158,
  "5 feet 3 inches": 160,
  "5 feet 4 inches": 163,
  "5 feet 5 inches": 165,
  "5 feet 6 inches": 168,
  "5 feet 7 inches": 170,
  "5 feet 8 inches": 173,
  "5 feet 9 inches": 175,
  "5 feet 10 inches": 178,
  "5 feet 11 inches": 180,
  "6 feet": 183,
  "6 feet 1 inches": 185,
  "6 feet 2 inches": 188,
  "6 feet 3 inches": 191,
  "6 feet 4 inches": 193,
  "6 feet 5 inches": 196,
  "6 feet 6 inches": 198,
  "6 feet 7 inches": 201,
  "6 feet 8 inches": 203,
  "6 feet 9 inches": 206,
  "6 feet 10 inches": 208,
  "6 feet 11 inches": 211,
  "7 feet": 213,
  "7 feet 1 inches": 216,
  "7 feet 2 inches": 218,
  "7 feet 3 inches": 221,
  "7 feet 4 inches": 223,
  "7 feet 5 inches": 226,
  "7 feet 6 inches": 229,
  "7 feet 7 inches": 231,
  "7 feet 8 inches": 234,
  "7 feet 9 inches": 236,
  "7 feet 10 inches": 239,
  "7 feet 11 inches": 241,
});

// radio button
export const BUILD_ENUM = Object.freeze({
  Slim: "Slim",
  Average: "Average",
  Athletic: "Athletic",
  Heavy: "Heavy",
});

export const PROFILES_UPDATED_SINCE_ENUM = Object.freeze({
  7: "7 days",
  15: "15 days",
  30: "1 Month",
  90: "3 Months",
  180: "6 Months",
  366: "1 Year",
});

export const HIGHEST_EDUCATION_ENUM = Object.freeze({
  "Integrated PG": "Integrated PG",
  "PG Diploma": "PG Diploma",
  "Ph.D/Doctorate": "Ph.D/Doctorate",
  "MS (ENGG)": "MS (ENGG)",
  "MS (Surgery)": "MS (Surgery)",
  MD: "MD",
  MDS: "MDS",
  CA: "CA",
  CFA: "CFA",
  CMA: "CMA",
  CS: "CS",
  ICWA: "ICWA",
  LLM: "LLM",
  "M.A": "M.A",
  "M.Arch": "M.Arch",
  "M.Com": "M.Com",
  "M.Ed": "M.Ed",
  "M.Pharma": "M.Pharma",
  "M.Sc": "M.Sc",
  "M.Tech": "M.Tech",
  "Pandit/Purohit": "Pandit/Purohit",
  "MBA/PGDM": "MBA/PGDM",
  MCA: "MCA",
  MCM: "MCM",
  MPHIL: "MPHIL",
  MVSC: "MVSC",
  "B.A": "B.A",
  "B.Arch": "B.Arch",
  "B.B.A": "B.B.A",
  "B.Com": "B.Com",
  "B.Ed": "B.Ed",
  "B.Pharma": "B.Pharma",
  "B.Sc": "B.Sc",
  "B.Tech/B.E.": "B.Tech/B.E.",
  BCA: "BCA",
  BDS: "BDS",
  BFA: "BFA",
  "B.Sc (Agri)": "B.Sc (Agri)",
  BAMS: "BAMS",
  BPT: "BPT",
  BHM: "BHM",
  BVSC: "BVSC",
  Diploma: "Diploma",
  LLB: "LLB",
  MBBS: "MBBS",
  ITI: "ITI",
  "SSLC/CLASS10": "SSLC/CLASS10",
  PUC: "PUC",
  "BELOW 10": "BELOW 10",
  Others: "Others",
  // "Not Applicable": "Not Applicable",
});

export const POST_GRADUATION = Object.freeze({
  CA: "CA",
  CFA: "CFA",
  CMA: "CMA",
  CS: "CS",
  ICWA: "ICWA",
  LLM: "LLM",
  "M.A": "M.A",
  "M.Arch": "M.Arch",
  "M.Com": "M.Com",
  "M.Ed": "M.Ed",
  "M.Pharma": "M.Pharma",
  "M.Sc": "M.Sc",
  "M.Tech": "M.Tech",
  "Pandit/Purohit": "Pandit/Purohit",
  "MBA/PGDM": "MBA/PGDM",
  MCA: "MCA",
  MCM: "MCM",
  MPHIL: "MPHIL",
  "MS (ENGG)": "MS (ENGG)",
  "MS (Surgery)": "MS (Surgery)",
  MD: "MD",
  MDS: "MDS",
  MVSC: "MVSC",
  Others: "Others",
  "Not Applicable": "Not Applicable",
});

export const LOCATION_REGION = Object.freeze({
  "Karnataka - Belagavi Division": "Karnataka - Belagavi Division",
  "Karnataka - Kalaburgi Division": "Karnataka - Kalaburgi Division",
  "Karnataka - Mysuru Division": "Karnataka - Mysuru Division",
  "Karnataka - Bengaluru Division": "Karnataka - Bengaluru Division",
  Maharashtra: "Maharashtra",
  Tamilnadu: "Tamilnadu",
  "Andhra Pradesh": "Andhra Pradesh",
  Telangana: "Telangana",
  Goa: "Goa",
  Kerala: "Kerala",
  Gujarat: "Gujarat",
  Delhi: "Delhi",
  "Other Indian states": "Other Indian states",
  "Overseas - Other Countries": "Overseas - Other Countries",
});

export const GRADUATION_ENUM = Object.freeze({
  "B.A": "B.A",
  "B.Arch": "B.Arch",
  "B.B.A": "B.B.A",
  "B.Com": "B.Com",
  "B.Ed": "B.Ed",
  "B.Pharma": "B.Pharma",
  "B.Sc": "B.Sc",
  BFA: "BFA",
  "B.Sc (Agri)": "B.Sc (Agri)",
  BAMS: "BAMS",
  BPT: "BPT",
  "B.Tech/B.E.": "B.Tech/B.E.",
  "Pandit/Purohit": "Pandit/Purohit",
  BCA: "BCA",
  BDS: "BDS",
  BHM: "BHM",
  BVSC: "BVSC",
  Diploma: "Diploma",
  ITI: "ITI",
  LLB: "LLB",
  MBBS: "MBBS",
  "SSLC/CLASS10": "SSLC/CLASS10",
  PUC: "PUC",
  "BELOW 10": "BELOW 10",
  "Not Applicable": "Not Applicable",
});

// radio button
export const FAMILY_VALUE_ENUM = Object.freeze({
  Orthodox: "Orthodox",
  Traditional: "Traditional",
  Moderate: "Moderate",
  Liberal: "Liberal",
});

export const FAMILY_TYPE_ENUM = Object.freeze({
  "Joint Family": "Joint Family",
  "Nuclear Family": "Nuclear Family",
});

export const FAMILY_STATUS_ENUM = Object.freeze({
  "Middle Class": "Middle Class",
  "Upper-Middle class": "Upper-Middle class",
  "Rich/Affluent Class": "Rich/Affluent Class",
});

export const EMPTY_VALUE = "N/A";

export const INVITE_STATUS_ENUM = {
  SENT: "SENT",
  RECEIVED: "RECEIVED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  SYSTEM_REJECTED: "SYSTEM_REJECTED",
  SYSTEM_ACCEPTED: "SYSTEM_ACCEPTED",
  USER_ACCEPTED: "USER_ACCEPTED",
};

export const VALIDATION_RULES = {
  EMAIL:
    "([a-zA-Z0-9]+)([_.{1}])?([a-zA-Z0-9]+)@([a-zA-Z0-9]+)([.])([a-zA-Z.]+)",
};

export const POLL_CHAT_COUNT_API_MS = 60000;
export const POLL_CHAT_HISTORY_API_MS = 10000;
