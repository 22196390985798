import React, { Component } from "react";
import TextMessage from "./TextMessage";
import EmojiMessage from "./EmojiMessage";
// import FileMessage from "./FileMessage";
// import chatIconUrl from "./../../assets/chat-icon.svg";

class Message extends Component {
  checkForEmoji(message) {
    const emojiPattern = /\p{Emoji_Presentation}/gu;
    const removeEmoji = message?.replace(emojiPattern, "");
    const isOnlyEmojis = !removeEmoji?.length;
    return isOnlyEmojis;
  }

  _renderMessageOfType(type) {
    if (this.checkForEmoji(this.props.message?.chatMessage)) {
      return <EmojiMessage {...this.props.message} />;
    } else {
      return <TextMessage {...this.props.message} />;
    }
    // switch (type) {
    //   case "Text":
    //     return;
    //   case "Emoji":
    //     return <EmojiMessage {...this.props.message} />;
    //   case "File":
    //     return <FileMessage {...this.props.message} />;
    //   default:
    //     return <TextMessage {...this.props.message} />;
    // }
  }

  render() {
    let contentClassList = [
      "sc-message--content",
      this.props.message.loggedInUser ? "sent" : "received",
    ];
    return (
      <div className="sc-message">
        <div className={contentClassList.join(" ")}>
          {/*<div className="sc-message--avatar" style={{
            backgroundImage: `url(${chatIconUrl})`
          }}></div>*/}
          {this._renderMessageOfType()}
        </div>
      </div>
    );
  }
}

export default Message;
