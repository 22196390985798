import React, { Component } from "react";
import MessageList from "./MessageList";
import moment from "moment";

class MessageGroup extends Component {
  constructor(props) {
    super(props);
  }
  dateDiff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  formatDate(dateStr) {
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let date = new Date(dateStr);

    if (new Date().toDateString() === date.toDateString()) {
      return "Today";
    }

    if (this.dateDiff(date, new Date()) < 7) {
      const day = date.getDay();
      return days[day];
    }
    return moment(dateStr).format("DD/MM/YYYY");
  }

  render() {
    const {
      showDelete,
      onDeleteRowSelected,
      selectedDeleteRow,
      messages,
      imageUrl,
    } = this.props;
    return (
      <div>
        <div className="sc-message-date">
          {this.formatDate(this.props.date)}
        </div>
        <MessageList
          {...{
            showDelete,
            onDeleteRowSelected,
            selectedDeleteRow,
            messages,
            imageUrl,
          }}
        />
      </div>
    );
  }
}

export default MessageGroup;
