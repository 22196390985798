/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showMessage } from "../../../Utils/helper";
import { ReportProfile } from "../../../Store/Actions/matchesActionCreator";
import { CustomTextField, Button, StyledMenuItem } from "../../UI";
import Modal from "../Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import { useConfirm } from "../../ConfirmationModal";
import "./style.scss";

const validationSchema = yup.object({
  answer1: yup
    .string("Invalid Value")
    .trim()
    .required("Report Category cannot be empty"),
  answer2: yup
    .string("Invalid Value")
    .trim()
    .required("Details cannot be empty"),
});

const useStyles = makeStyles((theme) => ({
  error: {
    border: "1px solid red !important",
  },
}));

const ReportProfileModal = function ReportProfileModal({
  user,
  data,
  open,
  onClose,
  next,
  dispatchReportProfile,
  ...props
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [isOpen, toggleModal] = useState(open);
  const [blockUser, setBlockUser] = useState(false);
  const ConfirmationModal = useConfirm();
  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      answer1: "",
      answer2: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      setLoading(true);
      dispatchReportProfile(
        data,
        {
          answer1: formik.values.answer1,
          answer2: formik.values.answer2,
          blockUser: blockUser,
        },
        (resp) => {
          if (!!resp?.status) {
            setLoading(false);
            showMessage(resp?.result, "success");
            if (blockUser) {
              // props.history.push("/my-matches/");
            }
          } else {
            setLoading(false);
            showMessage(resp?.result, "error");
          }
          handleClose();
        },
        (err) => {
          setLoading(false);
          handleClose();
        }
      );
    },
  });
  const { palette } = useTheme();
  const handleClose = () => {
    toggleModal(!isOpen);
    onClose && onClose();
  };
  useEffect(() => {}, [formik.values]);

  const actions = [];
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
      <Modal
        onClose={handleClose}
        open={isOpen}
        title={""}
        isCustomTitle={true}
        actions={actions}
        loading={loading}
        isForm={true}
        onSubmit={formik.handleSubmit}
        maxWidth="sm"
      >
        <Box className="mainbox" style={{ gridTemplateRows: "100%" }}>
          <Box className="modelforum" style={{ width: "100%" }}>
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                marginTop: "8px",
                marginBottom: "24px",
                fontWeight: "bold",
              }}
            >
              Report/ Block Profile
            </Typography>
            <Typography
              style={{
                marginTop: "8px",
                marginBottom: "24px",
                color: "#000000",
              }}
            >
              You have the option to report this profile to the admin for
              further action or fully block the user, which will prevent them
              from viewing your profile or contacting you.
              <br /> <br />
              Reports remain anonymous, and the admin team will review and take
              action as needed. For more information, you can reach out on
              WhatsApp at{" "}
              <span fontSize={"1em"} variant="h6" color="textPrimary">
                <a href="https://wa.me/message/BN2R4ZFIMY4JM1">
                  +91 63639 21008                                                                                                                                                        
                </a>
              </span>{" "}
              or email at jspn.vvs@umapps.in <br /> <br />
              If you prefer to block the user, select the 'Report & Block'
              option.
            </Typography>
            <CustomTextField
              label="Report Category"
              select
              fullWidth={true}
              value={formik?.values?.answer1}
              onChange={formik.handleChange("answer1")}
              isRequired={true}
              variant={"standard"}
              helperText={
                formik.touched.answer1 && formik.errors.answer1 ? (
                  <span style={{ color: "red" }}>{formik.errors.answer1}</span>
                ) : null
              }
              errorStyle={classes.error}
            >
              <StyledMenuItem value="Profile is obscene/ fraud">
                Profile is obscene/fraud
              </StyledMenuItem>
              <StyledMenuItem value="Details are inappropriate">
                Details are inappropriate
              </StyledMenuItem>
              <StyledMenuItem value="Duplicate profile">
                Duplicate profile
              </StyledMenuItem>
              <StyledMenuItem value="Photo is not real">
                Photo is not real
              </StyledMenuItem>
              <StyledMenuItem value="Wrong phone number / not reachable">
                Phone number not reachable
              </StyledMenuItem>
              <StyledMenuItem value="Marriage is fixed / engaged">
                Marriage is fixed/engaged
              </StyledMenuItem>
              <StyledMenuItem value="Member's communication is arrogant / indecent">
                Member is arrogant/indecent
              </StyledMenuItem>
            </CustomTextField>
            <CustomTextField
              isRequired={true}
              fullWidth={true}
              multiline={true}
              rows={3}
              label="Complaint Details"
              id="answer2"
              name="answer2"
              value={formik.values.answer2}
              onChange={formik.handleChange("answer2")}
              helperText={
                formik.touched.answer2 && formik.errors.answer2 ? (
                  <span style={{ color: "red" }}>{formik.errors.answer2}</span>
                ) : null
              }
              style={{ marginBottom: "16px", marginTop: "3em" }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{
                fontWeight: "bold",
              }}
              type="submit"
            >
              Report to Admin
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              sx={{
                backgroundColor: "#fff",
                color: user.prime ? palette.secondary.main : palette.grey[500],
                width: "100%",
                borderColor: user.prime
                  ? palette.secondary.main
                  : palette.grey[500],
                "&:hover": {
                  backgroundColor: palette.secondary.main,
                  borderColor: palette.secondary.main,
                  color: "white",
                },
                marginTop: "1.5em",
              }}
              disableElevation={true}
              onClick={(e) => {
                e.preventDefault();
                if (user.prime) {
                  if (
                    formik.values.answer1 === "" ||
                    formik.values.answer2 === ""
                  ) {
                    showMessage("Please fill out all required fields", "error");
                    return;
                  }
                  ConfirmationModal({
                    title: "Are you sure you want to block this profile? ",
                    description:
                      "They will not able to view your profile or contact you. You can unblock them later from your my matches section.",
                    confirmationText: "Yes",
                    confirmationButtonProps: {
                      variant: "contained",
                      style: {
                        // backgroundColor: '',
                        color: "white",
                      },
                    },
                    cancellationButtonProps: {
                      style: {
                        // backgroundColor: '',
                        color: "red",
                      },
                    },
                    cancellationText: "No",
                  }).then(() => {
                    setBlockUser(true);
                    formik.handleSubmit();
                  });
                } else {
                  showMessage(
                    "Upgrade to VVS PRIME to block the profile OR you can report the profile to the admin",
                    "error"
                  );
                }
              }}
              // disabled={!user.prime}
            >
              Report & Block
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              sx={{
                backgroundColor: "#fff",
                color: palette.secondary.main,
                width: "100%",
                borderColor: palette.secondary.main,
                "&:hover": {
                  backgroundColor: palette.secondary.main,
                  borderColor: palette.secondary.main,
                  color: "white",
                },
                marginTop: "1.5em",
              }}
              disableElevation={true}
              onClick={(e) => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* </Spinner> */}
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReportProfile: (id, data, onSuccess, onError) =>
    dispatch(ReportProfile(id, data, onSuccess, onError)),
});

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(mapDispatchToProps)
// );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportProfileModal);
