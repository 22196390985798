import { enqueueSnackbar } from "../Store/Actions/notificationActionCreator";
import { logoutUser } from "../Store/Actions/userActionCreator";
import store from "../Store/configureStore";
import React from "react";

export const isMobileView = window.innerWidth < 600;

export const showMessage = (msg, type) => {
  store.dispatch(
    enqueueSnackbar({
      message: `${msg ? msg : type === "error" ? "Server Error" : ""}`,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: type,
      },
    })
  );
};

export function checkHttpStatus(status) {
  switch (status?.code) {
    case 403:
    case 400:
    case 429:
      showMessage(status?.message, "error");
      return false;
    case 401:
      showMessage("Unauthorised access. Please login again.", "error");
      // AuthenticationService.logoutUser();
      logout();
      return false;
    case 501:
      // showMessage(status?.message, "error");
      // AuthenticationService.logoutUser();
      return false;
    case 500:
    case 503:
      showMessage(status?.message, "error");
      return false;
    case 200:
    case 201:
    case 202:
      return true;
    default:
      return false;
  }
}

export function getLoginUser() {
  return localStorage.getItem("USER_INFO")
    ? JSON.parse(window.atob(localStorage.getItem("USER_INFO")))
    : "";
}

export function logout() {
  const logoutAction = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  store.dispatch(
    logoutUser(
      (resp) => {
        logoutAction();
      },
      (err) => logoutAction()
    )
  );
}

export const toISOStringWithTimezone = date => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      '.' + pad(date.getMilliseconds()) +
      diff + pad(tzOffset / 60) +
      ':' + pad(tzOffset % 60);
};

export const toTime = date => {
  let chatDate = new Date(date)
  return chatDate.getHours().toString().padStart(2,0) + ':' + chatDate.getMinutes().toString().padStart(2, 0)
}
