import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, CustomTextField, Spinner } from "../../Components/UI";
import { DeleteUser } from "../../Store/Actions/userActionCreator";
import { logout, showMessage } from "../../Utils/helper";
import * as yup from "yup";
import { useFormik } from "formik";

const validationSchema = yup.object({
  answer1: yup.string("Invalid Value").trim().required("Field cannot be empty"),
  answer2: yup.string("Invalid Value").trim().required("Field cannot be empty"),
  answer3: yup.string("Invalid Value").trim().required("Field cannot be empty"),
  answer4: yup.string("Invalid Value").trim().required("Field cannot be empty"),
  answer5: yup.string("Invalid Value").trim().required("Field cannot be empty"),
});

function ProfileDeletion({ dispatchDeleteUser, ...props }) {
  const isMobileView = window.innerWidth < 600;
  const [loading, setLoading] = React.useState(false);
  const [isNoteError, setNoteError] = React.useState(false);

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      handleUserDeletion(values);
    },
  });

  const handleUserDeletion = (values) => {
    setLoading(true);
    dispatchDeleteUser(
      values,
      (resp) => {
        if (!!resp?.status) {
          resp?.result && showMessage(resp?.result ?? resp?.result, "success");
          localStorage.clear();
          window.location.href = "/";
        } else {
          showMessage(resp?.result, "error");
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <Spinner loading={loading}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
          <Typography
            variant="h5"
            color={"#000000"}
            fontWeight="bold"
            paddingTop={"1em"}
            textAlign={isMobileView ? "center" : "left"}
          >
            Account Deletion
          </Typography>
          <Divider
            sx={{ borderColor: "rgba(102, 51, 153, 0.3)", my: "16px" }}
          />
          <FormControl sx={{ width: "100%", mb: "16px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Typography variant="h6" color={"#000000"}>
                Please state your reason for deleting account{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </FormLabel>
            <RadioGroup
              isRequired={true}
              value={formik?.values?.answer1}
              onChange={formik.handleChange("answer1")}
            >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Marriage settled ( from this website )"
                control={<Radio />}
                label="Marriage settled ( from this website )"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Marriage settled ( from another website )"
                control={<Radio />}
                label="Marriage settled ( from another website )"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="dont wish to continue"
                control={<Radio />}
                label="dont wish to continue"
              />
            </RadioGroup>
            {(!!formik.touched.answer1 || !!formik.errors.answer1) && (
              <FormHelperText sx={{ color: "red" }}>
                {formik.touched.answer1 && formik.errors.answer1}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "100%", mb: "16px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Typography variant="h6" color={"#000000"}>
                Please rate the ease of using our website{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </FormLabel>
            <RadioGroup
              isRequired={true}
              value={formik?.values?.answer2}
              onChange={formik.handleChange("answer2")}
            >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Excellent"
                control={<Radio />}
                label="Excellent"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Very Good"
                control={<Radio />}
                label="Very Good"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Average"
                control={<Radio />}
                label="Average"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Poor"
                control={<Radio />}
                label="Poor"
              />
            </RadioGroup>
            {(!!formik.touched.answer2 || !!formik.errors.answer2) && (
              <FormHelperText sx={{ color: "red" }}>
                {formik.touched.answer2 && formik.errors.answer2}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "100%", mb: "16px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Typography variant="h6" color={"#000000"}>
                Please rate the User Interface of our website{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </FormLabel>
            <RadioGroup
              isRequired={true}
              value={formik?.values?.answer3}
              onChange={formik.handleChange("answer3")}
            >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Excellent"
                control={<Radio />}
                label="Excellent"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Very Good"
                control={<Radio />}
                label="Very Good"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Average"
                control={<Radio />}
                label="Average"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Poor"
                control={<Radio />}
                label="Poor"
              />
            </RadioGroup>
            {(!!formik.touched.answer3 || !!formik.errors.answer3) && (
              <FormHelperText sx={{ color: "red" }}>
                {formik.touched.answer3 && formik.errors.answer3}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "100%", mb: "16px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Typography variant="h6" color={"#000000"}>
                Your Overall experience <span style={{ color: "red" }}>*</span>
              </Typography>
            </FormLabel>
            <RadioGroup
              isRequired={true}
              value={formik?.values?.answer4}
              onChange={formik.handleChange("answer4")}
            >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Excellent"
                control={<Radio />}
                label="Excellent"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Very Good"
                control={<Radio />}
                label="Very Good"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Average"
                control={<Radio />}
                label="Average"
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { color: "#000" } }}
                value="Poor"
                control={<Radio />}
                label="Poor"
              />
            </RadioGroup>
            {(!!formik.touched.answer4 || !!formik.errors.answer4) && (
              <FormHelperText sx={{ color: "red" }}>
                {formik.touched.answer4 && formik.errors.answer4}
              </FormHelperText>
            )}
          </FormControl>
          <Box sx={{ width: "100%" }}>
            <CustomTextField
              label="Please share your user experience or opinions, to improve our services"
              id="key"
              name="key"
              value={formik?.values?.answer5}
              onChange={formik.handleChange("answer5")}
              style={{ marginBottom: "16px", width: "100%" }}
              isRequired={true}
              minRows={3}
              multiline
              error={
                formik.touched.answer5 ? Boolean(formik.errors.answer5) : false
              }
              helperText={formik.touched.answer5 && formik.errors.answer5}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              mt: "8px",
              backgroundColor: "rgba(255, 39, 183, 0.1)",
              padding: isMobileView ? "16px" : "30px",
            }}
          >
            <Typography variant="h6" color={"#F86969"} fontWeight="bolder">
              NOTE:
            </Typography>
            <Typography color={"#000000"} sx={{ mt: "20px" }}>
            Please delete your account if you are no longer looking for alliance. After deleting your account; your account will be totally erased
              and you cannot login to your profile anymore.
            </Typography>
          </Box>
          <Divider
            sx={{ borderColor: "rgba(102, 51, 153, 0.3)", my: "30px" }}
          />
          <Box
            sx={{
              width: "100%",
              mt: "8px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobileView ? "column" : "row",
            }}
          >
            <Button
              style={{
                width: "100%",
                fontWeight: 900,
                color: "#000000",
                borderColor: "#000000",
              }}
              variant="outlined"
              size="large"
              onClick={(e) => {
                props.history.push("/my-profile");
              }}
            >
              CANCEL AND GO BACK
            </Button>
            <Button
              style={{
                width: "100%",
                fontWeight: 900,
                marginRight: "1em",
                backgroundColor: !!formik.isValid && "#F86969",
                color: "#fff",
                ...(!isMobileView
                  ? { marginLeft: "1em" }
                  : {
                    marginTop: "1em",
                  }),
              }}
              type={"submit"}
              variant="contained"
              size="large"
              disableElevation
              disabled={!formik.isValid}
            >
              YES, DELETE MY ACCOUNT
            </Button>
          </Box>
        </Box>
      </form>
    </Spinner>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchDeleteUser: (data, onSuccess, onError) =>
    dispatch(DeleteUser(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDeletion);
