import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, CompactCustomTextField, Spinner } from "../../Components/UI";
import {
  CheckRegisteredUser,
  FetchProfileDetails,
  ResetUserPassword,
  SendEmailUpdateOtp,
  SendMobileUpdateOtp,
  VerifyMobileUpdateOtp,
} from "../../Store/Actions/userActionCreator";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { logout, showMessage } from "../../Utils/helper";
import DoanteModal from "../../Components/Modals/ModalContexts/DonateModal";
import EditIcon from "@mui/icons-material/Edit";
import MobileOtpVerificationModal from "../../Components/Modals/ModalContexts/MobileOtpVerificationModal";
import EmailOtpVerificationModal from "../../Components/Modals/ModalContexts/EmailOtpVerificationModal";
import { VALIDATION_RULES } from "../../Utils/constants";
const validationSchema = yup.object({
  currentPassword: yup
    .string("Invalid password")
    .trim()
    .required("Current Passowrd cannot be empty"),
  newPassword: yup
    .string()
    .test("newPassword", "Both password need to be the same", function (value) {
      return this.parent.cpassword === value;
    })
    .required("New Password cannot be empty"),
  cpassword: yup
    .string()
    .test("cpassword", "Both password need to be the same", function (value) {
      return this.parent.newPassword === value;
    })
    .required("Confirm Password cannot be empty"),
});

function MyAccount({
  dispatchResetUserPassword,
  dispatchSendMobileUpdateOtp,
  dispatchVerifyMobileUpdateOtp,
  dispatchSendEmailUpdateOtp,
  dispatchVerifyEmailUpdateOtp,
  dispatchCheckRegisteredUser,
  dispatchFetchProfileDetails,
  dispatchGetOrderDetails,
  dispatchVerifyOrderDetails,
  user,
  ...props
}) {
  const isMobileView = window.innerWidth < 600;
  const isTabView = window.innerWidth > 600 && window.innerWidth < 1600;
  const [openDonateModal, setopenDonateModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [profilInfo, setProfileInfo] = React.useState(false);
  const [showEmailInput, toggleEmailInput] = React.useState(false);
  const [showMobileInput, toggleMobileInput] = React.useState(false);
  const [openEmailOtpModal, setOpenEmailOtpModal] = React.useState(false);
  const [openMobileOtpModal, setOpenMobileOtpModal] = React.useState(false);

  const [inputValues, setInputValues] = React.useState({
    email: "",
    mobile: "",
  });
  const [inputErrors, setInputError] = React.useState({
    email: "",
    mobile: "",
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      currentPassword: "",
      newPassword: "",
      cpassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      setLoading(true);
      dispatchResetUserPassword(
        {
          currentPassword: values?.currentPassword,
          newPassword: values?.newPassword,
        },
        (resp) => {
          if (!!resp?.status) {
            showMessage("Password changed successfully!", "success");
            props.history.push("/my-profile");
          } else {
            showMessage(resp?.result, "error");
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    },
  });
  const handleProfileDelete = () => {
    props.history.push("/my-profile/delete", {
      data: user,
    });
  };

  const fetchProfileDetails = () => {
    setLoading(true);
    dispatchFetchProfileDetails(
      {},
      (resp) => {
        if (resp?.status) {
          setProfileInfo(resp?.result);
          setInputValues({
            email: resp?.result?.emailId,
            mobile: resp?.result?.mobileNumber,
          });
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (!user?.accessToken) {
      props.history.push("/");
    }
    fetchProfileDetails();
  }, []);

  return (
    <Spinner loading={loading}>
      <a
        href="https://wa.me/message/BN2R4ZFIMY4JM1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
          <div className="account-top">
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight="bold"
              paddingBottom={"1em"}
              // paddingTop={"0.4em"}
              textAlign={isMobileView ? "center" : "left"}
            >
              My Account
            </Typography>
            {user?.prime ? (
              <div>
                {" "}
                <Typography
                  variant="h4"
                  color={"#16ce05"}
                  fontWeight="bold"
                  // paddingBottom={"1em"}
                  // paddingTop={"0.4em"}
                  textAlign={isMobileView ? "center" : "left"}
                >
                  You are a VVS PRIME user
                </Typography>
                <Typography
                  variant="h7"
                  color={"#16ce05"}
                  fontWeight="bold"
                  paddingBottom={"1em"}
                  // paddingTop={"0.4em"}
                  textAlign={isMobileView ? "center" : "left"}
                >
                  Your VVS PRIME privileges will expire on{" "}
                  {user.primeExpiryDate}. <br></br> You can renew again once it
                  is expired.
                </Typography>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {" "}
                <Button
                  style={{
                    fontWeight: 900,
                    color: "#16ce05",
                    borderColor: "#16ce05",
                    height: "max-content",
                    width: isMobileView ? "100%" : "auto%",
                  }}
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setopenDonateModal(true);
                  }}
                >
                  Upgrade To VVS Prime
                </Button>
                <Typography
                  marginTop={"4px"}
                  fontSize={"1em"}
                  variant="h6"
                  color=""
                  gutterBottom
                  to="/vvs-prime"
                  component={Link}
                >
                  Why VVS PRIME?
                </Typography>
              </div>
            )}
            {!!openDonateModal && (
              <DoanteModal
                open={openDonateModal}
                data={{ emailId: formik?.values?.userId, validateViaOTP: true }}
                onClose={() => {
                  setopenDonateModal(false);
                }}
                next={() => {
                  setopenDonateModal(false);
                  // handleClose();
                }}
              />
            )}
          </div>
          <Divider
            sx={{
              borderColor: "rgba(102, 51, 153, 0.3)",
              my: "16px",
              mb: "30px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: isMobileView ? "100%" : "100%",
              flexDirection: isMobileView ? "column" : "row",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                fontWeight="bold"
                variant="h6"
                textAlign={isMobileView ? "center" : "left"}
                textTransform="capitalize"
              >
                {`${profilInfo?.firstName ?? ""} ${profilInfo?.lastName ?? ""}`}
              </Typography>
              <Typography
                fontWeight="bold"
                variant="body2"
                color={"#0D1321"}
                textAlign={isMobileView ? "center" : "left"}
              >
                Profile ID : {`${profilInfo?.id ?? ""}`}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flex: 2 }}>
              {showEmailInput ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: isMobileView ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: isMobileView ? "100%" : isTabView ? "100%" : "50%",
                  }}
                >
                  <CompactCustomTextField
                    fullWidth={true}
                    label=""
                    id="email"
                    name="email"
                    value={inputValues?.email}
                    onChange={(e) => {
                      const emailPattern = new RegExp(VALIDATION_RULES?.EMAIL);
                      if (!emailPattern.test(e?.target?.value)) {
                        setInputError({
                          ...inputErrors,
                          [e?.target?.name]: "Email is Invalid",
                        });
                      } else {
                        if (emailPattern.test(e?.target?.value)) {
                          dispatchCheckRegisteredUser(
                            {
                              userId: e?.target?.value,
                            },
                            (resp) => {
                              if (!resp?.status) {
                                setInputError({
                                  ...inputErrors,
                                  [e?.target?.name]:
                                    "Email is already registered",
                                });
                              } else {
                                setInputError({
                                  ...inputErrors,
                                  [e?.target?.name]: "",
                                });
                              }
                            },
                            (err) => {
                              showMessage(err ?? "Server Error", "error");
                            }
                          );
                        }
                        setInputError({
                          ...inputErrors,
                          [e?.target?.name]: "",
                        });
                      }
                      setInputValues({
                        ...inputValues,
                        [e?.target?.name]: e?.target?.value,
                      });
                    }}
                    type="email"
                    error={Boolean(inputErrors?.email)}
                    helperText={!!inputErrors?.email && inputErrors?.email}
                    style={{ width: "100%" }}
                  />
                  <Button
                    style={{
                      fontWeight: 900,
                      color: "#000000",
                      borderColor: "#000000",
                      marginLeft: isMobileView ? "0px" : "16px",
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                    disabled={
                      inputValues?.email === profilInfo?.emailId ||
                      !!inputErrors?.email
                        ? true
                        : false
                    }
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      setLoading(true);
                      dispatchSendEmailUpdateOtp(
                        {
                          userId: inputValues?.email,
                        },
                        (resp) => {
                          setLoading(false);
                          if (!!resp?.status) {
                            setOpenEmailOtpModal(true);
                          } else {
                            showMessage(resp?.result, "error");
                          }
                        },
                        (error) => {
                          setLoading(false);
                        }
                      );
                    }}
                  >
                    Verify
                  </Button>
                  <Button
                    style={{
                      fontWeight: 900,
                      color: "#000000",
                      borderColor: "#000000",
                      marginLeft: isMobileView ? "0px" : "16px",
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      setInputValues({
                        ...inputValues,
                        email: profilInfo?.emailId,
                      });
                      setInputError({
                        ...inputErrors,
                        email: "",
                      });
                      toggleEmailInput(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    color={"#0D1321"}
                    textAlign={isMobileView ? "center" : "left"}
                  >
                    {profilInfo?.emailId ?? ""}
                  </Typography>
                  <IconButton
                    aria-label="EditIcon"
                    sx={{ marginLeft: "16px" }}
                    onClick={() => {
                      toggleMobileInput(false);
                      toggleEmailInput(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
              {showMobileInput ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: isMobileView ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: isMobileView ? "100%" : isTabView ? "100%" : "50%",
                  }}
                >
                  <CompactCustomTextField
                    fullWidth={true}
                    label=""
                    id="mobile"
                    name="mobile"
                    value={inputValues?.mobile}
                    onChange={(e) => {
                      if (e?.target?.value?.length !== 10) {
                        setInputError({
                          ...inputErrors,
                          [e?.target?.name]: "Mobile should be 10 digit",
                        });
                      } else {
                        if (
                          e?.target?.value?.length === 10 &&
                          e?.target?.value !== profilInfo?.mobileNumber
                        ) {
                          dispatchCheckRegisteredUser(
                            {
                              userId: e?.target?.value,
                            },
                            (resp) => {
                              if (!resp?.status) {
                                setInputError({
                                  ...inputErrors,
                                  [e?.target?.name]:
                                    "Mobile Number is already registered",
                                });
                              } else {
                                setInputError({
                                  ...inputErrors,
                                  [e?.target?.name]: "",
                                });
                              }
                            },
                            (err) => {
                              showMessage(err ?? "Server Error", "error");
                            }
                          );
                        }
                        setInputError({
                          ...inputErrors,
                          [e?.target?.name]: "",
                        });
                      }
                      setInputValues({
                        ...inputValues,
                        [e?.target?.name]: e?.target?.value,
                      });
                    }}
                    error={Boolean(inputErrors?.mobile)}
                    helperText={!!inputErrors?.mobile && inputErrors?.mobile}
                    style={{ width: "100%" }}
                  />
                  <Button
                    style={{
                      fontWeight: 900,
                      color: "#000000",
                      borderColor: "#000000",
                      marginLeft: isMobileView ? "0px" : "16px",
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                    variant="contained"
                    disableElevation
                    disabled={
                      inputValues?.mobile === profilInfo?.mobileNumber ||
                      !!inputErrors?.mobile
                        ? true
                        : false
                    }
                    onClick={() => {
                      setLoading(true);
                      dispatchSendMobileUpdateOtp(
                        {
                          userId: inputValues?.mobile,
                        },
                        (resp) => {
                          setLoading(false);
                          if (!!resp?.status) {
                            setOpenMobileOtpModal(true);
                          } else {
                            showMessage(resp?.result, "error");
                          }
                        },
                        (error) => {
                          setLoading(false);
                        }
                      );
                    }}
                  >
                    Verify
                  </Button>
                  <Button
                    style={{
                      fontWeight: 900,
                      color: "#000000",
                      borderColor: "#000000",
                      marginLeft: isMobileView ? "0px" : "16px",
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      setInputValues({
                        ...inputValues,
                        mobile: profilInfo?.mobileNumber,
                      });
                      setInputError({
                        ...inputErrors,
                        mobile: "",
                      });
                      toggleMobileInput(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    color={"#0D1321"}
                    textAlign={isMobileView ? "center" : "left"}
                  >
                    {profilInfo?.mobileNumber ?? ""}
                  </Typography>
                  <IconButton
                    aria-label="EditIcon"
                    sx={{ marginLeft: "16px" }}
                    onClick={() => {
                      toggleEmailInput(false);
                      toggleMobileInput(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box></Box>
          </Box>
          <Divider
            sx={{
              borderColor: "rgba(102, 51, 153, 0.3)",
              my: "16px",
              mt: "30px",
            }}
          />
          <Typography
            variant="h5"
            color={"#000000"}
            fontWeight="bold"
            textAlign={"left"}
          >
            New Password
          </Typography>
          <Box
            sx={{
              width: isMobileView ? "100%" : "50%",
              mt: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isMobileView ? "column" : "row",
            }}
          >
            <Typography
              color={"#000000"}
              textAlign={"left"}
              sx={{
                width: isMobileView ? "100%" : "50%",
              }}
            >
              Enter Current password
            </Typography>
            <CompactCustomTextField
              fullWidth={true}
              label=""
              id="currentPassword"
              name="currentPassword"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.currentPassword
                  ? Boolean(formik.errors.currentPassword)
                  : false
              }
              type="password"
              helperText={
                formik.touched.currentPassword && formik.errors.currentPassword
              }
              style={{ width: isMobileView ? "100%" : "50%" }}
            />
          </Box>
          <Box
            sx={{
              width: isMobileView ? "100%" : "50%",
              mt: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isMobileView ? "column" : "row",
            }}
          >
            <Typography
              color={"#000000"}
              textAlign={"left"}
              sx={{
                width: isMobileView ? "100%" : "50%",
              }}
            >
              Enter New password
            </Typography>
            <CompactCustomTextField
              fullWidth={true}
              label=""
              id="newPassword"
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              type="password"
              error={
                formik.touched.newPassword
                  ? Boolean(formik.errors.newPassword)
                  : false
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              style={{ width: isMobileView ? "100%" : "50%" }}
            />
          </Box>
          <Box
            sx={{
              width: isMobileView ? "100%" : "50%",
              mt: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isMobileView ? "column" : "row",
            }}
          >
            <Typography
              color={"#000000"}
              textAlign={"left"}
              sx={{
                width: isMobileView ? "100%" : "50%",
              }}
            >
              Confirm New password
            </Typography>
            <CompactCustomTextField
              fullWidth={true}
              label=""
              id="cpassword"
              name="cpassword"
              value={formik.values.cpassword}
              onChange={formik.handleChange}
              type="password"
              error={
                formik.touched.cpassword
                  ? Boolean(formik.errors.cpassword)
                  : false
              }
              helperText={formik.touched.cpassword && formik.errors.cpassword}
              style={{ width: isMobileView ? "100%" : "50%" }}
            />
          </Box>

          <Divider
            sx={{ borderColor: "rgba(102, 51, 153, 0.3)", my: "30px" }}
          />
          <Box
            sx={{
              width: isMobileView ? "100%" : "50%",
              mt: "8px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobileView ? "column" : "row",
            }}
          >
            <Button
              style={{
                width: "100%",
                fontWeight: 900,
                color: "#000000",
                borderColor: "#000000",
              }}
              variant="outlined"
              size="large"
              onClick={() => {
                props.history.push("/my-profile");
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "100%",
                fontWeight: 900,
                marginRight: "1em",
                ...(!isMobileView
                  ? { marginLeft: "1em" }
                  : {
                      marginTop: "1em",
                    }),
              }}
              type="submit"
              variant="contained"
              size="large"
              disableElevation
              // onClick={handleUserDeletion}
            >
              Update Password
            </Button>
          </Box>

          <Divider
            sx={{ borderColor: "rgba(102, 51, 153, 0.3)", my: "30px" }}
          />

          <Box
            sx={{
              width: "100%",
              mt: "8px",
              backgroundColor: "rgba(255, 39, 183, 0.1)",
              padding: isMobileView ? "16px" : "30px",
            }}
          >
            <Typography
              variant="h5"
              color={"#000000"}
              fontWeight="bold"
              textAlign={"left"}
            >
              Delete Account
            </Typography>

            <Typography color={"#000000"} sx={{ mt: "20px" }}>
              Please delete your account if you are no longer looking for
              alliance. After deleting your account; your account will be
              totally erased and you cannot login to your profile anymore.
            </Typography>
            <Button
              style={{
                fontWeight: 900,
                color: "#F86969",
                borderColor: "#F86969",
                height: "max-content",
                marginTop: "16px",
                // marginLeft: "1em",
              }}
              variant="outlined"
              size="large"
              onClick={handleProfileDelete}
            >
              Delete Account
            </Button>
          </Box>
        </Box>
        {/* </Box> */}
      </form>
      {!!openEmailOtpModal && (
        <EmailOtpVerificationModal
          open={openEmailOtpModal}
          blockClose={false}
          data={{ emailId: inputValues?.email, validateNewOTP: true }}
          onClose={() => {
            setOpenEmailOtpModal(false);
            toggleEmailInput(false);
          }}
          next={() => {
            fetchProfileDetails();
            toggleEmailInput(false);
            setOpenEmailOtpModal(false);
          }}
        />
      )}
      {!!openMobileOtpModal && (
        <MobileOtpVerificationModal
          open={openMobileOtpModal}
          data={{
            mobileNumber: inputValues?.mobile,
            validateNewOTP: true,
          }}
          blockClose={false}
          onClose={() => {
            toggleMobileInput(false);
            setOpenMobileOtpModal(false);
          }}
          next={(resp) => {
            fetchProfileDetails();
            toggleMobileInput(false);
            setOpenMobileOtpModal(false);
          }}
        />
      )}
    </Spinner>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchResetUserPassword: (data, onSuccess, onError) =>
    dispatch(ResetUserPassword(data, onSuccess, onError)),
  dispatchSendMobileUpdateOtp: (data, onSuccess, onError) =>
    dispatch(SendMobileUpdateOtp(data, onSuccess, onError)),
  dispatchVerifyMobileUpdateOtp: (data, onSuccess, onError) =>
    dispatch(VerifyMobileUpdateOtp(data, onSuccess, onError)),
  dispatchSendEmailUpdateOtp: (data, onSuccess, onError) =>
    dispatch(SendEmailUpdateOtp(data, onSuccess, onError)),
  dispatchCheckRegisteredUser: (payload, onSuccess, onError) =>
    dispatch(CheckRegisteredUser(payload, onSuccess, onError)),
  dispatchFetchProfileDetails: (type, onSuccess, onError) =>
    dispatch(FetchProfileDetails(type, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
