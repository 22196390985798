import * as constants from "../constants";

const defaultState = {
  notificationCount: 0,
};

export default function SystemReducer(state = defaultState, action) {
  switch (action.type) {
    case constants.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };
    default:
      return state;
  }
}
