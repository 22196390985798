import { Box, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  SendEmailOtp,
  VerifyEmailOtp,
  SendAccountOtp,
  VerifyForgetPwOtp,
  VerifyEmailUpdateOtp,
} from "../../../Store/Actions/userActionCreator";
import Modal from "../Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { withRouter } from "react-router-dom";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import { EmailVerifiedImg } from "../../../Assets/svg";
import { Button } from "../../UI";
import { showMessage } from "../../../Utils/helper";

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: "flex",
    flex: "1 1 15%",
    // border: '1px solid black',
  },

  otpInputStyle: {
    width: "48px !important",
    height: "48px !important",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "#663399",
    color: "#663399",
    fontSize: "24px",
    fontWeight: 600,
  },
  error: {
    border: "1px solid red !important",
  },
}));

const validationSchema = yup.object({
  key: yup.string().nullable().required("Email/Mobile No cannot be empty"),
  otp: yup
    .string()
    .length(4, "OTP should be 4 digit")
    .required("OTP cannot be empty"),
});

function EmailOtpVerificationModal({
  data,
  open,
  next,
  onClose,
  dispatchSendEmailOtp,
  dispatchVerifyEmailOtp,
  dispatchSendAccountOtp,
  dispatchVerifyForgetPwOtp,
  dispatchVerifyEmailUpdateOtp,
  ...props
}) {
  const classes = useStyles();
  const [isOpen, toggleModal] = useState(open);
  const [loading, toggleLoading] = useState(false);

  const [hasError, setHasError] = useState(false);

  const handleClose = () => {
    // Setting form data to null before closing

    toggleModal(!isOpen);
    onClose && onClose();
  };

  const otpHandler = (otp) => {
    formik.setFieldValue("otp", otp, true);
    if (otp.length !== 4) {
      setHasError(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
      key: data?.emailId ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      const payload = {
        ...values,
      };
      toggleLoading(true);
      const verifyOtp = !!data?.validateNewOTP
        ? dispatchVerifyEmailUpdateOtp
        : !!data?.validateViaOTP
          ? dispatchVerifyForgetPwOtp
          : dispatchVerifyEmailOtp;
      verifyOtp(
        payload,
        (resp) => {
          if (!!resp?.status) {
            toggleLoading(false);
            !data?.validateViaOTP && showMessage(resp?.result, "success");
            !!next && next(resp);
          } else {
            toggleLoading(false);
            showMessage(resp?.result, "error");
          }
        },
        (err) => {
          toggleLoading(false);
          showMessage(err, "error");
        }
      );
    },
  });

  const SendEmailOtp = () => {
    toggleLoading(true);
    const sendOtp = !!data?.validateViaOTP
      ? dispatchSendAccountOtp
      : dispatchSendEmailOtp;
    sendOtp(
      !!data?.validateViaOTP ? { userId: data?.emailId } : {},
      (resp) => {
        if (!!resp?.status) {
          resetTimer('emailcaller');
          showMessage(resp?.result, "success");
        } else {
          showMessage(resp?.result, "error");
        }
        toggleLoading(false);
      },
      (err) => {
        toggleLoading(false);
        handleClose();
        // showMessage(err, "error");
      }
    );
  };

  useEffect(() => {
    formik.setFieldValue("key", data?.emailId);
    if (
      (data?.hasOwnProperty("validateViaOTP") && !data?.validateViaOTP) ||
      (data?.hasOwnProperty("validateNewOTP") && !data?.validateNewOTP)
    )
      SendEmailOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [timer, setTimer] = useState(60);    
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);
  

  const resetTimer = function (e) {
    if (timer===0 && e==='clickbutton') {
      SendEmailOtp();
    }else if (timer===0 && e === 'emailcaller'){
      setTimer(60);
    }
  };



  const actions = [];
  // let email = "";
  // if (!!data?.emailId) {
  //   email = data?.emailId.split("@");
  //   email =
  //     Array(email?.[0]?.length - 1)
  //       ?.fill("*")
  //       ?.join("") +
  //     "@" +
  //     email?.[1];
  // }
  return (
    <Modal
      onClose={handleClose}
      blockClose={!data?.validateViaOTP}
      open={isOpen}
      title={
        <Typography
          variant="h4"
          style={{
            textAlign: "center",
            marginTop: "8px",
            marginBottom: "24px",
            // fontFamily: "Montserrat",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          {!!data?.validateViaOTP ? "OTP Verification" : "Email Verification"}
        </Typography>
      }
      keepMounted
      isCustomTitle={true}
      actions={actions}
      loading={loading}
      isForm={true}
      onSubmit={formik.handleSubmit}
      maxWidth="sm"
    >
      <Box
        style={{
          // padding: "0 15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <EmailVerifiedImg style={{ width: "70%", height: "100%" }} />
        <Typography
          variant="body1"
          color={"#000000"}
          marginTop={"20px"}
          marginBottom={"10px"}
          textAlign="center"
        >
          {`Please enter the OTP sent to your registered ${
            !Number(data?.emailId) ? "Email" : "Mobile Number"
          } ${data?.emailId} to
          continue setting up your account`}
        </Typography>
        {!Number(data?.emailId) && (
          <Typography fontSize={"0.8em"} color={"red"} marginBottom={"25px"}>
            Please check the Spam/Promotions folder for Email OTP
          </Typography>
        )}
        <Typography
          variant="body1"
          color={"#000000"}
          marginBottom={"15px"}
          marginLeft={"-30px"}
        >
          Enter the 4 digit OTP
        </Typography>
        <OtpInput
          inputStyle={classes.otpInputStyle}
          numInputs={4}
          hasErrored={hasError}
          errorStyle={classes.error}
          onChange={otpHandler}
          separator={<span>&nbsp;</span>}
          isInputNum={true}
          shouldAutoFocus
          value={formik.values.otp}
        />
        {!data?.validateNewOTP ? (
          <Typography
            variant="caption"
            color={"#898686"}
            marginTop={"1px"}
            marginBottom={"50px"}
          >
            Havent received yet?{" "}
            <Typography
              variant="caption"
              color={ timer===0?"#5671FF":"black"}
              marginTop={"1px"}
              marginBottom={"50px"}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                resetTimer('clickbutton');
              }}
            >
              Resend OTP {!!timer && `(${timer})`}
            </Typography>
          </Typography>
        ) : (
          <Box sx={{ marginBottom: "50px" }} />
        )}
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            style={{
              width: "40%",
              fontWeight: 900,
              color: "#000000",
              borderColor: "#000000",
             
            }}
            variant="outlined"
            type="button"
            onClick={(e) => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "40%", fontWeight: "bold", marginLeft: "1em", }}
            type="submit"
          >
            VERIFY
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSendEmailOtp: (payload, onSuccess, onError) =>
    dispatch(SendEmailOtp(payload, onSuccess, onError)),
  dispatchVerifyEmailOtp: (payload, onSuccess, onError) =>
    dispatch(VerifyEmailOtp(payload, onSuccess, onError)),
  dispatchSendAccountOtp: (payload, onSuccess, onError) =>
    dispatch(SendAccountOtp(payload, onSuccess, onError)),
  dispatchVerifyForgetPwOtp: (payload, onSuccess, onError) =>
    dispatch(VerifyForgetPwOtp(payload, onSuccess, onError)),
  dispatchVerifyEmailUpdateOtp: (payload, onSuccess, onError) =>
    dispatch(VerifyEmailUpdateOtp(payload, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailOtpVerificationModal));
