import * as constants from '../constants';

const defaultState = [];

export default function notificationReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.ENQUEUE_SNACKBAR:
            return [
                ...state,
                {
                    key: action.key,
                    ...action.notification,
                },
            ];

        case constants.CLOSE_SNACKBAR:
            return [
                ...state.map((notification) =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification },
                ),
            ];

        case constants.REMOVE_SNACKBAR:
            return [
                ...state.filter(
                    (notification) => notification.key !== action.key,
                ),
            ];

        default:
            return state;
    }
}
