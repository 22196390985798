import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  Grid,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  CustomTextField,
  StyledMenuItem,
  Button,
  Spinner,
} from "../../Components/UI";
import "./Registration.scss";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import {
  CheckRegisteredEmailAndMobile,
  CheckRegisteredUser,
  CreateUser,
} from "../../Store/Actions/userActionCreator";
import EmailOtpVerificationModal from "../../Components/Modals/ModalContexts/EmailOtpVerificationModal";
import MobileOtpVerificationModal from "../../Components/Modals/ModalContexts/MobileOtpVerificationModal";
import { showMessage } from "../../Utils/helper";
import { USER_ROLE_ENUM } from "../../Utils/constants";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useConfirm } from "../../Components/ConfirmationModal";
function Register({
  dispatchCreateUser,
  dispatchCheckRegisteredUser,
  dispatchCheckRegisteredEmailAndMobile,
  ...props
}) {
  const isMobileView = window.innerWidth < 600;
  const [openEmailOtpModal, setOpenEmailOtpModal] = useState(false);
  const [openMobileOtpModal, setOpenMobileOtpModal] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isMobileFocused, setIsMobileFocused] = useState(false);
  const [routeData, setRouteData] = useState({});
  const [loading, toggleLoading] = useState(false);

  const ConfirmationModal = useConfirm();

  // let emailIdSchemaValided = false;
  const emailIdSchema = yup
    .string("Invalid Email")
    .email("Enter a valid email")
    .trim()
    .required("Email cannot be empty");

  // let mobileSchemaValided = false;
  const mobileSchema = yup
    .string("Invalid alternate contact no.")
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .length(10, "Mobile Number should 10 digit")
    .trim()
    .required("Mobile number cannot be empty");
  const validationSchema = yup.object({
    firstName: yup
      .string("Invalid first Name")
      .trim()
      .required("First Name cannot be empty")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .min(3)
      .max(15),
    lastName: yup
      .string("Invalid last Name")
      .trim()
      .required("Last Name cannot be empty")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .min(3)
      .max(15),
    mobileNumber: mobileSchema
      .test("mobileNumber", "Mobile Number is already registered.", (value) => {
        return new Promise((resolve, reject) => {
          mobileSchema.isValid(value).then((isValid) => {
            if (!!value && isValid && !!isMobileFocused) {
              dispatchCheckRegisteredUser(
                {
                  userId: value,
                },
                (resp) => {
                  resolve(!!resp?.status);
                  // mobileSchemaValided = !!resp?.status;
                },
                (err) => {
                  showMessage(err ?? "Server Error", "error");
                  resolve(false);
                }
              );
            } else {
              // resolve(mobileSchemaValided);
              resolve(true);
            }
          });
        });
      })
      .required("Mobile Number cannot be empty"),
    emailId: emailIdSchema.test(
      "emailId",
      "email is already registered.",
      (value) => {
        return new Promise((resolve, reject) => {
          emailIdSchema.isValid(value).then((isValid) => {
            if (!!value && isValid && !!isEmailFocused) {
              dispatchCheckRegisteredUser(
                {
                  userId: value,
                },
                (resp) => {
                  if (!resp?.status) {
                    resolve(false);
                    // resolve(!!resp?.status);
                    // emailIdSchemaValided = !!resp?.status;
                  } else {
                    resolve(true);
                  }
                },
                (err) => {
                  showMessage(err ?? "Server Error", "error");
                  resolve(false);
                }
              );
            } else {
              // resolve(emailIdSchemaValided);
              resolve(true);
            }
          });
        });
      }
    ),
    gender: yup.string("").trim().required("Gender cannot be empty"),
    password: yup
      .string()
      .min(4)
      .test("password", "Both password need to be the same", function (value) {
        return this.parent.cpassword === value;
      })
      .required("Password cannot be empty"),
    cpassword: yup
      .string()
      .test("cpassword", "Both password need to be the same", function (value) {
        return this.parent.password === value;
      })
      .required("Confirm password cannot be empty")
      .min(4),
    dateOfBirth: yup
      .string()
      .trim()
      .test("dateOfBirth", "age must be atleast 18 years", function (value) {
        return (
          Number(moment().format("YYYY") - moment(value).format("YYYY")) >= 18
        );
      })
      .nullable()
      .required("Date Of Birth cannot be empty"),
    profileCreatedBy: yup
      .string()
      .trim()
      .required("Profile created by cannot be empty"),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      gender: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      emailId: "",
      password: "",
      cpassword: "",
      dateOfBirth: "",
      profileCreatedBy: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, err) => {
      const payload = {
        ...values,
        dateOfBirth: moment(values?.dateOfBirth).format("DD/MM/YYYY"),
      };
      dispatchCheckRegisteredEmailAndMobile(
        {
          mobileNumber: values?.mobileNumber,
          emailId: values?.emailId,
        },
        (resp) => {
          toggleLoading(false);
          if (!!resp?.status) {
            ConfirmationModal({
              description: `OTP will be sent on ${payload?.emailId} and ${payload?.mobileNumber} for verification.`,
            })
              .then(() => {
                toggleLoading(true);
                dispatchCreateUser(
                  payload,
                  (resp) => {
                    if (!!resp?.status) {
                      toggleLoading(false);
                      if (!resp?.result?.emailVerified) {
                        setOpenEmailOtpModal(true);
                      } else if (!resp?.result?.mobileVerified) {
                        setOpenMobileOtpModal(true);
                      } else if (
                        !!resp?.result &&
                        resp?.result?.roles[resp?.result?.roles?.length - 1]
                          ?.authority === USER_ROLE_ENUM.ROLE_NORMAL_USER
                      ) {
                        props.history.push("/register/basic");
                      } else {
                        props.history.push("/");
                      }
                    } else {
                      toggleLoading(false);
                      showMessage(resp?.result, "error");
                    }
                  },
                  (err) => {
                    toggleLoading(false);
                  }
                );
              })
              .catch(() => {
                toggleLoading(false);
              });
          } else {
            showMessage(resp?.result, "error");
          }
        },
        (err) => {
          toggleLoading(false);
        }
      );
    },
  });

  useEffect(() => {
    const data = props?.location?.state ?? null;
    setRouteData(data ?? {});
    if (!!data && !data?.emailVerified) {
      setOpenEmailOtpModal(true);
    } else if (!!data && !data?.mobileVerified) {
      setOpenMobileOtpModal(true);
    }
  }, [props?.location?.state]);

  return (
    // refectoring
    <Box className="register_verify">
      <a
        href="https://wa.me/message/BN2R4ZFIMY4JM1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <Typography variant="h4" fontWeight={"bold"}>
        Welcome to JSPN Vadhu Vara Samyojana!
      </Typography>
      <Typography variant="h6" color={"#434343"} marginBottom={2}>
        You are a few steps away from being a registered member. To get started,
        please register yourself and verify. Ensure that you enter correct Email
        and Mobile Number
      </Typography>
      <Typography
        variant="h12"
        color={"#b0001f"}
        style={{ fontWeight: "bold" }}
      >
        Note: Please make sure you are seeking for First Marriage only. <br />
        <br />
        <br />
      </Typography>
      <Spinner loading={loading}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            justifyContent={"space-between"}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={4} margin={"auto 0"}>
              <Typography color={"#434343"}>
                {" "}
                Vadhu / Vara Gender <span style={{ color: "red" }}>
                  *
                </span> :{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} justifyContent={"space-between"}>
              <RadioGroup
                row={true}
                id="gender"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="MALE"
                  control={<Radio />}
                  label="MALE"
                />
                <FormControlLabel
                  value="FEMALE"
                  control={<Radio />}
                  label="FEMALE"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <FormHelperText
            error={
              formik.touched.gender ? Boolean(formik.errors.gender) : false
            }
          >
            {!!formik.touched.gender && formik.errors.gender}
          </FormHelperText>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Vadhu / Vara First Name"
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName
                    ? Boolean(formik.errors.firstName)
                    : false
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                className="custom_text_box"
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Vadhu / Vara Last Name"
                id="lastName"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName
                    ? Boolean(formik.errors.lastName)
                    : false
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                className="custom_text_box"
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="10 digit Mobile Number"
                id="mobileNumber"
                name="mobileNumber"
                value={formik.values.mobileNumber.trim()}
                onChange={(e) => {
                  e.target.value = e.target.value.trim();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  setIsMobileFocused(false);
                  formik.handleBlur(e);
                }}
                onFocus={(e) => {
                  setIsMobileFocused(true);
                }}
                error={
                  formik.touched.mobileNumber
                    ? Boolean(formik.errors.mobileNumber)
                    : false
                }
                helperText={
                  formik.touched.mobileNumber && formik.errors.mobileNumber
                }
                className="custom_text_box"
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Email-ID"
                id="emailId"
                name="emailId"
                value={formik.values.emailId.trim()}
                onChange={(e) => {
                  e.target.value = e.target.value.trim();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  setIsEmailFocused(false);
                  formik.handleBlur(e);
                }}
                onFocus={(e) => {
                  setIsEmailFocused(true);
                }}
                error={
                  formik.touched.emailId
                    ? Boolean(formik.errors.emailId)
                    : false
                }
                helperText={formik.touched.emailId && formik.errors.emailId}
                className="custom_text_box"
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Password"
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password
                    ? Boolean(formik.errors.password)
                    : false
                }
                InputProps={{
                  type: "password",
                }}
                helperText={formik.touched.password && formik.errors.password}
                className="custom_text_box"
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Confirm Password"
                id="cpassword"
                name="cpassword"
                value={formik.values.cpassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.cpassword
                    ? Boolean(formik.errors.cpassword)
                    : false
                }
                InputProps={{
                  type: "password",
                }}
                helperText={formik.touched.cpassword && formik.errors.cpassword}
                className="custom_text_box"
                isRequired={true}
                variant={"standard"}
              />
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  // label="Date of Birth"
                  // id="dateOfBirth"
                  // name="dateOfBirth"
                  minDate={moment("1977-01-01").toDate()}
                  disableCloseOnSelect={false}
                  maxDate={moment().subtract(18, "years").toDate()}
                  inputFormat="dd/MM/yyyy"
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={
                    !!formik.values.dateOfBirth
                      ? moment(formik.values.dateOfBirth)?.valueOf() > 0
                        ? formik.values.dateOfBirth
                        : null
                      : null
                  }
                  onChange={(e) => {
                    if (e !== null) {
                      formik.setFieldValue("dateOfBirth", moment(e), true);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant={"standard"}
                      label="Vadhu / Vara Date of Birth"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      className="custom_text_box"
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      value={
                        !!formik.values.dateOfBirth
                          ? moment(formik.values.dateOfBirth)?.valueOf() > 0
                            ? moment(formik.values.dateOfBirth).format(
                                "dd/MM/yyyy"
                              )
                            : null
                          : null
                      }
                      error={
                        formik.touched.dateOfBirth
                          ? Boolean(formik.errors.dateOfBirth)
                          : false
                      }
                      helperText={
                        formik.touched.dateOfBirth && formik.errors.dateOfBirth
                      }
                      isRequired={true}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} margin={"auto 0"}>
              <CustomTextField
                label="Profile Created by"
                id="profileCreatedBy"
                name="profileCreatedBy"
                value={formik.values.profileCreatedBy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.profileCreatedBy
                    ? Boolean(formik.errors.profileCreatedBy)
                    : false
                }
                helperText={
                  formik.touched.profileCreatedBy &&
                  formik.errors.profileCreatedBy
                }
                select
                fullWidth={true}
                defaultValue=""
                className="custom_text_box"
                isRequired={true}
                variant={"standard"}
              >
                <StyledMenuItem value={"Self"}>Self</StyledMenuItem>
                <StyledMenuItem value={"Father"}>Father</StyledMenuItem>
                <StyledMenuItem value={"Mother"}>Mother</StyledMenuItem>
                <StyledMenuItem value={"Sibling"}>Sibling</StyledMenuItem>
                <StyledMenuItem value={"Relative"}>Relative</StyledMenuItem>
              </CustomTextField>
            </Grid>
          </Grid>
          <Grid columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <HorizontalLine />
            <Grid
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "flex-end",
                ...(isMobileView && {
                  alignItems: "flex-end",
                  flexDirection: "column",
                  justifyContent: "center",
                }),
              }}
            >
              <Button
                variant="link"
                size="large"
                type="button"
                sx={{
                  fontSize: "1em",
                  width: "100%",
                  fontFamily: "Roboto",
                  color: "#434343",
                  borderRadius: "0.7rem",
                  textTransform: "none",
                  marginRight: "16px",
                  display: "block",
                  "& span": {
                    color: "#663399",
                    marginLeft: "5px",
                  },
                  "&:hover": {
                    backgroundColor: "#fff",
                    "& span": {
                      textDecoration: "underline",
                    },
                  },
                }}
                onClick={(e) => {
                  window.open("/terms-and-conditions", "_blank");
                  // props.history.push("/terms-and-conditions");
                }}
              >
                {"By clicking on VERIFY ME, you agree to our "}
                <span>{"Terms & Conditions"}</span>
              </Button>
              <Button
                variant="contained"
                size="large"
                type="submit"
                endIcon={
                  <ArrowForwardOutlinedIcon
                    htmlColor="#663399"
                    fontSize="small"
                  />
                }
                className="registration_button_width"
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  color: "#663399",
                  borderRadius: "0.7rem",
                }}
                // disabled={formik.isValid}
              >
                Verify Me
              </Button>
            </Grid>
          </Grid>
        </form>
      </Spinner>

      {!!openEmailOtpModal && (
        <EmailOtpVerificationModal
          open={openEmailOtpModal}
          data={{
            emailId: formik?.values?.emailId,
            ...routeData,
            validateNewOTP: false,
          }}
          onClose={() => {
            setOpenEmailOtpModal(false);
          }}
          next={() => {
            setOpenEmailOtpModal(false);
            setOpenMobileOtpModal(true);
          }}
        />
      )}
      {!!openMobileOtpModal && (
        <MobileOtpVerificationModal
          open={openMobileOtpModal}
          data={{ mobileNumber: formik?.values?.mobileNumber, ...routeData }}
          onClose={() => {
            setOpenMobileOtpModal(false);
          }}
        />
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCreateUser: (payload, onSuccess, onError) =>
    dispatch(CreateUser(payload, onSuccess, onError)),
  dispatchCheckRegisteredUser: (payload, onSuccess, onError) =>
    dispatch(CheckRegisteredUser(payload, onSuccess, onError)),
  dispatchCheckRegisteredEmailAndMobile: (payload, onSuccess, onError) =>
    dispatch(CheckRegisteredEmailAndMobile(payload, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

export const HorizontalLine = () => (
  <Divider sx={{ margin: "20px 0", borderColor: "black", borderWidth: 1 }} />
);

export const HeadTitle = ({ title, subTitle, subTitleColor = "#000" }) => {
  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            position: "relative",
            marginBottom: !!subTitle ? 0 : 20,
          }}
        >
          <Box
            style={{
              left: -40,
              position: "absolute",
              width: 15,
              height: 15,
              borderRadius: 50,
              backgroundColor: "#663399",
            }}
          />
          <Typography
            variant="h6"
            marginRight={5}
            fontWeight={"bold"}
            sx={{ width: "100%" }}
          >
            {title}
          </Typography>
        </Box>
        {!!subTitle && (
          <Typography
            color={subTitleColor}
            fontWeight={"normal"}
            sx={{ width: "100%" }}
          >
            {subTitle}
          </Typography>
        )}
      </Box>
    </>
  );
};
