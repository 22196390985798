import * as constants from "../constants";

/**
 * User Logout
 * @returns
 */
const logoutResetUser = () => {
  return { type: constants.RESET_USER_INFO };
};

export const GetOrderDetails = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/create-order`,
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const VerifyOrderDetails = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/verify-order`,
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.SET_PRIME_USER,
        payload: response?.status ? response?.result : false,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const logoutUser = (onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/logout-user",
    method: "POST",
    success: (response) => logoutResetUser(),
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const login = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/login",
    method: "POST",
    data,
    success: (response) => setUserInfo(response?.result),
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const CreateUser = ({ ...data }, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/create-user",
    method: "POST",
    data,
    success: (response) => setUserInfo(response?.result),
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const setUserInfo = (data) => {
  let userInfo = {
    id: null,
    emailId: null,
    mobileNumber: null,
    accessToken: null,
    deviceToken: null,
  };

  // Response to have status 200 for all success cases
  if (data && data.accessToken) {
    userInfo = {
      ...data,
    };
    localStorage.setItem(
      "USER_INFO",
      window.btoa(unescape(encodeURIComponent(JSON.stringify(userInfo))))
    );
  }
  return { type: constants.SET_USER_INFO, payload: userInfo };
};

export const GetBasicDetails = (onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/basic-details",
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const PostBasicDetails = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/basic-details",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const GetAdvanceDetails = (onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/advance-details",
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const PostAdvanceDetails = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/advance-details",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const DeleteUser = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/delete-user",
    method: "DELETE",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const GetImageUploadUrl = (onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/upload-image-url",
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const GetImageUrl = (onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/get-image-url",
    method: "GET",
    success: (response) => {
      return {
        type: constants.SET_USER_IMAGES,
        payload: response?.status ? response?.result : null,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const DeleteUserImage = (imageName, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/delete-image/${imageName}`,
    method: "DELETE",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const UpdateMobileNumber = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/update-mobile-number",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const VerifyEmailOtp = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/verify-email-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const VerifyForgetPwOtp = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/verify-forgot-pw-otp",
    method: "POST",
    data,
    success: (response) => setUserInfo(response?.result),
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const VerifyMobileOtp = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/verify-mobile-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const SendEmailOtp = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/send-email-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const SendAccountOtp = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/send-account-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const forgotPasswordOTP = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/send-forgot-password-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const SendMobileOtp = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/send-mobile-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const SendForgetPasswordOtp = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/send-forgot-pw-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const UpdatePassword = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/update-password",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const CheckRegisteredUser = (param = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/is-registered-user?userId=${param?.userId}`,
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const CheckRegisteredEmailAndMobile = (
  param = {},
  onSuccess,
  onError,
) => ({
  type: constants.API,
  payload: {
      url: `/is-registered-email-mobile`,
      method: 'GET',
      params: param,
      success: response => {
          return {
              type: constants.DO_NOTHING,
          };
      },
      postProcessSuccess: onSuccess,
      postProcessError: onError,
  },
});
export const FetchProfileDetails = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/profile-details",
    method: "GET",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const UpdateProfileDetails = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/profile-details",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const ResetUserPassword = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/reset-password",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const SendEmailUpdateOtp = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/send-email-change-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const VerifyEmailUpdateOtp = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/verify-email-change-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const SendMobileUpdateOtp = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/send-mobile-change-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const VerifyMobileUpdateOtp = (data = {}, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/verify-mobile-change-otp",
    method: "POST",
    data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
