import "./styles.scss";
import RouteWithTitle from "../../Components/Navigation/AppRoute/RouteWithTitle";
import { withRouter } from "react-router-dom";
import { Notifier } from "../../Components/UI";
import React, { PureComponent, useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";

import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import ScrollToTop from "../../Utils/ScrollToTop";

const stepperRoutEnum = {
  "/register": 0,
  "/register/basic": 1,
  "/register/advance": 1,
  "/register/profile": 2,
};

const steps = [
  {
    label: "Register & Verify",
  },
  {
    label: "Complete Your Profile",
  },
  {
    label: "Upload Photos",
  },
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "transparent",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "transparent",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#FFB955",
    borderRadius: 1,
    margin: "0.4vw 0.9vw",
    borderWidth: "3px",
    height: "4em",
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 22,
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    backgroundColor: "#00AA44",
    color: "#fff",
    zIndex: 1,
    border: "1px solid transparent !important",
  },
  "& .QontoStepIcon-activeIcon": {
    backgroundColor: "#FFB955",
    color: "#784af4",
    zIndex: 1,
    border: "1px solid #FFB955 !important",
  },
  "& .QontoStepIcon-circle": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "3em",
    height: "3em",
    borderRadius: "50%",
    border: "1px solid black",
    fontSize: 18,
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className, icon } = props;
  return (
    <QontoStepIconRoot
      ownerState={{ active }}
      className={`displayFlowrootMobile ${className ? className : " "}`}
    >
      {completed ? (
        <Check className="QontoStepIcon-circle QontoStepIcon-completedIcon" />
      ) : (
        <Typography
          className={`QontoStepIcon-circle ${
            active ? "QontoStepIcon-activeIcon" : ""
          }`}
          fontWeight={900}
        >
          {icon}
        </Typography>
      )}
    </QontoStepIconRoot>
  );
}

function CustomStepper(props) {
  const [activeIndex, setactiveIndex] = useState(0);
  useEffect(() => {
    Object.keys(stepperRoutEnum).forEach((element) => {
      if (props.location.pathname === element) {
        setactiveIndex(stepperRoutEnum[element]);
      }
    });
  }, []);

  return (
    <Stepper
      activeStep={activeIndex}
      orientation={window.innerWidth < 700 ? "horizontal" : "vertical"}
      connector={<QontoConnector />}
    >
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            className="rootstepper_grid"
          >
            <Typography fontWeight={600} className="mobileStepperFontSize">
              {step.label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

class RegistrationLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }
  componentDidMount() {}

  render() {
    const { path, component, name } = this.props;

    return (
      <div
        className="registration-layout"
        style={{ position: "relative", minWidth: "100vw", minHeight: "100vh" }}
      >
        <Typography
          className="logo_mobile"
          variant="h4"
          fontWeight={700}
          fontFamily={"Roboto"}
        >
          <Avatar
            alt="logo jspn"
            src={"/images/logo.webp"}
            onClick={e => {
              window.location.href = '/'
            }}
            sx={{margin:"auto"}}
          />
        </Typography>
        <div
          style={{
            zIndex: -1,
            left: 0,
            height: "100vh",            
            position: "fixed",
          }}
        >
          <img
            alt="stepper"
            style={{ height: "100%", width: "100%" }}
            src="/images/blank_layout_background.svg"
          />
        </div>
        <img
          alt="stepper"
          style={{
            zIndex: -1,
            right: 0,
            top: 0,
            objectFit: "cover",
            position: "absolute",
          }}
          src="/images/dot_design.png"
        />
        <img
          alt="stepper"
          style={{
            zIndex: -1,
            right: 0,
            bottom: 0,
            objectFit: "cover",
            position: "absolute",
          }}
          src="/images/dot_design.png"
        />
        <div className="stepper_container">
          {/* 
              for activating perticular index give their number as activestep previous step will be mark as completed 
            */}
          <CustomStepper {...this.props} />
        </div>
        <div className="content_container">
          <div
            className="container"
            style={{
              padding: "6vh 6vw",
            }}
          >
            {path && component && (
              <RouteWithTitle
                key={name}
                exact={true}
                path={path}
                component={component}
                name={name}
              />
            )}
          </div>
        </div>
        <Notifier />
        <ScrollToTop />
      </div>
    );
  }
}

export default withRouter(RegistrationLayout);
