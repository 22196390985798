import * as constants from "./../constants";

const defaultState = {
  chatTotalCount: 0,
};

export default function ConversationReducer(state = defaultState, action) {
  switch (action.type) {
    case constants.SET_CHAT_COUNT:
      return {
        ...state,
        chatTotalCount: action.payload,
      };
    default:
      return state;
  }
}
