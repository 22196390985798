import { Box } from '@mui/material';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// import { Document } from 'react-pdf';

export const LightBoxImage = ({
    source,
    imageStyles = {},
    containerStyles = {},
    type = 'image',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Box
            onClick={() => setIsOpen(true)}
            style={{
                width: '100%',
                height: '100%',
                ...containerStyles,
            }}
        >
            {type == 'image' ? (
                <>
                    {isOpen && (
                        <Lightbox
                            mainSrc={source}
                            onCloseRequest={() => setIsOpen(false)}
                            reactModalStyle={{ overlay: { zIndex: 1400 } }}
                        />
                    )}
                    <img
                        style={{
                            height: '150px',
                            width: '100%',
                            margin: 'auto',
                            borderRadius: '16px',
                            objectFit: 'contain',
                            ...imageStyles,
                        }}
                        src={source}
                        alt={'uploaded-url'}
                    />
                </>
            ) : (
                <>{/* {isOpen && <Document file={source}/>} */}</>
            )}
        </Box>
    );
};
