import React, { Component } from "react";
import "./styles";
import Launcher from "./components/Launcher";
import { fetchChatHistory } from "../Store/Actions/conversationActionCreator";
import { useDispatch } from "react-redux";
import { showMessage } from "../Utils/helper";
import { Spinner } from "../Components/UI";

export const ChatWindowRef = React.createRef();

export const ChatWindowLauncher = ({
  userProfile,
  children,
  disabled,
  onChatOpen,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleChatClose = () => {
    ChatWindowRef.current.close();
  };

  const fetchChatHistoryData = (chatId) => {
    setLoading(true);
    dispatch(
      fetchChatHistory(
        chatId,
        (resp) => {
          if (resp?.status & (resp?.result != null)) {
            ChatWindowRef.current.open({
              agentProfile: userProfile,
              handleClose: handleChatClose,
              messageList: resp.result,
              isOpen: true,
              showEmoji: true,
            });
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const handleChatLaunch = (e) => {
    e.stopPropagation();
    !!onChatOpen && onChatOpen();
    if (disabled) {
      showMessage("Upgrade to VVS PRIME to start a new conversation. You can reply to the messages you have received from 'My Conversations' ", "error");
      return false;
    }
    if (!userProfile?.id) {
      showMessage("invalid user", "error");
      return false;
    }
    fetchChatHistoryData(userProfile?.id);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Spinner loading={loading}>
        <div onClick={handleChatLaunch}>{!!children ? children : "chat"}</div>
      </Spinner>
      {/* {isOpen && (
        <ChatWindow
          agentProfile={userProfile}
          handleClose={handleChatClose}
          messageList={messageList}
          isOpen={isOpen}
          showEmoji={true}
        />
      )} */}
    </div>
  );
};

export default class ChatWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      chatProps: {},
    };
  }

  open(newProps) {
    this.setState({
      open: true,
      chatProps: {
        ...this.state?.chatProps,
        ...newProps,
      },
    });
  }

  close() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { chatProps, open } = this.state;
    return !!open ? (
      <Launcher
        {...chatProps}
        isOpen={open}
        handleClose={() => {
          this.close();
        }}
        showEmoji={true}
      />
    ) : (
      <></>
    );
  }
}

export { ChatWindow };
