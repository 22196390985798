import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    common: {
      black: "rgba(0, 0, 0, 1)",
      white: "rgba(255, 255, 255, 1)",
    },
    background: {
      paper: "rgba(255, 255, 255, 1)",
      default: "rgba(241, 243, 246, 1)",
    },
    primary: {
      light: "#7986cb",
      main: "#FFB955",
      dark: "#3c3a8b",
      default: "#fff",
      contrastText: "#663399",
    },
    secondary: {
      light: "#7986cb",
      main: "#663399",
      dark: "#3c3a8b",
      default: "#fff",
      contrastText: "#FFB955",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "#663399",
      error: "#f44336",
      secondary: "#515f6d",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      contrastText: "#FFB955",
    },
  },
  typography: {
    fontFamily: ["Montserrat"],
    allVariants: { color: "#663399" },
  }
});
export default theme;
