import React from "react";
import { Button as MUIButton } from "@mui/material";

export function Button({ sx, ...props }) {
  return (
    <MUIButton
      {...props}
      disableElevation
      sx={(theme) => ({
        textTransform: "none",
        "&:hover": {
          backgroundColor: theme.palette.primary.contrastText,
          color: "#fff !important",
          "& svg": {
            fill: "#fff",
          },
        },
        ...sx,
      })}
    />
  );
}
