import React, { useEffect } from "react";
import "./style.scss";
import List from "@mui/material/List";
import {
  Avatar,
  Badge,
  Box,
  Container,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  fetchChatCount,
  fetchChatHistory,
  fetchChats,
} from "../../Store/Actions/conversationActionCreator";
import { connect } from "react-redux";
import { Spinner } from "../../Components/UI";
import { ChatWindowLauncher } from "../../Chat";
import moment from "moment";
import { EmptyImage } from "../../Assets/svg";

function Conversations({
  user,
  dispatchfetchChats,
  dispatchFetchChatHistory,
  dispatchChatCount,
  ...props
}) {
  const isMobileView = window.innerWidth < 600;
  const [loading, toggleLoading] = React.useState(false);
  const [chatHistory, setChatHistory] = React.useState([]);

  useEffect(() => {
    fetchChatListData();
  }, []);

  const fetchChatListData = () => {
    toggleLoading(true);
    dispatchfetchChats(
      (resp) => {
        if (resp?.status) {
          setChatHistory(resp?.result);
        }
        toggleLoading(false);
      },
      (err) => {
        setChatHistory([]);
        toggleLoading(false);
      }
    );
  };

  const resetConversationCount = (chatId) => {
    setChatHistory(
      chatHistory?.map((chat) => ({
        ...chat,
        unseenCount: chatId === chat.fromUserId ? 0 : chat?.unseenCount,
      }))
    );
  };

  return (
    <Spinner loading={loading}>
      <Container className="conversation_content" maxWidth="unset">
        <Box
          sx={{ width: "100%", padding: isMobileView ? "0 12px" : "0px 180px" }}
        >
          <div className="account-top">
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight="bold"
              paddingBottom={"1em"}
              // paddingTop={"0.4em"}
              textAlign={"left"}
            >
              My Conversations
            </Typography>
          </div>
          {chatHistory.length > 0 ? (
            chatHistory.map((chat) => (
              <ChatWindowLauncher
                userProfile={{
                  id: chat.fromUserId,
                  teamName: chat.fromUserName,
                  imageUrl: chat.imageUrl,
                }}
                disabled={false}
                onChatOpen={() => {
                  resetConversationCount(chat.fromUserId);
                }}
              >
                <List
                  className={"conversation_list"}
                  sx={{ borderRadius: "12px", marginBottom: 2 }}
                >
                  <ListItem
                    alignItems="flex-start"
                    secondaryAction={
                      !isMobileView && (
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {moment(chat.createdTimestamp).format(
                            "DD MMM, YYYY hh:mm A"
                          )}
                        </Typography>
                      )
                    }
                  >
                    <ListItemAvatar>
                      <Badge badgeContent={chat.unseenCount} color="error">
                        <Avatar alt={chat.fromUserName} src={chat.imageUrl} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ ml: 1 }}
                      primary={chat.fromUserName}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Last Message -
                          </Typography>
                          {chat.lastMessage}
                          {isMobileView && (
                            <>
                              <br />
                              <Typography
                                component="p"
                                sx={{ display: "inline" }}
                                variant="body2"
                                color="text.primary"
                              >
                                {moment(chat.createdTimestamp).format(
                                  "DD MMM, YYYY hh:mm A"
                                )}
                              </Typography>
                            </>
                          )}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </ChatWindowLauncher>
            ))
          ) : (
            <Box
              className="inboxlist"
              padding={0}
              paddingBottom="1rem"
              style={{
                display: "flex",
                flexDirection:'column',
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <EmptyImage width={400} height={400} />
            </Box>
          )}
        </Box>
      </Container>
    </Spinner>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchfetchChats: (onSuccess, onError) =>
    dispatch(fetchChats(onSuccess, onError)),
  dispatchFetchChatHistory: (chatId, onSuccess, onError) =>
    dispatch(fetchChatHistory(chatId, onSuccess, onError)),
  dispatchChatCount: (onSuccess, onFailure) =>
    dispatch(fetchChatCount(onSuccess, onFailure)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversations);
