import React from "react";
import moment from "moment";
import { DoubleTickIcon } from "../../../Assets/svg";

const EmojiMessage = (props) => {
  return (
    <div>
      <div className="sc-message--emoji">{props.chatMessage}</div>
      <div className="sc-message--time">
        {moment(props.createdTimestamp).format("hh:mm A")}
        {!!props?.loggedInUser && (
          <>
            {"   "}
            <DoubleTickIcon
              style={
                !!props?.isSeen ? { fill: "#FFB955" } : { fill: "#663399" }
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EmojiMessage;
