import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { ThemeProvider, withStyles } from "@mui/styles";
import Apptheme from "../../../Utils/theme";

export const StyledMenuItem = withStyles(() => {
  const theme = Apptheme;
  return {
    root: {
      margin: "0 8px",
      border: "0 !important",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "rgba(67, 65, 158, 0.08)",
        color: theme.palette.primary.main,
        borderRadius: "8px",
      },
    },
  };
})(MenuItem);

export const CustomTextField = withStyles(() => {
  const theme = Apptheme;
  return {
    root: {
      width: "100%",
      "& label": {
        color: "#0D1321",
      },
      "& label.Mui-focused": {
        color: "#9C27B0",
      },

      // "& .MuiInput-underline": {
      //   color: 'red'
      // },
      // marginBottom: '16px',

      "& .MuiOutlinedInput-root": {
        // '& fieldset': {
        //     borderColor: 'red',
        // },
        borderRadius: 10,
        borderColor: "rgba(67, 65, 158, 0.3)",
        "&:hover fieldset": {
          borderColor: theme.palette.primary.main,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(67, 65, 158, 0.3) !important",
      },
      "& .MuiOutlinedInput-input": {
        padding: "16px",
        color: "#14112d",
        borderColor: "rgba(67, 65, 158, 0.3)",
      },
      "& .MuiOutlinedInput-inputMultiline": {
        padding: 0,
      },
    },
  };
})(({ classes, children, label, isRequired, ...props }) => {
  return (
    <ThemeProvider theme={Apptheme}>
      <TextField
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        variant="outlined"
        error={false}
        label={
          isRequired && label ? (
            <>
              {label}
              <span style={{ color: "red" }}>*</span>
            </>
          ) : (
            label
          )
        }
        {...props}
      >
        {children}
      </TextField>
    </ThemeProvider>
  );
});

export const CompactCustomTextField = withStyles(() => {
  const theme = Apptheme;
  return {
    root: {
      width: "100%",
      "& label.Mui-focused": {
        color: "#14112d",
      },

      "& .MuiOutlinedInput-root": {
        padding: "0 4px",
        height: 40,
        borderRadius: 10,
        "&:hover fieldset": {
          borderColor: theme.palette.primary.main,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 10px",
        color: "#14112d",
      },
    },
  };
})(({ classes, children, ...props }) => {
  return (
    <TextField
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      variant="outlined"
      error={false}
      {...props}
    >
      {children}
    </TextField>
  );
});
