import React from 'react';
import './styles.scss';
import Typography from '@mui/material/Typography';

export const ComponentHeader = ({ headingText, borderWidth, extraStyles }) => {
    return (
        <Typography variant="h4" fontFamily="monospace" fontWeight={900} {...extraStyles} >
            {headingText}
            <div style={{display:"table"}}><hr  style={{
            width:`${borderWidth}`,
            height:"5px",
            maxWidth:'90vw',
            backgroundColor:"#663399"
            }} /></div>
        </Typography>
    );
};
